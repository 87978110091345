// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSnapShots_single__lqOlV {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserSnapShots_mainContainer__4JfKg {
  border-radius: 1rem;
  border: 1.5px solid black;
  padding: 1rem;
  margin: 1rem;
}

.UserSnapShots_centerContent__osnmd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserSnapShots_centerContent__osnmd img{
  border-radius: 1rem;
  border: 1.5px solid black;
}`, "",{"version":3,"sources":["webpack://./src/pages/data/UserSnapShots/UserSnapShots.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".single {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.mainContainer {\n  border-radius: 1rem;\n  border: 1.5px solid black;\n  padding: 1rem;\n  margin: 1rem;\n}\n\n.centerContent {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.centerContent img{\n  border-radius: 1rem;\n  border: 1.5px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"single": `UserSnapShots_single__lqOlV`,
	"mainContainer": `UserSnapShots_mainContainer__4JfKg`,
	"centerContent": `UserSnapShots_centerContent__osnmd`
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { MeditationServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_MEDITATIONS_LIST } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import Loader from "../../../../components/Loader/Loader";

const UpdateMeditations = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [MeditationName, setMeditationName] = useState("");
    const [MediaLink, setMediaLink] = useState("");
    const [ThumbnailURL, setThumbnailURL] = useState("");
    const [Descriptions, setDescriptions] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [sortID, setSortID] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [IsPaid, setIsPaid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);

    const { token } = AUTH_CONTEXT();

    const FetchResponse = async () => {
        try {
            let response = await MeditationServices.getAllMeditations(token, navigate, params.ID);
            setIsLoaderLoading(true)
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const FetchData = async () => {
            try {
                let resp = await FetchResponse();
                const response = resp[0];
                setDescriptions(response.Description)
                setSortID(parseInt(response.SortID))
                setIsActive(response.IsActive)
                setMeditationName(response.MeditationName)
                setMediaLink(response.MediaLink)
                setThumbnailURL(response.ThumbnailURL)
                setIsPaid(response.IsPaid)
            } catch (error) {
                console.log(error.message);
            }
        };

        if (params.ID) {
            FetchData();
        } else {
            return;
        }
    }, []);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            _id: params.ID,
            MeditationName: MeditationName,
            MediaLink: MediaLink,
            ThumbnailURL: ThumbnailURL,
            Description: Descriptions,
            SortID: sortID,
            IsActive: isActive,
            IsPaid: IsPaid,
        }

        const FetchResponse = async () => {
            try {
                let response = await MeditationServices.addMeditation(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_MEDITATIONS_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const handleDescriptionChange = (e, locale) => {
        setDescriptions(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        !IsLoaderLoading ? <Loader /> : <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Update Meditation</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Update Meditation</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="MeditationName">MeditationName</label>
                        <input
                            type="text"
                            id="MeditationName"
                            required
                            value={MeditationName}
                            onChange={(e) => setMeditationName(e.target.value)}
                            placeholder="Enter Name for Meditation"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="MediaLink">Meditation Media Link : </label>
                        <input
                            required
                            id="MediaLink"
                            type="url"
                            placeholder="Enter Media Link"
                            value={MediaLink}
                            onChange={(e) => setMediaLink(e.target.value)}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="ThumbnailURL">Meditation Thumbnail Link : </label>
                        <input
                            id="ThumbnailURL"
                            type="url"
                            placeholder="Enter Thumbnail URL"
                            value={ThumbnailURL}
                            onChange={(e) => setThumbnailURL(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SortID">SortID</label>
                        <input
                            type="number"
                            id="SortID"
                            required
                            value={sortID}
                            onChange={(e) => setSortID(parseInt(e.target.value))}
                            placeholder="Enter unique ID eg. 1, 2, etc."
                        />
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>


                    <div className={classes.selectbox} >
                        <label htmlFor="IsPaid">IsPaid : </label>
                        <select
                            name="IsPaid"
                            id="IsPaid"
                            value={IsPaid}
                            onChange={(e) => setIsPaid(e.target.value)}
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Descriptions["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Descriptions["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Descriptions["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Update</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default UpdateMeditations;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { PaymentInvoiceService } from "../../../../services/redeem_services";
import Button from "../../../../components/Button/Button";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST } from "../../../../base_routes_paths/redeem_paths";

const UpdateSubPaymentInvoice = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);
    const [UserID, setUserID] = useState('');
    const [InvoiceNumber, setInvoiceNumber] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [SubscriptionID, setSubscriptionID] = useState('');
    const [InvoiceUrl, setInvoiceUrl] = useState('');
    const [MessageID, setMessageID] = useState('');
    const [IsSharedViaMail, setIsSharedViaMail] = useState(false);
    const [IsNewSub, setIsNewSub] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const data = {
            _id: params.ID,
            Data: {
                UserID,
                InvoiceNumber,
                transactionId,
                SubscriptionID,
                InvoiceUrl,
                MessageID,
                IsSharedViaMail,
                IsNewSub
            }
        };

        try {
            const response = await PaymentInvoiceService.addInvoice(token, navigate, data);

            setIsLoading(false);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: 'bottom-left',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: 'light'
                });
            }

            navigate(BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST);
            toast.success('SubPaymentInvoice Updated successfully!', {
                position: 'bottom-left',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message, {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        }
    };

    const FetchResponse = async () => {
        try {
            let response = await PaymentInvoiceService.getInvoices(token, navigate, params.ID);
            setIsLoaderLoading(true)
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const FetchData = async () => {
            try {
                let resp = await FetchResponse();
                const response = resp[0];
                setUserID(response.UserID)
                setInvoiceNumber(response.InvoiceNumber)
                setTransactionId(response.transactionId)
                setSubscriptionID(response.SubscriptionID)
                setInvoiceUrl(response.InvoiceUrl)
                setMessageID(response.MessageID)
                setIsSharedViaMail(response.IsSharedViaMail)
                setIsNewSub(response.IsNewSub)
            } catch (error) {
                console.log(error.message);
            }
        };

        if (params.ID) {
            FetchData();
        }
    }, []);

    return (
        !IsLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Redeem
                    </Typography>
                    <Typography color="text.primary">Update Sub Payment Invoice Config</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Update New Sub Payment Invoice Config</div>
                    <form onSubmit={SubmitHandler}>
                        <div className={classes.control}>
                            <label htmlFor="UserID">UserID</label>
                            <input
                                type="text"
                                id="UserID"
                                value={UserID}
                                onChange={(e) => setUserID(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.control}>
                            <label htmlFor="InvoiceNumber">InvoiceNumber</label>
                            <input
                                type="text"
                                id="InvoiceNumber"
                                value={InvoiceNumber}
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.control}>
                            <label htmlFor="transactionId">transactionId</label>
                            <input
                                type="text"
                                id="transactionId"
                                value={transactionId}
                                onChange={(e) => setTransactionId(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.control}>
                            <label htmlFor="SubscriptionID">SubscriptionID</label>
                            <input
                                type="text"
                                id="SubscriptionID"
                                value={SubscriptionID}
                                onChange={(e) => setSubscriptionID(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.control}>
                            <label htmlFor="InvoiceUrl">InvoiceUrl</label>
                            <input
                                type="text"
                                id="InvoiceUrl"
                                value={InvoiceUrl}
                                onChange={(e) => setInvoiceUrl(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.control}>
                            <label htmlFor="MessageID">MessageID</label>
                            <input
                                type="text"
                                id="MessageID"
                                value={MessageID}
                                onChange={(e) => setMessageID(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsSharedViaMail">IsSharedViaMail : </label>
                            <select
                                name="IsSharedViaMail"
                                id="IsSharedViaMail"
                                value={IsSharedViaMail}
                                onChange={(e) => setIsSharedViaMail(e.target.value)}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsNewSub">IsNewSub : </label>
                            <select
                                name="IsNewSub"
                                id="IsNewSub"
                                value={IsNewSub}
                                onChange={(e) => setIsNewSub(e.target.value)}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p color="white">Sending request...</p>}
                    </form>
                </Card>
            </div>
    );
};

export default UpdateSubPaymentInvoice;

import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const TimeOffSet = localStorage.getItem("TimeOffSet") ? localStorage.getItem("TimeOffSet") : 0;

// ==============================|| FREE USER CONFIG ||============================== //
// Delete API is created but not integrated for free user => Doubt
const FreeUserServices = {
    // User API => Called on both View an update pages
    getFreeUserConfig: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/GetFreeUserConfig`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    // Admin API
    addFreeUserConfig: async (
        token,
        navigate,
        Name,
        IsActive,
        DailyPoints,
        IsAdsFree,
        IsReminders,
        Redemptions,
        freeSuperpowers,
        ChallengeCategoryAllowed,
        UserGoalConfig,
        UserStepsConfig,
        ReferralConfig,
        ClanConfig,
        RoutinePlansConfig,
        IsInterstitialAdsAllowed
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/CreateFreeUserConfig`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        Data: {
                            Name: Name,
                            IsActive: IsActive,
                            DailyPoints: DailyPoints,
                            IsAdsFree: IsAdsFree,
                            IsReminders: IsReminders,
                            Redemptions: Redemptions,
                            FreeSuperpowers: freeSuperpowers,
                            ChallengeCategoryAllowed: ChallengeCategoryAllowed,
                            UserGoalConfig: UserGoalConfig,
                            UserStepsConfig: UserStepsConfig,
                            ReferralConfig: ReferralConfig,
                            ClanConfig: ClanConfig,
                            RoutinePlansConfig: RoutinePlansConfig,
                            IsInterstitialAdsAllowed: IsInterstitialAdsAllowed,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateFreeUser: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/CreateFreeUserConfig`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        Data: Data,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

// ==============================|| SUBSCRIPTION CONFIG ||============================== //

const SubscriptionsServices = {
    addSubscription: async (
        token,
        navigate,
        Name,
        Price,
        Duration,
        IsActive,
        AndroidproductId,
        IOSproductId,
        SoloChallengeLevel,
        DailyPoints,
        IsAdsFree,
        IsReminders,
        Redemptions,
        ReferralConfig,
        freeSuperpowers,
        ChallengeCategoryAllowed,
        UserGoalConfig,
        UserStepsConfig,
        ClanConfig,
        RoutinePlansConfig
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/CreateSubscriptionSchema`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        Name: Name,
                        Price: Price,
                        Duration: Duration,
                        IsActive: IsActive,
                        AndroidproductId: AndroidproductId,
                        IOSproductId: IOSproductId,
                        SoloChallengeLevel: SoloChallengeLevel,
                        DailyPoints: DailyPoints,
                        IsAdsFree: IsAdsFree,
                        IsReminders: IsReminders,
                        Redemptions: Redemptions,
                        ReferralConfig: ReferralConfig,
                        FreeSuperpowers: freeSuperpowers,
                        ChallengeCategoryAllowed: ChallengeCategoryAllowed,
                        UserGoalConfig: UserGoalConfig,
                        UserStepsConfig: UserStepsConfig,
                        ClanConfig,
                        RoutinePlansConfig
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllSubscriptions: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/GetAllSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateSubscription: async (token, navigate, ID, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/UpdateReminderImageByID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                        Data: {
                            ImagesLinks: Data,
                        },
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteSubscription: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/redeem/AdminDeleteSubscription`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Subscription deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const SubscriptionsDetailsServices = {
    getSubscriptionDetails: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllSubscriptionDetailsDBSchema`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addSubscriptionDetails: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminCreateSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateSubscriptionDetails: async (token, navigate, ID, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminCreateSubscriptionDetailsForApp`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                        Data: Data,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    iosUserSubscriptions: async (
        token,
        navigate,
        page,
        pageSize,
        sort,
        field,
        filterModel
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllIOSSubscriptions?page=${page}&limit=${pageSize}&sort=${sort}&field=${field}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    androidUserSubscriptions: async (
        token,
        navigate,
        page,
        pageSize,
        sort,
        field,
        filterModel
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllSubscriptions?page=${page}&limit=${pageSize}&sort=${sort}&field=${field}&TimeOffSet=${TimeOffSet}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const VoucherServices = {
    addVoucher: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/addRedeemOption`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateVoucher: async (token, navigate, ID, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/updateRedeemVoucher?voucherId=${ID}`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllVouchers: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllVoucher`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteVoucher: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/redeem/deleteRedeemVoucher?voucherId=${id}`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                }
                            );

                            StatusCode(response);
                            const data = await response.json();
                            if (response.status === 200) {
                                toast.success("Voucher deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const RedeemService = {
    updateRedeemData: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/UpdateRedeempionData`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getRedeemData: async (token, navigate, Date) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/RedeemUserDetails`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        Date: Date,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    distributeVoucher: async (token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to distribute Vouchers manually?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/redeem/DistriButeVoucher`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                }
                            );

                            StatusCode(response);
                            const data = await response.json();
                            if (response.status === 200) {
                                toast.success("Succesfully distributed Vouchers", {
                                    position: "bottom-left",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to distribute Vouchers.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message ||
                                "An error occurred while distributing Vouchers.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const PurchaseUserSubscriptionService = {
    addUserSubscription: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminPurchaseUserSubscription`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const AFAServices = {
    getAllAFA: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllAppFeatureAvailable`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addAFA: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminAddAppFeatureAvailable`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const PaymentGatewayService = {
    addPaymentGatewayConfig: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminAddPaymentGatewayConfig`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getPaymentGatewayConfigs: async (token, navigate, _id) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllPaymentGatewayData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: _id,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deletePaymentGatewayConfig: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/redeem/AdminDeletePaymentGatewayConfig`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Payment Gateway Config deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};


const PhonePeService = {
    getallPhonePeInitData: async (token, navigate, _id) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllPhonePePaymentInitData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: _id,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getallPhonePeRecurringInitData: async (token, navigate, _id) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllPhonePeRecurringInitData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: _id,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const PaymentInvoiceService = {
    addInvoice: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminAddSubPaymentInvoiceConfig`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getInvoices: async (token, navigate, _id) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetAllSubPaymentInvoiceData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: _id,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deletePaymentInvoice: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/redeem/AdminDeleteSubPaymentInvoiceConfig`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Invoice Config deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

export {
    SubscriptionsServices,
    SubscriptionsDetailsServices,
    FreeUserServices,
    VoucherServices,
    RedeemService,
    PurchaseUserSubscriptionService,
    AFAServices,
    PaymentGatewayService,
    PhonePeService,
    PaymentInvoiceService
};

import { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../Data.module.css";
import { toast } from "react-toastify";
import {
    Breadcrumbs,
    FormControl,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AUTH_CONTEXT from "../../../store";
import { StatusCode } from "../../../utils";
import ARENO_API_ENDPOINT from "../../../api";
import { UserGoalsServices } from "../../../services/dashboard_services";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../base_routes_paths/data_paths";

const UserGoals = () => {
    const { token, fileName } = AUTH_CONTEXT()
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [rowsCount, setRowsCount] = useState(1);
    const EXPORT_FILENAME = `${fileName}_UsersGoals`;
    const [filterModel, setFilterModel] = useState({});
    const [sort, setSort] = useState(1);
    const navigate = useNavigate();

    const FetchResponseGetAllUserGoals = async (
        page,
        pageSize,
        filterModel,
        sort,
        field
    ) => {
        try {
            setIsLoading(true);
            setIsDataFetched(false);
            let response = await UserGoalsServices.getAllGoals(token, navigate, page, pageSize, sort, field, filterModel);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const FetchResponseFilter = async (page, pageSize, filterModel, sort, field) => {
        try {
            setIsLoading(true);
            let response = await UserGoalsServices.getAllGoals(token, navigate, page, pageSize, sort, field, filterModel);
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isDataFetched) {
                try {
                    const result = await FetchResponseGetAllUserGoals(
                        page,
                        pageSize,
                        filterModel,
                        sort
                    );
                    const res = result.data.UserGoals;
                    setRowsCount(result.data.TotalUsersGoals);
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                } catch (err) { }
            }
        };

        fetchData();
    }, [page, pageSize, filterModel, sort, isDataFetched]);

    const userColumns = [
        {
            field: "UserID",
            headerName: "UserID",
            width: 210,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</a>
                );
            },
        },
        {
            field: "CalorieGoal",
            headerName: "CalorieGoal",
            width: 130,
        },
        {
            field: "Duration",
            headerName: "Duration(mins)",
            width: 130,
        },
        {
            field: "Steps",
            headerName: "Steps",
            width: 130,
        },
    ];

    const handleFilterModelChange = async (model) => {
        let items = model.items;

        const json = items.reduce((obj, item) => {
            if (
                item.value !== null &&
                item.value !== undefined &&
                item.value !== ""
            ) {
                const fieldValue =
                    item.field === "Mobile_Number" ||
                        item.field === "Age" ||
                        item.field === "Height" ||
                        item.field === "Weight" ||
                        item.field === "YearOfBirth" ||
                        item.field === "ActivityStatus" ||
                        item.field === "GetToKnowFrom" ||
                        item.field === "TimeOffSet"
                        ? +item.value
                        : item.value;

                if (item.field === "IsSubscribed") {
                    obj[item.field] = item.value.toLowerCase() === "true";
                } else {
                    obj[
                        item.field === "id"
                            ? "_id"
                            : item.field
                    ] = fieldValue;
                }
            }
            return obj;
        }, {});

        try {
            setFilterModel(json);
            setPage(1);

            setTimeout(async () => {
                const result = await FetchResponseFilter(1, pageSize, json, sort, null);

                if (result && result.data) {
                    const res = result.data.UserGoals;
                    setRowsCount(result.data.TotalUsersGoals);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 500);
        } catch (err) { }
    };

    const handleSortModelChange = (model) => {
        let field = model.some((item) => {
            return item.field;
        });

        const isSort = model.some((item) => item.sort === "asc");

        if (field) {
            setTimeout(async () => {
                const result = await FetchResponseFilter(
                    1,
                    pageSize,
                    filterModel,
                    isSort ? 1 : -1,
                    field
                );

                if (result && result.data) {
                    const res = result.data.UserGoals;
                    setRowsCount(result.data.TotalUsersGoals);
                    setData(res.map(({ _id: id, ...result }) => ({ id, ...result })));
                }
            }, 100);

            field = "";
        }
    };

    function CustomToolbar() {
        const [selectedOption, setSelectedOption] = useState("UserID");
        const [value, setValue] = useState("");

        const handleOptionChange = (event) => {
            setSelectedOption(event.target.value);
            setValue("");
        };

        const generateJson = () => {
            const json = { [selectedOption]: value };

            return json;
        };

        const json = generateJson();

        return (
            <GridToolbarContainer
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
                <FormControl
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        sx={{ width: "10rem" }}
                    >
                        <MenuItem value="UserID">UserID</MenuItem>
                    </Select>
                    <TextField
                        id="outlined-basic"
                        label={selectedOption}
                        variant="filled"
                        type="text"
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => {
                                            const result = await FetchResponseFilter(
                                                1,
                                                pageSize,
                                                json,
                                                sort,
                                                null
                                            );

                                            if (result && result.data) {
                                                const res = result.data.UserGoals;
                                                setRowsCount(result.data.TotalUsersGoals);
                                                setData(
                                                    res.map(({ _id: id, ...result }) => ({
                                                        id,
                                                        ...result,
                                                    }))
                                                );
                                            }
                                        }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Data
                </Typography>
                <Typography color="text.primary">User Goals List</Typography>
            </Breadcrumbs>
            <DataGridPremium
                slots={{
                    toolbar: CustomToolbar,
                }}
                rows={data}
                rowCount={rowsCount}
                columns={userColumns}
                pageSize={pageSize}
                pagination
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: pageSize, page: page - 1 },
                    },
                }}
                pageSizeOptions={[20, 50, 100, 500, 1000]}
                disableRowSelectionOnClick
                onPaginationModelChange={({ pageSize, page }) => {
                    setPage(page + 1);
                    setPageSize(pageSize);
                    FetchResponseGetAllUserGoals(page + 1, pageSize, filterModel, sort);
                }}
                filterMode="server"
                onFilterModelChange={(model) => {
                    handleFilterModelChange(model);
                    setPage(1);
                }}
                onSortModelChange={(model) => handleSortModelChange(model)}
                loading={isLoading}
                checkboxSelectionVisibleOnly
                checkboxSelection
            />
        </div>
    );
};

export default UserGoals;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Auth.module.css";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Loader from "../../../../components/Loader/Loader";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_SPECIAL_REFERRAL, BASE_PATH_TO_UPDATE_SPECIAL_REFERRAL } from "../../../../base_routes_paths/auth_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import { SpecialReferraServices } from "../../../../services/auth_services";

const SpecialReferral = (props) => {
    const navigate = useNavigate()
    const userColumns = [
        {
            field: "ReferralCode",
            headerName: "ReferralCode",
            width: 100,
            renderCell: (params) => {
                const FetchApi = async () => {
                    let response = await fetch(`${ARENO_API_ENDPOINT}/auth/GetUserDetailsByReferralCode`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            ReferralCode: params.row.ReferralCode
                        })
                    });

                    StatusCode(response, navigate);
                    response = await response.json();
                    if (response.status === 207) {
                        return toast
                            .error(response.error, {
                                position: "bottom-left",
                                autoClose: 3000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            })
                    }
                    return response.data;
                };
                return (
                    <p
                        style={{ cursor: "pointer", color: "#4f005f" }}
                        onClick={async () => {
                            const res = await FetchApi()
                            navigate(`${BASE_PATH_TO_USER_DETAILS}/` + res.UserRecords._id)
                        }} >{params.row.ReferralCode}</p>
                );
            },
        },
        {
            field: "ReferralLimit",
            headerName: "ReferralLimit",
            width: 100,
        },
        {
            field: "PointForReferralUser",
            headerName: "PointForReferralUser",
            width: 180,
        },
        {
            field: "PointForReferredUser",
            headerName: "PointForReferredUser",
            width: 180,
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_SpecialReferralsList`;

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await SpecialReferraServices.getAllSpecialReferral(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }

            setIsDataFetched(true);

            let result = response.data;

            let input = result.map(({ _id: id, ...result }) => ({
                id,
                ...result,
            }));
            setData(input);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(true);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    const handleDelete = (ReferralCode) => {
        SpecialReferraServices.deletePointPurchaseVouchers(ReferralCode, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_SPECIAL_REFERRAL}/` + params.row.ReferralCode}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.ReferralCode)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Auth
                        </Typography>
                        <Typography color="text.primary">SpecialReferral List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns.concat(actionColumn)}
                        slots={{
                            toolbar: () => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    onClickCreate={() => {
                                        navigate(BASE_PATH_TO_ADD_SPECIAL_REFERRAL);
                                    }}
                                    displayCreate={""}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default SpecialReferral;
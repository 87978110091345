import {
    BASE_PATH_TO_ADD_GAMES,
    BASE_PATH_TO_GAME,
    BASE_PATH_TO_GAMES_LIST,
    BASE_PATH_TO_UPDATE_GAMES,
} from "../base_routes_paths/game_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import AddGameLevel from "../pages/games/game_levels/add_game_levels";
import GameLevels from "../pages/games/game_levels/game_levels_list";
import UpdateGameLevel from "../pages/games/game_levels/update_game_levels";
import { ProtectedRoutes } from "./protected_route";

const GameRoutes = {
    path: BASE_PATH_TO_GAME,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_GAMES_LIST,
            element: <GameLevels />,
        },
        {
            path: BASE_PATH_TO_ADD_GAMES,
            element: <AddGameLevel />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_GAMES}/:ID`,
            element: <UpdateGameLevel />,
        },
    ],
};

export default GameRoutes;

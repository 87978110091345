// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_cellWithImg__uaP1q {
    display: flex;
    align-items: center;
  }
  
  .Sidebar_cellImg__Wb04- {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/layouts/header/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".cellWithImg {\n    display: flex;\n    align-items: center;\n  }\n  \n  .cellImg {\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-right: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cellWithImg": `Sidebar_cellWithImg__uaP1q`,
	"cellImg": `Sidebar_cellImg__Wb04-`
};
export default ___CSS_LOADER_EXPORT___;

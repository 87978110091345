import { BASE_PATH_TO_ADD_AFA, BASE_PATH_TO_ADD_FREE_USER_CONFIG, BASE_PATH_TO_ADD_PAYMENT_GATEWAY_CONFIG, BASE_PATH_TO_ADD_PAYMENT_INVOICE_CONFIG, BASE_PATH_TO_ADD_SUBSCRIPTIONS, BASE_PATH_TO_ADD_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_ADD_VOUCHER, BASE_PATH_TO_AFA_LIST, BASE_PATH_TO_FREE_USER_CONFIG_VIEW, BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST, BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST, BASE_PATH_TO_PHONE_PE_PAYMENT_INIT, BASE_PATH_TO_PHONE_PE_RECURRING_INIT, BASE_PATH_TO_PURCHASE_USER_SUBSCRIPTION, BASE_PATH_TO_REDEEM, BASE_PATH_TO_SUBSCRIPTIONS_LIST, BASE_PATH_TO_UPDATE_AFA, BASE_PATH_TO_UPDATE_FREE_USER_CONFIG, BASE_PATH_TO_UPDATE_PAYMENT_GATEWAY_CONFIG, BASE_PATH_TO_UPDATE_PAYMENT_INVOICE_CONFIG, BASE_PATH_TO_UPDATE_SUBSCRIPTIONS, BASE_PATH_TO_UPDATE_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_UPDATE_VOUCHER, BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_VOUCHER_LIST } from "../base_routes_paths/redeem_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import AddAFA from "../pages/redeem/app_feature_available/add_afa";
import ViewAFA from "../pages/redeem/app_feature_available/afa_list";
import UpdateAFA from "../pages/redeem/app_feature_available/update_afa";
import FreeUserCreationForm from "../pages/redeem/free_user/add_free_user";
import FreeUserUpdate from "../pages/redeem/free_user/update_free_user";
import FreeUser from "../pages/redeem/free_user/view_free_user";
import AddPaymentGatewayConfig from "../pages/redeem/payment_gateway/add_payment_gateway";
import PaymentGatewayList from "../pages/redeem/payment_gateway/payment_gateway_list";
import UpdatePaymentGatewayConfig from "../pages/redeem/payment_gateway/update_payment_gateway";
import AddSubPaymentInvoice from "../pages/redeem/payment_invoice/add_invoice";
import InvoicesList from "../pages/redeem/payment_invoice/invoices_list";
import UpdateSubPaymentInvoice from "../pages/redeem/payment_invoice/update_invoice";
import PhonePePaymentInitList from "../pages/redeem/phone_pe/payment_init";
import PhonePeRecurringPaymentInitList from "../pages/redeem/phone_pe/payment_recurring_init";
import PurchaseUserSubscription from "../pages/redeem/purchase_user_subscription";
import SubscriptionCreationForm from "../pages/redeem/subscriptions/add_subscriptions";
import SubscriptionsList from "../pages/redeem/subscriptions/subscriptions_list";
import UpdateSubscription from "../pages/redeem/subscriptions/update_subscriptions";
import CreateSubscriptionDetails from "../pages/redeem/subscriptions_details/add_subscription_details";
import UpdateSubscriptionDetails from "../pages/redeem/subscriptions_details/update_subscription_details";
import SubscriptionsDetails from "../pages/redeem/subscriptions_details/view_subscription_details";
import AddRedeemVoucherConfig from "../pages/redeem/voucher/add_voucher";
import UpdateRedeemVoucherConfig from "../pages/redeem/voucher/update_voucher";
import VouchersList from "../pages/redeem/voucher/vouchers_list";
import { ProtectedRoutes } from "./protected_route";

const RedeemRoutes = {
    path: BASE_PATH_TO_REDEEM,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_FREE_USER_CONFIG_VIEW,
            element: <FreeUser />,
        },
        {
            path: BASE_PATH_TO_ADD_FREE_USER_CONFIG,
            element: <FreeUserCreationForm />,
        },
        {
            path: BASE_PATH_TO_UPDATE_FREE_USER_CONFIG,
            element: <FreeUserUpdate />,
        },
        {
            path: BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS,
            element: <SubscriptionsDetails />,
        },
        {
            path: BASE_PATH_TO_ADD_SUBSCRIPTIONS_DETAILS,
            element: <CreateSubscriptionDetails />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS_DETAILS}/:ID`,
            element: <UpdateSubscriptionDetails />,
        },
        {
            path: BASE_PATH_TO_SUBSCRIPTIONS_LIST,
            element: <SubscriptionsList />,
        },
        {
            path: BASE_PATH_TO_ADD_SUBSCRIPTIONS,
            element: <SubscriptionCreationForm />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_SUBSCRIPTIONS}/:ID`,
            element: <UpdateSubscription />,
        },
        {
            path: BASE_PATH_TO_VOUCHER_LIST,
            element: <VouchersList />,
        },
        {
            path: BASE_PATH_TO_ADD_VOUCHER,
            element: <AddRedeemVoucherConfig />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_VOUCHER}/:ID`,
            element: <UpdateRedeemVoucherConfig />,
        },
        {
            path: BASE_PATH_TO_PURCHASE_USER_SUBSCRIPTION,
            element: <PurchaseUserSubscription />,
        },
        {
            path: BASE_PATH_TO_AFA_LIST,
            element: <ViewAFA />,
        },
        {
            path: BASE_PATH_TO_UPDATE_AFA,
            element: <UpdateAFA />,
        },
        {
            path: BASE_PATH_TO_ADD_AFA,
            element: <AddAFA />,
        },
        {
            path: BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST,
            element: <PaymentGatewayList />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_PAYMENT_GATEWAY_CONFIG}/:ID`,
            element: <UpdatePaymentGatewayConfig />,
        },
        {
            path: BASE_PATH_TO_ADD_PAYMENT_GATEWAY_CONFIG,
            element: <AddPaymentGatewayConfig />,
        },
        {
            path: BASE_PATH_TO_PHONE_PE_RECURRING_INIT,
            element: <PhonePeRecurringPaymentInitList />,
        },
        {
            path: BASE_PATH_TO_PHONE_PE_PAYMENT_INIT,
            element: <PhonePePaymentInitList />,
        },
        {
            path: BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST,
            element: <InvoicesList />,
        },
        {
            path: BASE_PATH_TO_ADD_PAYMENT_INVOICE_CONFIG,
            element: <AddSubPaymentInvoice />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_PAYMENT_INVOICE_CONFIG}/:ID`,
            element: <UpdateSubPaymentInvoice />,
        },
    ],
};

export default RedeemRoutes;

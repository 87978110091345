import { Box, Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const CustomToolbar = ({ EXPORT_FILENAME, onClickCreate, displayCreate, onClickUpdate, displayUpdate }) => {
    return (
        <GridToolbarContainer
            style={{ display: "flex", justifyContent: "space-between" }}
        >
            <GridToolbarExport
                printOptions={{ disableToolbarButton: true }}
                csvOptions={{ fileName: EXPORT_FILENAME }}
                excelOptions={{ fileName: EXPORT_FILENAME }}
            />
            <Box sx={{ display: "flex" }}>
                <Box sx={{ marginRight: displayCreate === "block" && 1, display: displayCreate }}>
                    <Button variant="outlined" onClick={onClickCreate} >
                        <AddIcon /> Create
                    </Button>
                </Box>
                <Box sx={{ display: displayUpdate }}>
                    <Button variant="outlined" onClick={onClickUpdate}>
                        <EditIcon /> Update
                    </Button>
                </Box>
            </Box>
        </GridToolbarContainer>
    );
}

export default CustomToolbar;

import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { BASE_PATH_TO_ADD_COMMUNITY_GOALS, BASE_PATH_TO_UPDATE_COMMUNITY_GOALS } from "../../../../base_routes_paths/challenge_paths";
import CustomToolbar from "../../../../components/toolbar";
import { CommunityGoalServices } from "../../../../services/challenge_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CommunityGoalsList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_CommunityGoalList`;

    const userColumns = [
        {
            field: "Title",
            headerName: "Title",
            width: 400,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const Title = params.row.Title;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Locale</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(Title).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "Goal",
            headerName: "Goal",
            width: 80,
        },
        {
            field: "StartTimeUtc",
            headerName: "StartTimeUtc",
            width: 220,
        },
        {
            field: "StartDate",
            headerName: "StartDate",
            width: 120,
        },
        {
            field: "StartTime",
            headerName: "StartTime",
            width: 120,
        },
        {
            field: "EndTimeUtc",
            headerName: "EndTimeUtc",
            width: 220,
        },
        {
            field: "EndDate",
            headerName: "EndDate",
            width: 120,
        },
        {
            field: "EndTime",
            headerName: "EndTime",
            width: 120,
        },
        {
            field: "Reward",
            headerName: "Reward",
            width: 400,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const Reward = params.row.Reward;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Locale</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(Reward).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 150,
        },
        {
            field: "IsMeditationAllowed",
            headerName: "IsMeditationAllowed",
            width: 150,
        },
        {
            field: "IsRoutineActivityAllowed",
            headerName: "IsRoutineActivityAllowed",
            width: 180,
        },
        {
            field: "Unit",
            headerName: "Unit",
            width: 150,
        },
        {
            field: "DescriptionImageUrl",
            headerName: "DescriptionImageUrl",
            width: 600,
            renderCell: (params) => {
                const link = params.row.DescriptionImageUrl;
                return (
                    <>
                        <ContentCopyIcon
                            sx={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                toast.success("Copied Link!!");
                            }}
                            titleAccess="Copy"
                        />
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.copyIcon}
                        >
                            {link}
                        </a>
                    </>
                );
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);
    const navigate = useNavigate();

    const FetchResponse = async () => {
        try {
            let response = await CommunityGoalServices.getAllCommunityGoals(token, navigate, null);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        CommunityGoalServices.deleteCommunityGoals(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            href={`${BASE_PATH_TO_UPDATE_COMMUNITY_GOALS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </Link>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenges
                </Typography>
                <Typography color="text.primary">Community Goals List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_COMMUNITY_GOALS);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    rowHeight={150}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default CommunityGoalsList;

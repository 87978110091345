import {
    BASE_PATH_TO_ADD_POINT_PURCHASE_VOUCHERS,
    BASE_PATH_TO_PHONE_PE_PAYMENT_INIT_LIST,
    BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST,
    BASE_PATH_TO_PURCHASE,
    BASE_PATH_TO_UPDATE_POINT_PURCHASE_VOUCHERS,
} from "../base_routes_paths/purchase_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import PhonePePaymentInitList from "../pages/purchase/phone_pe_pyment_init/phone_pe_init_list";
import AddPointPurchaseVoucher from "../pages/purchase/point_purchase_voucher/add_voucher";
import UpdatePointPurchaseVoucher from "../pages/purchase/point_purchase_voucher/update_voucher";
import PointPurchaseVouchers from "../pages/purchase/point_purchase_voucher/voucher_list";
import { ProtectedRoutes } from "./protected_route";

const PurchaseRoutes = {
    path: BASE_PATH_TO_PURCHASE,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_PHONE_PE_PAYMENT_INIT_LIST,
            element: <PhonePePaymentInitList />,
        },
        {
            path: BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST,
            element: <PointPurchaseVouchers />,
        },
        {
            path: BASE_PATH_TO_ADD_POINT_PURCHASE_VOUCHERS,
            element: <AddPointPurchaseVoucher />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_POINT_PURCHASE_VOUCHERS}/:ID`,
            element: <UpdatePointPurchaseVoucher />,
        },
    ],
};

export default PurchaseRoutes;

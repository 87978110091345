export const BASE_PATH_TO_HOME = "/";

export const BASE_PATH_TO_ALL_USERS = "/data/all-users";
export const BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS = "/data/solo-challenge-participants";
export const BASE_PATH_TO_USER_DETAILS = "/data/user-details";
export const BASE_PATH_TO_REDEEM_INPUT = "/data/redeem-input";
export const BASE_PATH_TO_REDEEM_DATA = "/data/redeem";
export const BASE_PATH_TO_UPDATE_REDEEM = "/data/update-redeem";
export const BASE_PATH_TO_USER_ACTIVITY = "/data/user-activity";
export const BASE_PATH_TO_LEADERBOARD_INPUT = "/data/leaderboard-input";
export const BASE_PATH_TO_LEADERBOARD = "/data/leaderboard";
export const BASE_PATH_TO_TESTIMONIALS_LIST = "/data/user-testimonials";
export const BASE_PATH_TO_UPDATE_TESTIMONIAL = "/data/update-testimonial";
export const BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS = "/data/ios-user-subscriptions";
export const BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS_DETAILS = "/data/ios-user-subscriptions-details";
export const BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS = "/data/android-user-subscriptions";
export const BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS = "/data/android-user-subscriptions-details";
export const BASE_PATH_TO_USER_GOALS = "/data/user-goals";
export const BASE_PATH_TO_USER_ONBOARDING_SCREEN_RECOMMENDATIONS = "/data/user-onboarding-screen-recommendations";
export const BASE_PATH_TO_USER_SNAPSHOTS = "/data/user-snapshots";
export const BASE_PATH_TO_USER_SUBSCRIPTION = "/data/user-subscriptions-list";
export const BASE_PATH_TO_USER_RECOMMENDATIONS = "/data/user-recommendations-list";
export const BASE_PATH_TO_USER_SOLO_CHALLENGE = "/data/user-solo-challenge-list";
export const BASE_PATH_TO_USER_CHALLENGE_ATTEMPTS = "/data/user-challenge-attempts";
export const BASE_PATH_TO_USER_CHALLENGE_SNAPSHOTS = "/data/user-challenge-snapshots";
export const BASE_PATH_TO_USER_All_ACTIVITIES = "/data/user-all-activites";

export const BASE_PATH_TO_UPDATE_FEEDBACKS = "/data/update-feedbacks"; 
import { BASE_PATH_TO_ADD_THRESHOLD_POINT, BASE_PATH_TO_ADD_USER_POINTS, BASE_PATH_TO_NEW_POINT_CONFIG, BASE_PATH_TO_POINT_CONFIG_LIST, BASE_PATH_TO_THRESHOLD_POINT, BASE_PATH_TO_UPDATE_POINT_CONFIG, BASE_PATH_TO_UPDATE_THRESHOLD_POINT } from "../base_routes_paths/points_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import AdminAddUserPointConfig from "../pages/point/add_user_points";
import AddPointConfig from "../pages/point/point_config/add_point_config";
import PointConfigList from "../pages/point/point_config/poiint_config_list";
import UpdatePointConfig from "../pages/point/point_config/update_point_config";
import AddThresholdConfig from "../pages/point/threshold/add_threshold";
import ThresholdConfig from "../pages/point/threshold/get_threshold";
import UpdateThresholdConfig from "../pages/point/threshold/update_threshold";
import { ProtectedRoutes } from "./protected_route";

const PointRoutes = {
    path: "/point",
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_ADD_THRESHOLD_POINT,
            element: <AddThresholdConfig />,
        },
        {
            path: BASE_PATH_TO_THRESHOLD_POINT,
            element: <ThresholdConfig />,
        },
        {
            path: BASE_PATH_TO_UPDATE_THRESHOLD_POINT,
            element: <UpdateThresholdConfig />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_POINT_CONFIG}/:ID`,
            element: <UpdatePointConfig />,
        },
        {
            path: BASE_PATH_TO_NEW_POINT_CONFIG,
            element: <AddPointConfig />,
        },
        {
            path: BASE_PATH_TO_POINT_CONFIG_LIST,
            element: <PointConfigList />,
        },
        {
            path: BASE_PATH_TO_ADD_USER_POINTS,
            element: <AdminAddUserPointConfig />,
        }
    ],
};

export default PointRoutes;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { BASE_PATH_TO_ADD_ONBOARDING_SCREEN_RECOMMENDATIONS, BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_RECOMMENDATIONS } from "../../../../base_routes_paths/auth_paths";
import CustomToolbar from "../../../../components/toolbar";
import { OnboardinScreenRecommendationsServices } from "../../../../services/auth_services";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const OSRecommendations = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_OnboardingScreenRecommendationsList`;
    const navigate = useNavigate();
    const userColumns = [
        {
            field: "RecommendationData",
            headerName: "RecommendationData",
            width: 400,
            height: 500,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const RecommendationData = params.row.RecommendationData

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>CategoryID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RecommendationData.map((Recommendation, index) => (
                                    <tr key={index}>
                                        <td style={{ paddingRight: 25 }}>{Recommendation.CategoryID}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div >
                );
            },
        },
        {
            field: "Data",
            headerName: "SolutionData",
            width: 400,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const SolutionData = params.row.SolutionData

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>QuestionID</th>
                                    <th style={{ paddingRight: 25 }}>ValueID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SolutionData.map((Solution, index) => (
                                    <tr key={index}>
                                        <td style={{ paddingRight: 25 }}>{Solution.QuestionID}</td>
                                        <td style={{ paddingRight: 25 }}>{Solution.ValueID}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div >
                );
            },
        },
        {
            field: "IsDefault",
            headerName: "IsDefault",
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await OnboardinScreenRecommendationsServices.getAllOnboardinScreenRecommendations(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        OnboardinScreenRecommendationsServices.deleteOnboardinScreenRecommendations(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            href={`${BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_RECOMMENDATIONS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </Link>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Onboarding Screen Recommendations List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_ONBOARDING_SCREEN_RECOMMENDATIONS);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    rowHeight={150}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default OSRecommendations;

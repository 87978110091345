import { useState, useEffect } from "react";
import {
    DataGridPremium,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Game.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import CustomToolbar from "../../../../components/toolbar";
import { GameLevelServices } from "../../../../services/games_services";
import { BASE_PATH_TO_ADD_GAMES, BASE_PATH_TO_UPDATE_GAMES } from "../../../../base_routes_paths/game_paths";

const GameLevels = () => {
    const userColumns = [
        { field: "id", headerName: "ID", width: 230 },
        {
            field: "ChallengeThumbURL",
            headerName: "Challenge Thumbnail",
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={classes.cellWithImg}>
                        <img
                            className={classes.cellImg}
                            src={params.row.ChallengeThumbURL || "https://areno.in/img/group-1@2x.svg"}
                            alt=""
                        />
                    </div>
                );
            },
        },
        {
            field: "WorkoutID",
            headerName: "WorkoutID",
            width: 80,
        },
        {
            field: "Level",
            headerName: "Level",
            width: 80,
        },
        {
            field: "HighestScorer",
            headerName: "HighestScorer",
            width: 500,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const HighestScorer = params.row.HighestScorer;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>HighestScore</th>
                                    <th style={{ paddingRight: 25 }}>UserID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ paddingRight: 25 }}>{HighestScorer.HighestScore}</td>
                                    <td style={{ paddingRight: 25 }}>{HighestScorer.UserID}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "NoOfAttempts",
            headerName: "NoOfAttempts",
            width: 120,
        },
        {
            field: "Frequency",
            headerName: "Frequency",
            width: 80,
        },
        {
            field: "Goal",
            headerName: "Goal",
            width: 80,
        },
        {
            field: "Threshold",
            headerName: "Threshold",
            width: 100,
        },
        {
            field: "TimeDuration",
            headerName: "TimeDuration",
            width: 100,
        },
        {
            field: "IsUnlimitedAttempts",
            headerName: "IsUnlimitedAttempts",
            width: 140,
        },
        {
            field: "IsPaid",
            headerName: "IsPaid",
            width: 120,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 120,
        },
        {
            field: "DeviceType",
            headerName: "DeviceType",
            width: 120,
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_GameLevels`;
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate()

    const FetchApi = async () => {
        try {
            let response = await GameLevelServices.getAllGameLevels(token, navigate, null);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            let result = response.data;
            let input = result.map(({ _id: id, ...result }) => ({
                id,
                ...result,
            }));
            setData(input);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    const handleDelete = (id) => {
        GameLevelServices.deleteGameLavel(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_GAMES}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Game
                        </Typography>
                        <Typography color="text.primary">Game Levels List</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns.concat(actionColumn)}
                        slots={{
                            toolbar: () => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    onClickCreate={() => {
                                        navigate(BASE_PATH_TO_ADD_GAMES);
                                    }}
                                    displayCreate={""}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default GameLevels;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__wiIYs {
    font: inherit;
  background-color: transparent;
  border: 1px solid #4f005f;
  color: #4f005f;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.Button_button__wiIYs:hover,
.Button_button__wiIYs:active {
    background: white;
    border-color: #4f005f;
    color: #4f005f;
}

.Button_button__wiIYs:focus {
    outline: none;
}

`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf,6BAA6B;EAC7B,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;IAEI,iBAAiB;IACjB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".button {\n    font: inherit;\n  background-color: transparent;\n  border: 1px solid #4f005f;\n  color: #4f005f;\n  font-weight: bold;\n  padding: 0.5rem 1.5rem;\n  border-radius: 6px;\n  cursor: pointer;\n}\n\n.button:hover,\n.button:active {\n    background: white;\n    border-color: #4f005f;\n    color: #4f005f;\n}\n\n.button:focus {\n    outline: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__wiIYs`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__6sIvw {
  background-color: white;
  width: 77vw;
}
.Table_cellWrapper__L9Nzz {
  display: flex;
  align-items: center;
}
.Table_image__Pc3l6 {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.Table_status__WAZtz {
  padding: 5px;
  border-radius: 5px;
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
}
/* .status_Approved {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
} */
/* .status_Pending {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
} */
`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;AAC1C;AACA;;;GAGG;AACH;;;GAGG","sourcesContent":[".table {\n  background-color: white;\n  width: 77vw;\n}\n.cellWrapper {\n  display: flex;\n  align-items: center;\n}\n.image {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  margin-right: 10px;\n  object-fit: cover;\n}\n\n.status {\n  padding: 5px;\n  border-radius: 5px;\n  color: green;\n  background-color: rgba(0, 128, 0, 0.151);\n}\n/* .status_Approved {\n  color: green;\n  background-color: rgba(0, 128, 0, 0.151);\n} */\n/* .status_Pending {\n  color: goldenrod;\n  background-color: rgba(189, 189, 3, 0.103);\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__6sIvw`,
	"cellWrapper": `Table_cellWrapper__L9Nzz`,
	"image": `Table_image__Pc3l6`,
	"status": `Table_status__WAZtz`
};
export default ___CSS_LOADER_EXPORT___;

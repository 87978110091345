import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import classes from "./Widget.module.css";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { StatusCode } from "../../utils";
import AUTH_CONTEXT from "../../store";
import { BASE_PATH_TO_ALL_USERS } from "../../base_routes_paths/data_paths";
import ARENO_API_ENDPOINT from "../../api";

const Widget = ({ type }) => {
  const navigate = useNavigate();
  const { token, setToken, updateToken } = AUTH_CONTEXT();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [UserCount, setUserCount] = useState(0);
  const [percent, setPercent] = useState(0);

  const FetchApi = async () => {
    try {
      let response = await fetch(`${ARENO_API_ENDPOINT}/auth/GetAllUsers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          TimeDuration: "week"
        })
      });
      if (!response.ok) {
        toast.error("Authentication Failed, please login again!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


      StatusCode(response, navigate);
      response = await response.json();
      if (response.status === 207) {
        return toast
          .error(response.error, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          })
      }
      setIsDataFetched(true);

      toast.success("Total-Users fetched successfully!!", {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return response.data;
    } catch (e) {
      setIsDataFetched(false);
      toast.error(e.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchTotalUsers = async () => {
    setIsLoading(true);
    const result = await FetchApi();
    setUserCount(result.UserCount);
    let LastDayRegisteredUsers =
      result.GraphData[result.GraphData.length - 1];
    let perc =
      (LastDayRegisteredUsers.Count / (result.UserCount - LastDayRegisteredUsers.Count)) *
      100;
    setPercent(parseFloat(isNaN(perc) ? 0 : perc).toFixed(2));
    setIsLoading(false);
  };

  let data;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        link: "See all users",
        icon: (
          <PersonOutlinedIcon
            className={classes.icon}
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className={classes.widget}>
      <div className={classes.left}>
        <span className={classes.title}>{data.title}</span>
        <span className={classes.counter}>
          {!isDataFetched ? (
            isLoading ? (
              <Box sx={{ display: "flex", margin: "10px 0px" }}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <button className={`${classes.button}`} onClick={fetchTotalUsers}>
                Fetch Total-Users
              </button>
            )
          ) : (
            UserCount
          )}
        </span>
        <Link to={BASE_PATH_TO_ALL_USERS}>
          <span className={classes.link}>{data.link}</span>
        </Link>
      </div>
      <div className={classes.right}>
        <div className={classes.percentage_positive}>
          <KeyboardArrowUpIcon />
          {percent} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;

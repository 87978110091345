import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    DataGridPremium,
} from "@mui/x-data-grid-premium";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const userColumns = [
    {
        field: "ChallengeID",
        headerName: "ChallengeID",
        width: 230,
        renderCell: (params) => {
            return (<a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.ChallengeID}>{params.row.ChallengeID}</a>)
        }
    },
    {
        field: "UserName",
        headerName: "UserName",
        width: 180,
    },
    {
        field: "Location",
        headerName: "Location",
        width: 180,
    },
    {
        field: "Age",
        headerName: "Age",
        width: 180,
    },
    {
        field: "Gender",
        headerName: "Gender",
        width: 180,
    },
    {
        field: "Height",
        headerName: "Height",
        width: 180,
    },
    {
        field: "Weight",
        headerName: "Weight",
        width: 180,
    },
    {
        field: "Date",
        headerName: "Date",
        width: 180,
    },
    {
        field: "Time",
        headerName: "Time",
        width: 300,
    },
    {
        field: "CorporateID",
        headerName: "CorporateID",
        width: 180,
    },
    {
        field: "ChallengeName",
        headerName: "ChallengeName",
        width: 180,
    },
    {
        field: "ChallengeCategory",
        headerName: "ChallengeCategory",
        width: 180,
    },
    {
        field: "AttemptResult",
        headerName: "AttemptResult",
        width: 180,
    },
    {
        field: "IpAddress",
        headerName: "IpAddress",
        width: 180,
    },
    {
        field: "IsUserAttempted",
        headerName: "IsUserAttempted",
        width: 120,
    },
    {
        field: "IsCompleted",
        headerName: "IsCompleted",
        width: 250,
    },
];

const UserChallengeAttempt = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(true);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const params = useParams();
    const [rowsCount, setRowsCount] = useState(1);
    const { token } = AUTH_CONTEXT();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const FetchResponseGetAllUserDetails = async (
        page,
        pageSize
    ) => {
        setIsLoaderLoading(true);
        setIsDataFetched(false);
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetUserChallengeAttemptData?page=${page}&limit=${pageSize}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        UserID: params.ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails(
                page,
                pageSize
            )
                .then((result) => {
                    let input = result.data.map((obj) => {
                        const newObject = { ...obj };
                        newObject.Dated = new Date(
                            newObject.Dated || new Date()
                        ).toLocaleString("en-Us", { timeZone: "Asia/Kolkata" });
                        const { _id, ...rest } = newObject;
                        return {
                            id: _id,
                            ...rest,
                        };
                    });
                    setRowsCount(result.length)
                    setData((prevData) => [...prevData, ...input]);
                    setIsLoaderLoading(false);
                })
                .catch((err) => console.log(err));
        }
    }, [page, pageSize, data, isDataFetched]);

    return (
        <div className={classes.datatable}>
            {isLoading && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Data
                        </Typography>
                        <Typography color="text.primary">Users Challenge Attempts</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        checkboxSelection
                        checkboxSelectionVisibleOnly
                        disableRowSelectionOnClick
                        rows={data}
                        rowCount={rowsCount}
                        columns={userColumns}
                        rowsPerPageOptions={[20]}
                        pageSize={pageSize}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: pageSize, page: page - 1 },
                            },
                        }}
                        pageSizeOptions={[20, 50, 100]}
                        onPaginationModelChange={({ pageSize, page }) => {
                            setPage(page + 1);
                            setPageSize(pageSize);
                            FetchResponseGetAllUserDetails(page + 1, pageSize);
                        }}
                        loading={isLoaderLoading}
                    />
                </>
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default UserChallengeAttempt;

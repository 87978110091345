import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_ADD_AFA, BASE_PATH_TO_UPDATE_AFA } from "../../../../base_routes_paths/redeem_paths";
import { AFAServices } from "../../../../services/redeem_services";

const ViewAFA = () => {
    const navigate = useNavigate();
    const [Data, setData] = useState({});
    const { token } = AUTH_CONTEXT();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const FetchResponse = async () => {
        try {
            let response = await AFAServices.getAllAFA(token, navigate);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setData(result);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return !isLoading ? (
        <Loader />
    ) : (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">Redeem</Typography>
                <Typography color="text.primary">View App Feature Available</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>App Feature Available</div>

                {/* Community Goal Section */}
                <div className={classes.subfields}>
                    <h3>Community</h3>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsCommunityGoalSectionActiveIOS">iOS: </label>
                        <select
                            name="IsCommunityGoalSectionActiveIOS"
                            id="IsCommunityGoalSectionActiveIOS"
                            value={Data?.CommunityGoalSectionConfig?.IOS}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsCommunityGoalSectionActiveAndroid">Android: </label>
                        <select
                            name="IsCommunityGoalSectionActiveAndroid"
                            id="IsCommunityGoalSectionActiveAndroid"
                            value={Data?.CommunityGoalSectionConfig?.Android}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                </div>

                {/* Game Section */}
                <div className={classes.subfields}>
                    <h3>Game</h3>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsGameSectionActiveIOS">iOS: </label>
                        <select
                            name="IsGameSectionActiveIOS"
                            id="IsGameSectionActiveIOS"
                            value={Data?.GameSectionConfig?.IOS}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsGameSectionActiveAndroid">Android: </label>
                        <select
                            name="IsGameSectionActiveAndroid"
                            id="IsGameSectionActiveAndroid"
                            value={Data?.GameSectionConfig?.Android}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                </div>

                {/* Meditation Section */}
                <div className={classes.subfields}>
                    <h3>Meditation</h3>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsMeditationSectionActiveIOS">iOS: </label>
                        <select
                            name="IsMeditationSectionActiveIOS"
                            id="IsMeditationSectionActiveIOS"
                            value={Data?.MeditationSectionConfig?.IOS}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsMeditationSectionActiveAndroid">Android: </label>
                        <select
                            name="IsMeditationSectionActiveAndroid"
                            id="IsMeditationSectionActiveAndroid"
                            value={Data?.MeditationSectionConfig?.Android}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                </div>

                {/* Yoga Section */}
                <div className={classes.subfields}>
                    <h3>Yoga</h3>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsYogaSectionActiveIOS">iOS: </label>
                        <select
                            name="IsYogaSectionActiveIOS"
                            id="IsYogaSectionActiveIOS"
                            value={Data?.YogaSectionConfig?.IOS}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="IsYogaSectionActiveAndroid">Android: </label>
                        <select
                            name="IsYogaSectionActiveAndroid"
                            id="IsYogaSectionActiveAndroid"
                            value={Data?.YogaSectionConfig?.Android}
                            disabled
                        >
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>
                </div>

                {/* Buttons for Edit and Add */}
                <div style={{ display: "flex", marginTop: "2rem" }}>
                    <div>
                        <Button onClick={() => navigate(`${BASE_PATH_TO_UPDATE_AFA}`)}>
                            Edit App Feature Available
                        </Button>
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                        <Button onClick={() => navigate(BASE_PATH_TO_ADD_AFA)}>
                            Add App Feature Available
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ViewAFA;

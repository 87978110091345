import AuthPageLayout from "../layouts/auth_page_layout";
import AuthForm from "../pages/auth";

const LandingPageRoutes = {
    path: '/auth',
    element: <AuthPageLayout />,
    children: [
        {
            path: '/auth',
            element: <AuthForm />
        },
    ]
};

export default LandingPageRoutes;

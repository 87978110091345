import { useState, useEffect, useContext } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { StatusCode } from "../../../../utils";
import ARENO_API_ENDPOINT from "../../../../api";
import { BASE_PATH_TO_USER_SNAPSHOTS } from "../../../../base_routes_paths/data_paths";

const UserSoloChallengeAttempts = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_UserSoloChallengeAttempts`;
    const params = useParams()

    const userColumns = [
        {
            field: "UserID",
            headerName: "UserID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={"/GetUserDetails/" + params.row.UserID}>{params.row.UserID}</a>
                );
            },
        },
        {
            field: "ChallengeID",
            headerName: "Challenege ID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={"/SoloChallengeDetails/" + params.row.ChallengeID}>{params.row.ChallengeID}</a>
                );
            },
        },
        {
            field: "WorkoutID",
            headerName: "WorkoutID",
            width: 130,
            renderCell: (params) => {
                const getWorkoutName = (number) => {
                    switch (number) {
                        case 1:
                            return 'Push-ups';
                        case 2:
                            return 'Planks';
                        case 3:
                            return 'Squats';
                        case 4:
                            return 'High Knees';
                        case 5:
                            return 'Lunges';
                        case 6:
                            return 'Situps';
                        case 7:
                            return 'Knee Pushups';
                        case 8:
                            return 'Jumping Jacks';
                        default:
                            return 'Unknown Workout';
                    }
                }
                return <>{`${getWorkoutName(params.row.WorkoutID)} (${params.row.WorkoutID})`}</>
            }
        },
        {
            field: "RegistrationID",
            headerName: "RegistrationID",
            width: 230,
        },
        {
            field: "Level",
            headerName: "Level",
            width: 80,
        },
        {
            field: "TotalCount",
            headerName: "TotalCount",
            width: 120,
        },
        {
            field: "IsSuccess",
            headerName: "IsSuccess",
            width: 120,
        },
        {
            field: "IsTimeOverForChallenge",
            headerName: "IsTimeOverForChallenge",
            width: 230,
        },
        {
            field: "StartDate",
            headerName: "StartDate",
            width: 230,
        },
        {
            field: "EndDate",
            headerName: "EndDate",
            width: 230,
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetSoloChallengeUserDetailsByUserAndChallengeID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserID: params.UserID,
                        ChallengeID: params.ChallengeID,
                    }),
                }
            );

            setIsLoading(false);
            StatusCode(response, navigate);
            response = await response.json();
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
                <div style={{ marginLeft: "auto" }}>
                    <Button
                        color="primary"
                        sx={{ margin: 0, padding: 0 }}
                    >
                        <a target="_blank" rel="noreferrer" href={
                            BASE_PATH_TO_USER_SNAPSHOTS +
                            "/"
                            +
                            params.UserID +
                            "/" +
                            params.ChallengeID
                        }
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                    </Button>
                </div>
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default UserSoloChallengeAttempts;

import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import CustomToolbar from "../../../../components/toolbar";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { RoutineActivitiesServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_ADD_ROUTINE_ACTIVITY, BASE_PATH_TO_UPDATE_ROUTINE_ACTIVITY } from "../../../../base_routes_paths/challenge_paths";

const RoutineActivityList = () => {
    const userColumns = [
        {
            field: "RoutineActivityName",
            headerName: "RoutineActivityName",
            width: 200,
        },
        {
            field: "RoutineActivityName",
            headerName: "RoutineActivityName",
            width: 300,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const RoutineActivityName = params.row.RoutineActivityName;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Key</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(RoutineActivityName).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
        {
            field: "RoutineActivityID",
            headerName: "RoutineActivityID",
            width: 130,
        },
        {
            field: "RoutineActivityCalories",
            headerName: "RoutineActivityCalories",
            width: 180,
        },
        // {
        //     field: "RoutineActivityInstructions",
        //     headerName: "Routine Activity Instructions",
        //     width: 300,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (params) => {
        //         const instructions = params.row.RoutineActivityInstructions;

        //         return (
        //             <div>
        //                 <table style={{ textAlign: "center" }}>
        //                     <thead>
        //                         <tr>
        //                             <th style={{ paddingRight: 25 }}>Locale</th>
        //                             <th style={{ paddingRight: 25 }}>Instructions</th>
        //                         </tr>
        //                     </thead>
        //                     <tbody>
        //                         {Object.entries(instructions).map(([locale, instArray]) => (
        //                             <tr key={locale}>
        //                                 <td style={{ paddingRight: 25 }}>{locale}</td>
        //                                 <td style={{ paddingRight: 25 }}>
        //                                     <ol style={{ margin: "1rem 0", paddingInlineStart: "20px" }}>
        //                                         {instArray.map((instruction, index) => (
        //                                             <li key={index}>{instruction}</li>
        //                                         ))}
        //                                     </ol>
        //                                 </td>
        //                             </tr>
        //                         ))}
        //                     </tbody>
        //                 </table>
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "RoutineActivityFocusArea",
        //     headerName: "Routine Activity Focus Area",
        //     width: 200,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (params) => {
        //         const focusAreas = params.row.RoutineActivityFocusArea;

        //         return (
        //             <div>
        //                 <table style={{ textAlign: "center" }}>
        //                     <thead>
        //                         <tr>
        //                             <th style={{ paddingRight: 25 }}>Locale</th>
        //                             <th style={{ paddingRight: 25 }}>Focus Areas</th>
        //                         </tr>
        //                     </thead>
        //                     <tbody>
        //                         {Object.entries(focusAreas).map(([locale, areasArray]) => (
        //                             <tr key={locale}>
        //                                 <td style={{ paddingRight: 25 }}>{locale}</td>
        //                                 <td style={{ paddingRight: 25 }}>
        //                                     <ol style={{ margin: "1rem 0", paddingInlineStart: "20px" }}>
        //                                         {areasArray.map((area, index) => (
        //                                             <li key={index}>{area}</li>
        //                                         ))}
        //                                     </ol>
        //                                 </td>
        //                             </tr>
        //                         ))}
        //                     </tbody>
        //                 </table>
        //             </div>
        //         );
        //     },
        // },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 80,
        },
        {
            field: "IsWorkout",
            headerName: "IsWorkout",
            width: 80,
        }
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_RoutineActivitiesList`;
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await RoutineActivitiesServices.getAllRoutineActivity(token, navigate);
            if (response.status === 207) {
                toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        RoutineActivitiesServices.deleteRoutineActivity(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_ROUTINE_ACTIVITY}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenge
                </Typography>
                <Typography color="text.primary">Routine Activities List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_ROUTINE_ACTIVITY);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        )
                    }}
                    pagination
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default RoutineActivityList;

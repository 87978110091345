import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Sundries.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_ANNOUNCEMENTS_LIST } from "../../../../base_routes_paths/sundries_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { AnnouncementsServices } from "../../../../services/sundries_services";

const AddAnnouncement = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [TitleHeading, setTitleHeading] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [Descriptions, setDescriptions] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [link, setLink] = useState("");
    const [WebUrl, setWebUrl] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [sortID, setSortID] = useState("");
    const [CorporateID, setCorporateID] = useState("");
    const [Country, setCountry] = useState("");

    const SubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Descriptions: Descriptions,
            TitleHeading: TitleHeading,
            Link: link,
            IsActive: isActive,
            SortID: sortID,
            WebUrl: WebUrl,
            CorporateID: CorporateID,
            Country: Country
        }

        try {
            let response = await AnnouncementsServices.addAnnouncement(token, navigate, Data);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIsLoading(false);
            navigate(BASE_PATH_TO_ANNOUNCEMENTS_LIST);
            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleTitleChange = (e, locale) => {
        setTitleHeading(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    const handleDescriptionChange = (e, locale) => {
        setDescriptions(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Sundries
                </Typography>
                <Typography color="text.primary">Add Announcement</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add Announcement</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.subfields}>
                        <h3>TitleHeadings</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Title (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={TitleHeading["en-US"]}
                                    onChange={(e) => handleTitleChange(e, "en-US")}
                                    placeholder="Enter Title for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Title (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={TitleHeading["en-IN"]}
                                    onChange={(e) => handleTitleChange(e, "en-IN")}
                                    placeholder="Enter Title for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Title (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={TitleHeading["en-CA"]}
                                    onChange={(e) => handleTitleChange(e, "en-CA")}
                                    placeholder="Enter Title for en-CA"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CorporateID">CorporateID</label>
                        <input
                            type="text"
                            id="CorporateID"
                            value={CorporateID}
                            onChange={(e) => setCorporateID(e.target.value)}
                            placeholder="Enter CorporateID"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Country">Country</label>
                        <input
                            type="text"
                            id="Country"
                            value={Country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Enter Country"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Link">Link</label>
                        <input
                            type="url"
                            id="Link"
                            required
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            placeholder="Enter Link"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="WebUrl">WebUrl</label>
                        <input
                            type="url"
                            id="WebUrl"
                            value={WebUrl}
                            onChange={(e) => setWebUrl(e.target.value)}
                            placeholder="Enter WebUrl"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value === "true")}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="SortID">SortID</label>
                        <input
                            type="number"
                            id="SortID"
                            value={sortID}
                            onChange={(e) => setSortID(parseInt(e.target.value))}
                            placeholder="Enter SortID"
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Descriptions["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Descriptions["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Descriptions["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddAnnouncement;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Auth.module.css";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { AppVersionServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_ADD_VERSION_CONFIG, BASE_PATH_TO_UPDATE_VERSION_CONFIG } from "../../../../base_routes_paths/auth_paths";

const VersionConfig = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [IsUpdateRequired, setIsUpdateRequired] = useState(false);
    const [LatestBuildNumber, setLatestBuildNumber] = useState(0);
    const { token } = AUTH_CONTEXT();
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await AppVersionServices.getAppVersionServices(token, navigate);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    console.log(result.IsUpdateRequired);
                    setIsUpdateRequired(result.IsUpdateRequired);
                    setLatestBuildNumber(result.LatestBuildNumber);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">View Version Configuration</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                {isLoading && (
                    <div>
                        <div className={classes.Main_div}>App Version Configuration</div>
                        <div className={classes.control} style={{ marginBottom: "1rem" }}>
                            <div className={classes.detailItem}>
                                <span className={classes.itemKey}>Is Update Required : </span>
                                <span className={classes.itemValue}>
                                    {IsUpdateRequired === false ? (
                                        <span className={classes.itemValue}>false</span>
                                    ) : (
                                        <span className={classes.itemValue}>true</span>
                                    )}
                                </span>
                            </div>
                            <div className={classes.detailItem}>
                                <span className={classes.itemKey}>Latest BuildNumber : </span>
                                <span className={classes.itemValue}>{LatestBuildNumber}</span>
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <Button
                                    onClick={() => {
                                        navigate(BASE_PATH_TO_UPDATE_VERSION_CONFIG);
                                    }}
                                >
                                    Update Version Config
                                </Button>
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                                <Button
                                    onClick={() => {
                                        navigate(BASE_PATH_TO_ADD_VERSION_CONFIG);
                                    }}
                                >
                                    Add New Version Config
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {!isLoading && <h3>Loading..</h3>}
            </Card>
        </div>
    );
};

export default VersionConfig;

import React from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import {
  BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS,
  BASE_PATH_TO_ALL_USERS,
  BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS,
  BASE_PATH_TO_HOME,
  BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS,
  BASE_PATH_TO_LEADERBOARD_INPUT,
  BASE_PATH_TO_REDEEM_INPUT,
  BASE_PATH_TO_TESTIMONIALS_LIST,
  BASE_PATH_TO_USER_ACTIVITY,
  BASE_PATH_TO_USER_GOALS,
  BASE_PATH_TO_USER_ONBOARDING_SCREEN_RECOMMENDATIONS,
} from "../../base_routes_paths/data_paths";
import {
  BASE_PATH_TO_ANNOUNCEMENTS_LIST,
  BASE_PATH_TO_ENQUIRY_LIST,
  BASE_PATH_TO_REMINDER_IMAGES_LIST,
} from "../../base_routes_paths/sundries_paths";
import { BASE_PATH_TO_PHONE_PE_PAYMENT_INIT_LIST, BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST } from "../../base_routes_paths/purchase_paths";
import {
  BASE_PATH_TO_ADD_USER_POINTS,
  BASE_PATH_TO_POINT_CONFIG_LIST,
  BASE_PATH_TO_THRESHOLD_POINT,
} from "../../base_routes_paths/points_paths";
import {
  BASE_PATH_TO_DEVICE_URL_LIST,
  BASE_PATH_TO_MENU_URL_LIST,
} from "../../base_routes_paths/dashboard_paths";
import {
  BASE_PATH_TO_SPECIAL_REFERRAL_LIST,
  BASE_PATH_TO_VIEW_COMMUNITY_LINK,
  BASE_PATH_TO_VIEW_IP_ADDRESS,
  BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS,
  BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS,
  BASE_PATH_TO_VIEW_VERSION_CONFIG,
} from "../../base_routes_paths/auth_paths";
import {
  BASE_PATH_TO_CATEGORY_LIST,
  BASE_PATH_TO_CCR_LIST,
  BASE_PATH_TO_CHALLENGES_LIST,
  BASE_PATH_TO_COMMUNITY_GOALS_LIST,
  BASE_PATH_TO_CORPORATE_LIST,
  BASE_PATH_TO_MEDITATIONS_LIST,
  BASE_PATH_TO_ROUTINE_ACTIVITY_LIST,
  BASE_PATH_TO_ROUTINE_PLANS_LIST,
  BASE_PATH_TO_SOLO_CHALLENGE_LIST,
  BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST,
  BASE_PATH_TO_WORKOUT_LIST,
} from "../../base_routes_paths/challenge_paths";
import { BASE_PATH_TO_AFA_LIST, BASE_PATH_TO_FREE_USER_CONFIG_VIEW, BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST, BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST, BASE_PATH_TO_PHONE_PE_PAYMENT_INIT, BASE_PATH_TO_PHONE_PE_RECURRING_INIT, BASE_PATH_TO_PURCHASE_USER_SUBSCRIPTION, BASE_PATH_TO_SUBSCRIPTIONS_LIST, BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_VOUCHER_LIST } from "../../base_routes_paths/redeem_paths";
import { BASE_PATH_TO_GAMES_LIST } from "../../base_routes_paths/game_paths";
import { BASE_PATH_TO_CLANS_LIST } from "../../base_routes_paths/clans_paths";

export const SidebarData = [
  {
    title: "Home",
    path: "",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Dashboard",
        path: BASE_PATH_TO_HOME,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Data",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Users",
        path: BASE_PATH_TO_ALL_USERS,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Solo Challenge Participants",
        path: BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Redeemption Details",
        path: BASE_PATH_TO_REDEEM_INPUT,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "UserActivity",
        path: BASE_PATH_TO_USER_ACTIVITY,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Leaderboard",
        path: BASE_PATH_TO_LEADERBOARD_INPUT,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Testimonials",
        path: BASE_PATH_TO_TESTIMONIALS_LIST,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Android User Subscriptions",
        path: BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "IOS User Subscriptions",
        path: BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "User Goals",
        path: BASE_PATH_TO_USER_GOALS,
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "User Onboarding Screen Recommendations",
        path: BASE_PATH_TO_USER_ONBOARDING_SCREEN_RECOMMENDATIONS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Auth Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Community Link",
        path: BASE_PATH_TO_VIEW_COMMUNITY_LINK,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Special Referral Config",
        path: BASE_PATH_TO_SPECIAL_REFERRAL_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Onboarding Screen Questions",
        path: BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Onboarding Screen Recommendations",
        path: BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "IP Address",
        path: BASE_PATH_TO_VIEW_IP_ADDRESS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Challenge Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Challenges",
        path: BASE_PATH_TO_CHALLENGES_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Corporate",
        path: BASE_PATH_TO_CORPORATE_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "WorkoutID",
        path: BASE_PATH_TO_WORKOUT_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "CategoryID",
        path: BASE_PATH_TO_CATEGORY_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Solo Challenges",
        path: BASE_PATH_TO_SOLO_CHALLENGE_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Solo Challenge Milestones",
        path: BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Meditations",
        path: BASE_PATH_TO_MEDITATIONS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Community Goals",
        path: BASE_PATH_TO_COMMUNITY_GOALS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Country Conversion Rates",
        path: BASE_PATH_TO_CCR_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Routine Actvities",
        path: BASE_PATH_TO_ROUTINE_ACTIVITY_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Routine Plans",
        path: BASE_PATH_TO_ROUTINE_PLANS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Clan Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Clans List",
        path: BASE_PATH_TO_CLANS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      }
    ],
  },
  {
    title: "DashBoard Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "DeviceUrl List",
        path: BASE_PATH_TO_DEVICE_URL_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "MenuUrl List",
        path: BASE_PATH_TO_MENU_URL_LIST,
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Point Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Threshold Config",
        path: BASE_PATH_TO_THRESHOLD_POINT,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Add User Points",
        path: BASE_PATH_TO_ADD_USER_POINTS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "PointConfig",
        path: BASE_PATH_TO_POINT_CONFIG_LIST,
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Redeem Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Vouchers",
        path: BASE_PATH_TO_VOUCHER_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Subscription",
        path: BASE_PATH_TO_SUBSCRIPTIONS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Subscription Detail",
        path: BASE_PATH_TO_VIEW_SUBSCRIPTIONS_DETAILS,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Free User Config",
        path: BASE_PATH_TO_FREE_USER_CONFIG_VIEW,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Purchase User Subscription",
        path: BASE_PATH_TO_PURCHASE_USER_SUBSCRIPTION,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "App Feature Available",
        path: BASE_PATH_TO_AFA_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Payment Gateway Config",
        path: BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Phone Pe Payment",
        path: BASE_PATH_TO_PHONE_PE_PAYMENT_INIT,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Phone Pe Recurring Payment",
        path: BASE_PATH_TO_PHONE_PE_RECURRING_INIT,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Sub Payment Invoice Config",
        path: BASE_PATH_TO_PAYMENT_INVOICE_CONFIG_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Streak Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [],
  },
  {
    title: "Purchase Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Point Purchase Voucher",
        path: BASE_PATH_TO_POINT_PURCHASE_VOUCHERS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "PhonePe Payment Init",
        path: BASE_PATH_TO_PHONE_PE_PAYMENT_INIT_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Games Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Game Levels",
        path: BASE_PATH_TO_GAMES_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Sundries Services",
    path: "",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Announcements",
        path: BASE_PATH_TO_ANNOUNCEMENTS_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Reminder Images",
        path: BASE_PATH_TO_REMINDER_IMAGES_LIST,
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      // {
      //   title: "Enquiries",
      //   path: BASE_PATH_TO_ENQUIRY_LIST,
      //   icon: <IoIcons.IoIosPaper />,
      //   cName: "sub-nav",
      // },
    ],
  },
];

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Auth.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { CommunityURLServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_ADD_COMMUNITY_LINK, BASE_PATH_TO_UPDATE_COMMUNITY_LINK } from "../../../../base_routes_paths/auth_paths";
import Loader from "../../../../components/Loader/Loader";

const ViewCommunityUrlConfig = () => {
    const navigate = useNavigate();
    const [url, setUrl] = useState();
    const { token } = AUTH_CONTEXT();

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const FetchResponse = async () => {
        try {
            let response = await CommunityURLServices.getCommunityLink(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setUrl(result.Url);
                })
                .catch((err) => console.log(err));
        }
    }, []);


    return (
        !isLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Auth
                    </Typography>
                    <Typography color="text.primary">View Community Link</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>WhatsApp Community Link</div>
                    <div className={classes.control}>
                        <label htmlFor="Url">Community Link</label>
                        <input
                            disabled
                            value={url}
                            placeholder="Enter Community Url"
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button
                                onClick={() => {
                                    navigate(`${BASE_PATH_TO_UPDATE_COMMUNITY_LINK}`);
                                }}
                            >
                                Edit Community Link
                            </Button>
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                            <Button
                                onClick={() => {
                                    navigate(BASE_PATH_TO_ADD_COMMUNITY_LINK);
                                }}

                            >
                                Add New Community Link
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
    );
};

export default ViewCommunityUrlConfig;

import { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import classes from "../Data.module.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../../store";
import Loader from "../../../components/Loader/Loader";
import { OnboardinScreenRecommendationsServices } from "../../../services/auth_services";
import { BASE_PATH_TO_USER_RECOMMENDATIONS } from "../../../base_routes_paths/data_paths";

const OsRecommendationsList = () => {
    const { token, fileName } = AUTH_CONTEXT()
    const EXPORT_FILENAME = `${fileName}_OnboardingScreenRecommendationsList`;

    const userColumns = [
        {
            field: "UserID",
            headerName: "UserID",
            width: 230,
            renderCell: (params) => {
                return (
                    <a target="_blank" rel="noreferrer" href={"/GetUserDetails/" + params.row.UserID}>{params.row.UserID}</a>
                );
            },
        },
        {
            field: "Points",
            headerName: "Points",
            width: 80,
        },
        {
            field: "RecommendationData",
            headerName: "RecommendationData",
            width: 300,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const RecommendationData = params.row.RecommendationData

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>CategoryID</th>
                                    <th style={{ paddingRight: 25 }}>CategoryName</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RecommendationData.map((Data, index) => (
                                    <tr key={index}>
                                        <td style={{ paddingRight: 25 }}>{Data.CategoryID}</td>
                                        <td style={{ paddingRight: 25 }}>{Data.CategoryName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div >
                );
            },
        },
        {
            field: "IsApplicableForRewards",
            headerName: "IsApplicableForRewards",
            width: 180,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await OnboardinScreenRecommendationsServices.getAllUsersOnboardinScreenRecommendations(token, navigate);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            to={`${BASE_PATH_TO_USER_RECOMMENDATIONS}/` + params.row.UserID}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </Link>
                    </div>
                );
            },
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div className={classes.datatable}>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pagination
                    rowHeight={150}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default OsRecommendationsList;

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { CategoryServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_CATEGORY_LIST } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const CreateCategoryId = (props) => {
    const navigate = useNavigate();
    const [categoryID, setCategoryID] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categoryImage, setCategoryImage] = useState("");
    const [categoryImageDetailedScreen, setCategoryImageDetailedScreen] =
        useState("");
    const [Descriptions, setDescriptions] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [DeviceType, setDeviceType] = useState("All");
    const [categoryIconMonochrome, setCategoryIconMonochrome] = useState("");
    const [categoryIconColor, setCategoryIconColor] = useState("");
    const [sortID, setSortID] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState([{ Key: "", Value: "" }]);

    const { token } = AUTH_CONTEXT()

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            CategoryID: +categoryID,
            CategoryName: categoryName,
            CategoryImage: categoryImage,
            CategoryImageDetailedScreen: categoryImageDetailedScreen,
            Descriptions: Descriptions,
            CategoryIconMonochrome: categoryIconMonochrome,
            CategoryIconColor: categoryIconColor,
            Links: link,
            SortID: sortID,
            IsActive: isActive,
            DeviceType: DeviceType
        }

        const FetchResponse = async () => {
            try {
                let response = await CategoryServices.addCategory(token, navigate, Data)

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_CATEGORY_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const addFieldsLink = (event) => {
        event.preventDefault();
        let newfield = { Key: "", Value: "" };
        setLink([...link, newfield]);
    };

    const handleFormChangeLink = (index, event) => {
        let data = [...link];
        data[index][event.target.name] = event.target.value;
        setLink(data);
    };

    const removeFieldsLink = (index) => {
        let data = [...link];
        data.splice(index, 1);
        setLink(data);
    };

    const handleDescriptionChange = (e, locale) => {
        setDescriptions(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Update Category</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add New Category</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="CategoryID">CategoryID: </label>
                        <input
                            type="number"
                            id="CategoryID"
                            required
                            value={categoryID}
                            onChange={(e) => setCategoryID(parseInt(e.target.value))}
                            placeholder="Enter unique ID eg. 1, 2, etc."
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="CategoryName">CategoryName</label>
                        <input
                            type="text"
                            id="CategoryName"
                            required
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Enter Name for workout"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CategoryImage">Category Image : </label>
                        <input
                            required
                            id="CategoryImage"
                            type="url"
                            placeholder="Enter Category Image URL"
                            value={categoryImage}
                            onChange={(e) => setCategoryImage(e.target.value)}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CategoryImageDetailedScreen">
                            Category Image Detailed Screen :
                        </label>
                        <input
                            required
                            id="CategoryImageDetailedScreen"
                            type="url"
                            placeholder="Enter Category Image Detailed Screen URL"
                            value={categoryImageDetailedScreen}
                            onChange={(e) => setCategoryImageDetailedScreen(e.target.value)}
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="CategoryIconMonochrome">
                            Category Icon Monochrome :
                        </label>
                        <input
                            required
                            id="CategoryIconMonochrome"
                            type="url"
                            placeholder="Enter Category Icon Monochrome URL :"
                            value={categoryIconMonochrome}
                            onChange={(e) => setCategoryIconMonochrome(e.target.value)}
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="CategoryIconColor">Category Icon Color : </label>
                        <input
                            required
                            id="CategoryIconColor"
                            type="url"
                            placeholder="Enter Category Icon Color URL"
                            value={categoryIconColor}
                            onChange={(e) => setCategoryIconColor(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="SortID">SortID</label>
                        <input
                            type="number"
                            id="SortID"
                            required
                            value={sortID}
                            onChange={(e) => setSortID(parseInt(e.target.value))}
                            placeholder="Enter unique ID eg. 1, 2, etc."
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Links</h3>
                        {link.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="Key">Key</label>
                                        <input
                                            type="text"
                                            name="Key"
                                            placeholder="Enter Key"
                                            value={input.Key}
                                            onChange={(event) => handleFormChangeLink(index, event)}
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="Value">Value</label>
                                        <input
                                            type="text"
                                            name="Value"
                                            placeholder="Enter Value"
                                            value={input.Value}
                                            onChange={(event) => handleFormChangeLink(index, event)}
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsLink(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsLink}>Add More...</button>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={isActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} >
                        <label htmlFor="DeviceType">DeviceType : </label>
                        <select
                            name="DeviceType"
                            id="DeviceType"
                            value={DeviceType}
                            onChange={(e) => setDeviceType(e.target.value)}
                        >
                            <option value="All">All</option>
                            <option value="IOS">IOS</option>
                            <option value="Android">Android</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Descriptions</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Description (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Descriptions["en-US"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-US")}
                                    placeholder="Enter Description for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Description (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Descriptions["en-IN"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                    placeholder="Enter Description for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Description (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Descriptions["en-CA"]}
                                    onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                    placeholder="Enter Description for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default CreateCategoryId;

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Data.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../base_routes_paths/data_paths";
import AUTH_CONTEXT from "../../../store";
import Loader from "../../../components/Loader/Loader";
import Card from "../../../components/Card/Card";
import Button from "../../../components/Button/Button";
import { FeedbackServices } from "../../../services/auth_services";

const UserFeedbacks = () => {
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);
    const [FeedbackList, setFeedbackList] = useState([""]);
    const [ID, setID] = useState([""]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    const addValueField = () => {
        setFeedbackList(prevState => [...prevState, '']);
    };

    const removeValueField = (index) => {
        setFeedbackList(prevState => {
            const updatedFeedbackList = [...prevState];
            updatedFeedbackList.splice(index, 1);
            return updatedFeedbackList;
        });
    };

    const FetchResponse = async () => {
        try {
            let response = await FeedbackServices.getAllFeedbacks(token, navigate, params.ID);

            setIsLoaderLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setFeedbackList(result.FeedbackList);
                    setID(result._id)
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const FetchApi = async () => {
            try {
                const Data = {
                    "_id": ID,
                    "Data": FeedbackList
                }

                let response = await FeedbackServices.updateFeedback(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast.error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
                }
                navigate(-1)
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    return (
        !IsLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Auth
                    </Typography>
                    <Typography color="text.primary">Feedback View and Update List / {params.ID}</Typography>
                </Breadcrumbs>
                <form onSubmit={submitHandler}>
                    <Card className={classes.input}>
                        <div className={classes.Main_div}>Feedbacks List</div>
                        <div className={classes.control} style={{ marginBottom: "1rem" }}>
                            <div className={classes.subfields}>
                                <label htmlFor="FeedbackList">FeedbackList :</label>
                                {FeedbackList.map((feedback, index) => (
                                    <div className={classes.subfields} key={index}>
                                        <div>
                                            <label htmlFor={`Feedback_${index}`}>Feedback:</label>
                                            <input
                                                id={`Feedback_${index}`}
                                                value={feedback.Feedback}
                                                type='text'
                                                onChange={(event) => {
                                                    const updatedFeedbackList = [...FeedbackList];
                                                    updatedFeedbackList[index].Feedback = event.target.value;
                                                    setFeedbackList(updatedFeedbackList);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`Event_${index}`}>Event:</label>
                                            <input
                                                id={`Event_${index}`}
                                                value={feedback.Event}
                                                type='text'
                                                onChange={(event) => {
                                                    const updatedFeedbackList = [...FeedbackList];
                                                    updatedFeedbackList[index].Event = event.target.value;
                                                    setFeedbackList(updatedFeedbackList);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`RatingGiven_${index}`}>RatingGiven:</label>
                                            <input
                                                id={`RatingGiven_${index}`}
                                                value={feedback.RatingGiven}
                                                type='text'
                                                onChange={(event) => {
                                                    const updatedFeedbackList = [...FeedbackList];
                                                    updatedFeedbackList[index].RatingGiven = event.target.value;
                                                    setFeedbackList(updatedFeedbackList);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor={`FeedbackID_${index}`}>FeedbackID:</label>
                                            <input
                                                id={`FeedbackID_${index}`}
                                                value={feedback.FeedbackID}
                                                type='text'
                                                onChange={(event) => {
                                                    const updatedFeedbackList = [...FeedbackList];
                                                    updatedFeedbackList[index].FeedbackID = event.target.value;
                                                    setFeedbackList(updatedFeedbackList);
                                                }}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => removeValueField(index)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={addValueField}
                                    style={{
                                        marginTop: "10px",
                                    }}
                                >
                                    Add More Values...
                                </button>
                            </div>
                        </div>
                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p color="white">Sending request...</p>}
                    </Card>
                </form>
            </div>
    )
}

export default UserFeedbacks;

import classes from "./StartingPageContent.module.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../store";
import { CurrentTime, TimeStringCalculate } from "../../utils";
import Widget from "../../components/Widget/Widget";
import Chart from "../../components/Chart/Chart";
import NewRegisteredUserChart from "../../components/Chart/NewRegisteredUserChart";
import UserActivityChart from "../../components/Chart/UserActivityChart";
import ChallengesTable from "../../components/Table/Table";
import { BASE_PATH_TO_AUTH } from "../../base_routes_paths/auth_paths";
import UserGroupActivityChart from "../../components/Chart/GroupActivity";

const StartingPageContent = () => {
    const navigate = useNavigate()
    const { token, setToken, updateToken } = AUTH_CONTEXT();

    // useEffect(() => {
    //   const FetchResponseToVerifyAdmin = async () => {
    //     try {
    //       let resp = await fetch(`${API}/auth/VerifyAuthentication`, {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           authorization: `Bearer ${token}`,
    //         },
    //       });
    //       if (!resp.ok) {
    //         toast.error("Authentcation failed", {
    //           position: "bottom-left",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "light",
    //         });
    //         throw new Error("Authentcation failed");
    //       } else {
    //         resp = await resp.json();
    //       }
    //       if (resp.status === 200 && resp.data.token === token) {
    //         return;
    //       } else throw new Error("Authentcation failed");
    //     } catch (e) {
    //       toast.error("Authentication failed", {
    //         position: "bottom-left",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //       });
    //       authCtx.logout();
    //     }
    //   };

    //   if (!authCtx.apiCallCheck) {
    //     FetchResponseToVerifyAdmin();
    //     handleApiCallCheckClick();
    //   } else {
    //     const timeoutId = setTimeout(() => {
    //       authCtx.setApiCallCheck(false);
    //     }, 3600000);

    //     return () => {
    //       clearTimeout(timeoutId);
    //     };
    //   }
    // }, [authCtx, handleApiCallCheckClick, token]);

    useEffect(() => {
        if (localStorage.length !== 0) {
            console.log("Global Effect ran!!")
            if (localStorage.getItem("refreshToken") !== undefined && localStorage.getItem("refreshToken") !== null) {
                console.log("Global Effect Started!!")
                const timestamp1String = CurrentTime();
                const timestamp2String = localStorage.getItem("tokenExpiresIn");

                const timestamp1 = TimeStringCalculate(timestamp1String);
                const timestamp2 = TimeStringCalculate(timestamp2String);

                const timeDifference = timestamp1 - timestamp2;

                if (timeDifference > 1000) {
                    console.log("Global Effect Token Pinned!!")
                    if (token) {
                        updateToken()
                    } else {
                        setToken(null);
                        localStorage.removeItem("userName")
                        localStorage.removeItem("tokenExpiresIn")
                        localStorage.removeItem("profileImg")
                        localStorage.removeItem("token")
                        localStorage.removeItem("refreshToken")
                        localStorage.removeItem("TimeOffSet")
                        navigate(BASE_PATH_TO_AUTH)
                    }
                }
            } else {
                console.log("Global Effect Error!!")
                setToken(null);
                localStorage.removeItem("userName")
                localStorage.removeItem("tokenExpiresIn")
                localStorage.removeItem("profileImg")
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken")
                localStorage.removeItem("TimeOffSet")
                toast.error("Session expired, Please Login again!!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_AUTH)
            }
        }
    }, []);

    return (
        <div className={classes.homeContainer}>
            <div className={classes.widgetsContainer}>
                <div className={classes.widgets}>
                    <Widget type="user" />
                </div>
            </div>
            <div className={classes.chartsContainer}>
                <div className={classes.charts}>
                    <UserActivityChart title="User Activity" aspect={4 / 1} />
                </div>
                <div className={classes.charts}>
                    <Chart title="Active Users" aspect={4 / 1} />
                </div>

                <div className={classes.charts}>
                    <UserGroupActivityChart title="Activity Groups Stats" aspect={4 / 1} />
                </div>

                <div className={classes.charts}>
                    <NewRegisteredUserChart
                        title="New User (Last 15 days)"
                        aspect={4 / 1}
                    />
                </div>
            </div>
            <div className={classes.listContainer}>
                <div className={classes.listTitle}>Active Challenges</div>
                <ChallengesTable />
            </div>
        </div>
    );
};

export default StartingPageContent;

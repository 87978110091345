import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const AnnouncementsServices = {
    getAllAnnouncements: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/AdminGetAllAnnouncements`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addAnnouncement: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/AddAnnouncement`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(Data),
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateAnnouncement: async (token, navigate, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/sundries/UpdateAnnouncement`, {
                method: "POST",
                body: JSON.stringify(Data),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteAnnouncement: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this announcement?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(`${ARENO_API_ENDPOINT}/sundries/DeleteAnnouncement`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify({
                                    _id: id,
                                }),
                            });

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Announcement deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete announcement.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            // Handle network errors
                            toast.error(error.message || "An error occurred while deleting the announcement.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

const ReminderImagesServices = {
    getAllReminderImages: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/GetAllReminderImage`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addReminderImages: async (token, navigate, data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/AddReminderImage`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        Data: data
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateReminderImages: async (token, navigate, ID, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/UpdateReminderImageByID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                        Data: {
                            ImagesLinks: Data,
                        }
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const NotificationServices = {
    sendNotification: async (token, navigate, UserIds, messageTitle, messageBody) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/AdminSendGroupAppNotification`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserIds,
                        messageTitle,
                        messageBody
                    })
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    sendInactiveUsersNotification: async (token, navigate, DayCount, messageTitle, messageBody) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/sundries/AdminSendNotificationForInActiveUsers`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        DayCount,
                        messageTitle,
                        messageBody
                    })
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

export { ReminderImagesServices, AnnouncementsServices, NotificationServices }
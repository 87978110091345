import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const TimeOffSet = localStorage.getItem("TimeOffSet") ? localStorage.getItem("TimeOffSet") : 0;

const ThresholdServices = {
    addThreshold: async (token, navigate, threshold) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/setThreshold`, {
                method: "POST",
                body: JSON.stringify({
                    threshold: threshold,
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getThreshold: async (token, navigate) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/AdminGetThreshold`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
}

const UserPointServices = {
    addUserPoint: async (token, navigate, EnteredChallengeRef, EnteredMessageRef, EnteredUserIDRef, EnteredPointRef) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/AdminAddUserPoint`, {
                method: "POST",
                body: JSON.stringify({
                    Challenge: EnteredChallengeRef,
                    Message: EnteredMessageRef,
                    UserID: EnteredUserIDRef,
                    Point: +EnteredPointRef,
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
}

const PointServices = {
    addPointConfig: async (token, navigate, EnteredDefaultPoint, EnteredIsPromotional, EnteredIsDatePromotional, EnteredIsNoOfPPLPromotional, EnteredPromotionEndDate, EnteredPromotionStartDate, EnteredNoofPPL, EnteredPromotionalPoint, EnteredPriority) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/SetPoint`, {
                method: "POST",
                body: JSON.stringify({
                    DefaultPoint: EnteredDefaultPoint,
                    IsPromotional: EnteredIsPromotional,
                    IsDatePromotional: EnteredIsDatePromotional,
                    IsNoOfPPLPromotional: EnteredIsNoOfPPLPromotional,
                    PromotionEndDate: EnteredPromotionEndDate,
                    PromotionStartDate: EnteredPromotionStartDate,
                    NoofPPL: EnteredNoofPPL,
                    PromotionalPoint: EnteredPromotionalPoint,
                    Priority: EnteredPriority,
                }),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updatePointConfig: async (token, navigate, Data) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/UpdatePointConfigs`, {
                method: "POST",
                body: JSON.stringify(Data),
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getAllPointConfig: async (token, navigate) => {
        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/points/GetAllPointConfigs?TimeOffSet=${TimeOffSet}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
            });

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deletePointConfig: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(`${ARENO_API_ENDPOINT}/points/deletePointConfig`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: token,
                                },
                                body: JSON.stringify({
                                    ConfigID: id,
                                }),
                            });

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Config deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(error.message || "An error occurred while deleting.", {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
}

export { ThresholdServices, UserPointServices, PointServices };
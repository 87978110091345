import { useState } from "react";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST } from "../../../../base_routes_paths/challenge_paths";
import { SoloChallengeMilestoneServices } from "../../../../services/challenge_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const CreateSoloChallengeRewards = () => {
    const navigate = useNavigate()
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [level, setLevel] = useState("");
    const [rewards, setReward] = useState([{ CutOffNumber: "", Amount: "", IsActive: true }]);
    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Level: level,
            Rewards: rewards,
        }

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await SoloChallengeMilestoneServices.addSoloChallengeMilestone(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate(BASE_PATH_TO_SOLO_CHALLENGE_MILESTONES_LIST);
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    const handleFormChangeRewards = (index, event) => {
        let data = [...rewards];
        if (event.target.name === "IsActive") {
            data[index][event.target.name] = event.target.value === "true";
        } else {
            data[index][event.target.name] = parseInt(event.target.value);
        }
        setReward(data);
    };

    const addFieldsRewards = (event) => {
        event.preventDefault();
        let newfield = { CutOffNumber: "", Amount: "", IsActive: true };
        setReward([...rewards, newfield]);
    };

    const removeFieldsRewards = (index) => {
        let data = [...rewards];
        data.splice(index, 1);
        setReward(data);
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenege
                </Typography>
                <Typography color="text.primary">Create Solo Challenge Milestone</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create Solo Challenge Milestone</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.selectbox}>
                        <label htmlFor="Level">Level :</label>
                        <input
                            type="number"
                            id="Level"
                            required
                            value={level}
                            onChange={(e) => setLevel(parseInt(e.target.value))}
                            placeholder="Enter level"
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Rewards</h3>
                        {rewards.map((input, index) => {
                            return (
                                <>
                                    <div key={index} className={classes.subfieldsControl}>
                                        <div className={classes.control}>
                                            <label htmlFor="CutOffNumber">Milestone Number</label>
                                            <input
                                                type="text"
                                                name="CutOffNumber"
                                                placeholder="Enter Milestone Number"
                                                value={input.CutOffNumber}
                                                onChange={(event) => handleFormChangeRewards(index, event)}
                                            />
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="Amount">Amount Percentage</label>
                                            <input
                                                type="text"
                                                name="Amount"
                                                placeholder="Enter Amount Percentage"
                                                value={input.Amount}
                                                onChange={(event) => handleFormChangeRewards(index, event)}
                                            />
                                        </div>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="IsActive">IsActive : </label>
                                            <select
                                                name="IsActive"
                                                id="IsActive"
                                                value={input.IsActive}
                                                onChange={(event) => handleFormChangeRewards(index, event)}
                                            >
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>

                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsRewards(index);
                                        }}
                                    >
                                        Remove
                                    </button>
                                </>
                            );
                        })}
                        <button onClick={addFieldsRewards}>Add More...</button>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default CreateSoloChallengeRewards;

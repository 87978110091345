import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const TimeOffSet = localStorage.getItem("TimeOffSet") ? localStorage.getItem("TimeOffSet") : 0;

const ChallenegeServices = {
    getAllChallenges: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetAllChallengesDetailsForDashBoard?TimeOffSet=${TimeOffSet}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getChallengeDetails: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetDetailedChallengeByID?TimeOffSet=${TimeOffSet}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addChallenges: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AddChallenge`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateChallenges: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/UpdateChallenge`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getDetailsChallengesByID: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetDetailedChallenge?TimeOffSet=${TimeOffSet}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        _id: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteChallenge: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteChallenge`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Challenge deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const CorporateServices = {
    getAllCorporate: async (token, navigate) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetAllCorporateID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token} `,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    getCorporateByID: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetCorporateDetailsByCorporateID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        CorporateID: ID,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addCorporate: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AddCorporateID`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateCorporate: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/UpdateCorporateID`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteCorporate: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteCorporateID`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Corporate ID deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const WorkoutServices = {
    getAllWorkout: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllWorkout`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addWorkout: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AddWorkoutID`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteWorkout: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteWorkoutID`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: token,
                                    },
                                    body: JSON.stringify({
                                        WorkoutID: +id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Corporate ID deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const CategoryServices = {
    getAllCategory: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllChallengeCategory`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addCategory: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AddChallengeCategory`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateCategory: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AddChallengeCategory`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteCategory: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteChallengeCategory`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Category ID deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const SoloChallengeMilestoneServices = {
    getAllSoloChallengeMilestone: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetAllSoloChallengeRewards`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addSoloChallengeMilestone: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/CreateSoloChallengeRewardConfig`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateSoloChallengeMilestone: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/UpdateSoloChallengeRewardsData`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(Data),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteSoloChallengeMilestone: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteSoloChallengeRewards`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Category ID deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const SoloChallengeServices = {
    getAllSoloChallenge: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllSoloChallenges`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addSoloChallenge: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/CreateSoloChallenge`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(Data),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    updateSoloChallenge: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/UpdateSoloChallenge`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(Data),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteSoloChallenge: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/DeleteSoloChallengeConfig`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        ChallengeID: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Category ID deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const LeaderboardService = {
    getLeaderboardData: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetUserListAttemptedChallanges`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ ChallengeID: ID }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const ActivityService = {
    getActivityData: async (
        token,
        navigate,
        page,
        pageSize,
        sort,
        field,
        filterModel
    ) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetUserActivity?page=${page}&limit=${pageSize}&sort=${sort}&field=${field}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        filters: filterModel,
                    }),
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
};

const MeditationServices = {
    getAllMeditations: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllMeditationData`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addMeditation: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminAddMeditationData`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteMeditation: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/AdminDeleteMeditationData`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Meditation deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const CCRServices = {
    getAllCCR: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllCountryConversionData`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addCCR: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminAddCountryConversionRate`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteCCR: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/AdminDeleteCountryConversionData`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Country Conversion Rate deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const CommunityGoalServices = {
    getAllCommunityGoals: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllCommunityGoal?TimeOffSet=${TimeOffSet}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addCommunityGoals: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminAddCommunityGoal`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteCommunityGoals: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/AdminDeleteCommunityGoal`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Community Goal deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const RoutineActivitiesServices = {
    getAllRoutineActivity: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllRoutineActivity`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addRoutineActivity: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminAddRoutineActivity`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteRoutineActivity: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/AdminDeleteRoutineActivity`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Routine Activity deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

const RoutinePlansServices = {
    getAllRoutinePlans: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetAllRoutinePlan`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addRoutinePlan: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminAddRoutinePlan`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteRoutinePlan: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/challenges/AdminDeleteRoutinePlan`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Routine Plan deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};

export {
    ChallenegeServices,
    CorporateServices,
    WorkoutServices,
    CategoryServices,
    SoloChallengeMilestoneServices,
    SoloChallengeServices,
    LeaderboardService,
    ActivityService,
    MeditationServices,
    CCRServices,
    CommunityGoalServices,
    RoutineActivitiesServices,
    RoutinePlansServices
};

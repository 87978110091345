import { BASE_PATH_TO_ADD_DEVICE_URL, BASE_PATH_TO_ADD_MENU_URL, BASE_PATH_TO_DEVICE_URL_LIST, BASE_PATH_TO_MENU_URL_LIST, BASE_PATH_TO_UPDATE_MENU_URL } from "../base_routes_paths/dashboard_paths";
import DashboardPageLayout from "../layouts/dashboard_layout";
import NewDeviceUrlsConfig from "../pages/dashboard/device/add_device_url";
import CurrentDeviceUrlsList from "../pages/dashboard/device/device_url_list";
import CreateMenuUrlConfig from "../pages/dashboard/menu/add_menu_url";
import MenuUrlListConfig from "../pages/dashboard/menu/menu_url_list";
import UpdateMenuUrlConfig from "../pages/dashboard/menu/update_menu_url";
import { ProtectedRoutes } from "./protected_route";

const DashboardRoutes = {
    path: "/dashboard",
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_DEVICE_URL_LIST,
            element: <CurrentDeviceUrlsList />,
        },
        {
            path: BASE_PATH_TO_ADD_DEVICE_URL,
            element: <NewDeviceUrlsConfig />,
        },
        {
            path: BASE_PATH_TO_MENU_URL_LIST,
            element: <MenuUrlListConfig />,
        },
        {
            path: BASE_PATH_TO_ADD_MENU_URL,
            element: <CreateMenuUrlConfig />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_MENU_URL}/:ID`,
            element: <UpdateMenuUrlConfig />,
        },
    ],
};

export default DashboardRoutes;

import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { toast } from "react-toastify";
import IndianTimeFormat from "../../../../utils";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { CorporateServices } from "../../../../services/challenge_services";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_CORPORATE, BASE_PATH_TO_UPDATE_CORPORATE } from "../../../../base_routes_paths/challenge_paths";

const CorporateTable = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_CorporateIDsList`;
    const userColumns = [
        { field: "id", headerName: "ID", width: 230 },
        {
            field: "CorporateID",
            headerName: "Corporate ID",
            width: 120,
        },
        {
            field: "CorporateName",
            headerName: "Corporate name",
            width: 150,
        },
        {
            field: "CorporateImage",
            headerName: "CorporateImage",
            width: 200,
            renderCell: (params) => {
                const link = params.row.CorporateImage;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        {
            field: "CorporateImageDetailedScreen",
            headerName: "CorporateImageDetailedScreen",
            width: 300,
            renderCell: (params) => {
                const link = params.row.CorporateImageDetailedScreen;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        {
            field: "SubscriptionIDForUser",
            headerName: "SubscriptionIDForUser",
            width: 220,
        },
        {
            field: "EmailDomain",
            headerName: "Email domain",
            width: 150,
        },
        {
            field: "Dated",
            headerName: "Created on",
            width: 115,
        },
        {
            field: "SubscriptionStartsOn",
            headerName: "SubscriptionStartsOn",
            width: 150,
        },
        {
            field: "SubscriptionEndsOn",
            headerName: "SubscriptionEndsOn",
            width: 150,
        },
        {
            field: "IsEmailRequired",
            headerName: "Email required",
            width: 115,
        },
        {
            field: "IsSubscribed",
            headerName: "IsSubscribed",
            width: 100,
        },
        {
            field: "IsSchool",
            headerName: "IsSchool",
            width: 100,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 80,
        },
        {
            field: "IsConsolidatedLeaderBoardRequired",
            headerName: "IsConsolidatedLeaderBoardRequired",
            width: 250,
        },
        {
            field: "IsCaloriesBasedLeaderboard",
            headerName: "IsCaloriesBasedLeaderboard",
            width: 220,
        },
        {
            field: "IsReferralPointCount",
            headerName: "IsReferralPointCount",
            width: 150,
        },
        {
            field: "IsRegistrationPointCount",
            headerName: "IsRegistrationPointCount",
            width: 200,
        },
        {
            field: "IsDailyRewardPointCount",
            headerName: "IsDailyRewardPointCount",
            width: 200,
        },
    ];

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let CorporateData = [];
    const [data, setData] = useState(CorporateData);

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await CorporateServices.getAllCorporate(token, navigate);

            setIsLoading(false);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponseGetAllUserDetails()
                .then((result) => {
                    for (var i = 0; i < result.length; i++) {
                        result[i].Dated = IndianTimeFormat(result[i].Dated);
                        if (result[i].IsEmailRequired) {
                            result[i].IsEmailRequired = `${result[i].IsEmailRequired} `;
                        } else {
                            result[i].IsEmailRequired = "false";
                        }
                    }
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        CorporateServices.deleteCorporate(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer"
                            href={`${BASE_PATH_TO_UPDATE_CORPORATE}/` + params.row.CorporateID}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </a>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenges
                </Typography>
                <Typography color="text.primary">Corporate ID List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_CORPORATE);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default CorporateTable;

import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "../header";

const AuthPageLayout = () => {
    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
            >
                <Sidebar />
                <Outlet />
            </Box>
        </>
    );
};

export default AuthPageLayout;

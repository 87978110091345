// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__-SwHU {
  background: rgb(226, 226, 226);
  box-shadow: 2px 4px 10px 1px rgba(161, 160, 160, 0.47);
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAE9B,sDAAsD;EACtD,mBAAmB;AACrB","sourcesContent":[".card {\n  background: rgb(226, 226, 226);\n  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n  box-shadow: 2px 4px 10px 1px rgba(161, 160, 160, 0.47);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__-SwHU`
};
export default ___CSS_LOADER_EXPORT___;

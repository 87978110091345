import { toast } from "react-toastify";
import ARENO_API_ENDPOINT from "../../api";
import { StatusCode } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const GameLevelServices = {
    getAllGameLevels: async (token, navigate, ID) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/game/AdminGetAllGameLevel`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        ChallengeID: ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    addGameLevel: async (token, navigate, Data) => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/game/AdminAddGameLevelData`,
                {
                    method: "POST",
                    body: JSON.stringify(Data),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            StatusCode(response, navigate);
            response = await response.json();
            return response;
        } catch (error) {
            throw new Error(error.message);
        }
    },
    deleteGameLavel: async (id, token) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            let response = await fetch(
                                `${ARENO_API_ENDPOINT}/game/AdminDeleteGameLevel`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bearer ${token}`,
                                    },
                                    body: JSON.stringify({
                                        _id: id,
                                    }),
                                }
                            );

                            StatusCode(response);

                            const data = await response.json();

                            if (response.status === 200) {
                                toast.success("Game Level deleted successfully!", {
                                    position: "bottom-left",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                                window.location.reload();
                            } else {
                                toast.error(data.error || "Failed to delete.", {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                        } catch (error) {
                            toast.error(
                                error.message || "An error occurred while deleting.",
                                {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                }
                            );
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    },
};


export {
    GameLevelServices,
};

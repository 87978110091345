import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { CommunityGoalServices } from "../../../../services/challenge_services";
import { BASE_PATH_TO_COMMUNITY_GOALS_LIST } from "../../../../base_routes_paths/challenge_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AddCommunityGoal = () => {
    const navigate = useNavigate();
    const [Title, setTitle] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [Goal, setGoal] = useState("");
    const [Reward, setReward] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [Unit, setUnit] = useState("");
    const [StartTimeUtc, setStartTimeUtc] = useState("");
    const [EndTimeUtc, setEndTimeUtc] = useState("");
    const [DescriptionImageUrl, setDescriptionImageUrl] = useState("");
    const [IsActive, setIsActive] = useState(true);
    const [IsRoutineActivityAllowed, setIsRoutineActivityAllowed] = useState(true);
    const [IsMeditationAllowed, setIsMeditationAllowed] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [WorkoutAllowed, setWorkoutAllowed] = useState([]);
    const [ChallengeCategoryAllowed, setChallengeCategoryAllowed] = useState([]);

    const { token } = AUTH_CONTEXT();

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Title: Title,
            Goal: Goal,
            Reward: Reward,
            Unit: Unit,
            StartTimeUtc: StartTimeUtc,
            EndTimeUtc: EndTimeUtc,
            DescriptionImageUrl: DescriptionImageUrl,
            IsActive: IsActive,
            ChallengeCategoryAllowed: ChallengeCategoryAllowed,
            WorkoutAllowed: WorkoutAllowed,
            IsRoutineActivityAllowed: IsRoutineActivityAllowed,
            IsMeditationAllowed: IsMeditationAllowed
        }

        const FetchResponse = async () => {
            try {
                let response = await CommunityGoalServices.addCommunityGoals(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast.error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }
                navigate(BASE_PATH_TO_COMMUNITY_GOALS_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const addWorkoutAllowed = () => {
        setWorkoutAllowed([...WorkoutAllowed, ""]);
    };

    const removeWorkoutAllowed = (index) => {
        const newWorkoutAllowed = [...WorkoutAllowed];
        newWorkoutAllowed.splice(index, 1);
        setWorkoutAllowed(newWorkoutAllowed);
    };

    const addChallengeCategoryAllowed = () => {
        setChallengeCategoryAllowed([...ChallengeCategoryAllowed, ""]);
    };

    const removeChallengeCategoryAllowed = (index) => {
        const newChallengeCategoryAllowed = [...ChallengeCategoryAllowed];
        newChallengeCategoryAllowed.splice(index, 1);
        setChallengeCategoryAllowed(newChallengeCategoryAllowed);
    };

    const handleTitleChange = (e, locale) => {
        setTitle(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    const handleRewardChange = (e, locale) => {
        setReward(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">Challenge</Typography>
                <Typography color="text.primary">Add Community Goal</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add New Community Goal</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.subfields}>
                        <h3>Title</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Title (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Title["en-US"]}
                                    onChange={(e) => handleTitleChange(e, "en-US")}
                                    placeholder="Enter Title for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Title (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Title["en-IN"]}
                                    onChange={(e) => handleTitleChange(e, "en-IN")}
                                    placeholder="Enter Title for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Title (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Title["en-CA"]}
                                    onChange={(e) => handleTitleChange(e, "en-CA")}
                                    placeholder="Enter Title for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Goal">Goal : </label>
                        <input
                            required
                            id="Goal"
                            type="number"
                            placeholder="Enter Goal"
                            value={Goal}
                            onChange={(e) => setGoal(e.target.value)}
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Reward</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Reward (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Reward["en-US"]}
                                    onChange={(e) => handleRewardChange(e, "en-US")}
                                    placeholder="Enter Reward for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Reward (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Reward["en-IN"]}
                                    onChange={(e) => handleRewardChange(e, "en-IN")}
                                    placeholder="Enter Reward for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Reward (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Reward["en-CA"]}
                                    onChange={(e) => handleRewardChange(e, "en-CA")}
                                    placeholder="Enter Reward for en-CA"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="DescriptionImageUrl">Description Image Url :</label>
                        <input
                            type="url"
                            id="DescriptionImageUrl"
                            value={DescriptionImageUrl}
                            onChange={(e) => setDescriptionImageUrl(e.target.value)}
                            placeholder="Enter Description Image Url"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="Unit">Unit :</label>
                        <input
                            type="url"
                            id="Unit"
                            value={Unit}
                            onChange={(e) => setUnit(e.target.value)}
                            placeholder="Enter Unit"
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="StartTimeUtc">StartTimeUtc :</label>
                        <input
                            type="datetime-local"
                            id="StartTimeUtc"
                            required
                            value={StartTimeUtc}
                            onChange={(e) => setStartTimeUtc(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="EndTimeUtc">EndTimeUtc :</label>
                        <input
                            type="datetime-local"
                            id="EndTimeUtc"
                            required
                            value={EndTimeUtc}
                            onChange={(e) => setEndTimeUtc(e.target.value)}
                        />
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={IsActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsMeditationAllowed">IsMeditationAllowed : </label>
                        <select
                            name="IsMeditationAllowed"
                            id="IsMeditationAllowed"
                            value={IsMeditationAllowed}
                            onChange={(e) => setIsMeditationAllowed(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsRoutineActivityAllowed">IsRoutineActivityAllowed : </label>
                        <select
                            name="IsRoutineActivityAllowed"
                            id="IsRoutineActivityAllowed"
                            value={IsRoutineActivityAllowed}
                            onChange={(e) => setIsRoutineActivityAllowed(e.target.value)}
                        >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                        </select>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Workout Allowed</h3>
                        {WorkoutAllowed.map((workout, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={workout}
                                    onChange={(e) => {
                                        const newWorkoutAllowed = [...WorkoutAllowed];
                                        newWorkoutAllowed[index] = e.target.value;
                                        setWorkoutAllowed(newWorkoutAllowed);
                                    }}
                                />
                                <Button onClick={() => removeWorkoutAllowed(index)}>Remove</Button>
                            </div>
                        ))}
                        <Button onClick={addWorkoutAllowed}>Add Workout Allowed</Button>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Challenge Category Allowed</h3>
                        {ChallengeCategoryAllowed.map((category, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={category}
                                    onChange={(e) => {
                                        const newChallengeCategoryAllowed = [...ChallengeCategoryAllowed];
                                        newChallengeCategoryAllowed[index] = e.target.value;
                                        setChallengeCategoryAllowed(newChallengeCategoryAllowed);
                                    }}
                                />
                                <Button onClick={() => removeChallengeCategoryAllowed(index)}>Remove</Button>
                            </div>
                        ))}
                        <Button onClick={addChallengeCategoryAllowed}>Add Challenge Category Allowed</Button>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddCommunityGoal;

import { useState } from "react";
import classes from "../../Sundries.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_REMINDER_IMAGES_LIST } from "../../../../base_routes_paths/sundries_paths";
import { ReminderImagesServices } from "../../../../services/sundries_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const AddReminderImage = () => {
    const navigate = useNavigate()
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [links, setLinks] = useState([{ Link: "" }]);

    const SubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const response = await ReminderImagesServices.addReminderImages(token, navigate, links);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIsLoading(false);

            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            navigate(BASE_PATH_TO_REMINDER_IMAGES_LIST);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleFormChangeImageReminders = (index, event) => {
        let data = [...links];
        data[index][event.target.name] = event.target.value;
        setLinks(data);
    };

    const addFieldsImageReminders = (event) => {
        event.preventDefault();
        let newfield = { Link: "" };
        setLinks([...links, newfield]);
    };

    const removeFieldsImageReminders = (index) => {
        let data = [...links];
        data.splice(index, 1);
        setLinks(data);
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Sundries
                </Typography>
                <Typography color="text.primary">Add ReminderImages</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>

                <div className={classes.Main_div}>Add Reminder Images</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.subfields}>
                        <h3>Images Links</h3>
                        {links.map((input, index) => {
                            return (
                                <div key={index} className={classes.subfieldsControl}>
                                    <div className={classes.control}>
                                        <label htmlFor="Link">Link</label>
                                        <input
                                            type="url"
                                            name="Link"
                                            placeholder="Enter Link"
                                            value={input.Link}
                                            onChange={(event) => handleFormChangeImageReminders(index, event)}
                                        />
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeFieldsImageReminders(index);
                                        }}
                                    >
                                        Remove Link
                                    </button>
                                </div>
                            );
                        })}
                        <button onClick={addFieldsImageReminders}>Add More Links...</button>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddReminderImage;

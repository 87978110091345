import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { SubscriptionsServices } from "../../../../services/redeem_services";
import { BASE_PATH_TO_SUBSCRIPTIONS_LIST } from "../../../../base_routes_paths/redeem_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const SubscriptionCreationForm = () => {
    const navigate = useNavigate();
    const [Name, setName] = useState("");
    const [Price, setPrice] = useState("");
    const [Duration, setDuration] = useState("");
    const [AndroidproductId, setAndroidproductId] = useState("");
    const [IOSproductId, setIOSproductId] = useState("");
    const [SoloChallengeLevel, setSoloChallengeLevel] = useState({
        IsSoloChallenge: true,
        IsSoloChallengeAllLevel: true,
        Levels: [
            {
                Level: 1,
            },
        ],
    });
    const [DailyPoints, setDailyPoints] = useState({
        IsDailyPoints: true,
        MaxReward: "",
        MinReward: "",
        RewardType: ""
    });
    const [IsActive, setIsActive] = useState(true);
    const [IsAdsFree, setIsAdsFree] = useState(true);
    const [IsReminders, setIsReminders] = useState(true);
    const [ReferralConfig, setReferralConfig] = useState({
        PointForReferralUser: "",
        PointForReferredUser: "",
        ReferralLimit: ""
    });
    const [freeSuperpowers, setFreeSuperpowers] = useState({
        IsFreeSuperpower: true,
        Amount: "",
    });
    const [UserGoalConfig, setUserGoalConfig] = useState({
        IsGoalProgressViewAllowed: true,
    });
    const [ClanConfig, setClanConfig] = useState({
        IsClanCreationAllowed: false,
        ClanCreationPoints: 0,
    });
    const [RoutinePlansConfig, setRoutinePlansConfig] = useState({
        IsRoutinePlansAllowed: false,
    });
    const [UserStepsConfig, setUserStepsConfig] = useState({
        IsDailyPoints: true,
        Target: 5000,
    });
    const [ChallengeCategoryAllowed, setChallengeCategoryAllowed] = useState([
        {
            CategoryID: "",
            IsUnlimitedAttemptsAllowed: false,
            AttemptsAllowed: ""
        }
    ]);
    const [Redemptions, setRedemptions] = useState({
        IsRedemptionLimit: false,
        Amount: ""
    });
    const [isLoading, setIsLoading] = useState(false);

    const { token } = AUTH_CONTEXT()

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let response = await SubscriptionsServices.addSubscription(token, navigate, Name, Price, Duration, IsActive, AndroidproductId, IOSproductId, SoloChallengeLevel, DailyPoints, IsAdsFree, IsReminders, Redemptions, ReferralConfig, freeSuperpowers, ChallengeCategoryAllowed, UserGoalConfig, UserStepsConfig, ClanConfig, RoutinePlansConfig)

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_SUBSCRIPTIONS_LIST)
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleFormChangeDailyPoints = (name, event) => {
        const value = event.target.value === "true";
        setDailyPoints((prevDailyPoints) => ({
            ...prevDailyPoints,
            [name]: value,
        }));
        if (event.target.value === "false") {
            setDailyPoints((prevRedemptionLimit) => ({
                ...prevRedemptionLimit,
                MaxReward: "",
                MinReward: "",
            }));
        }
    };

    const handleFormChangeUserGoalConfig = (name, event) => {
        const value = event.target.value === "true";
        setUserGoalConfig((prevUserGoals) => ({
            ...prevUserGoals,
            [name]: value,
        }));
    };

    const handleFormChangePoints = (name, event) => {
        const value = event.target.value;
        setDailyPoints((prevDailyPoints) => ({
            ...prevDailyPoints,
            [name]: parseInt(value),
        }));
    };

    const addChallengeCategoryAllowed = (event) => {
        event.preventDefault();
        let newField = {
            CategoryID: ""
        };
        setChallengeCategoryAllowed((prevChallengeCategoryAllowed) => [
            ...prevChallengeCategoryAllowed,
            newField,
        ]);
    };


    const removeChallengeCategoryAllowed = (index) => {
        setChallengeCategoryAllowed((prevSubscription) => {
            const data = [...prevSubscription];
            data.splice(index, 1);
            return data;
        });
    };

    const handleFormChallengeCategoryAllowed = (index, event, property) => {
        const { value } = event.target;

        setChallengeCategoryAllowed((prevChallengeCategoryAllowed) => {
            const updatedFeatures = [...prevChallengeCategoryAllowed];
            updatedFeatures[index] = {
                ...updatedFeatures[index],
                [property]: property === 'IsUnlimitedAttemptsAllowed' ? value === 'true' : parseInt(value)
            };
            return updatedFeatures;
        });
    };

    const handleFormChangeSoloChallengeLevel = (name, event) => {
        let value = event.target.value;
        if (name === "IsSoloChallenge" || name === "IsSoloChallengeAllLevel") {
            value = value === "true"; // Convert the string to a boolean
        }

        setSoloChallengeLevel((prevSoloChallengeLevel) => ({
            ...prevSoloChallengeLevel,
            [name]: value,
        }));
    };

    const handleFormChangeLevels = (index, event) => {
        setSoloChallengeLevel((prevSoloChallengeLevel) => {
            const data = { ...prevSoloChallengeLevel };
            data.Levels = [...data.Levels];
            data.Levels[index] = {
                ...data.Levels[index],
                Level: parseInt(event.target.value),
            };
            return { ...data };
        });
    };

    const addFieldsLevels = (event) => {
        event.preventDefault();
        let newField = { Level: "" };
        setSoloChallengeLevel((prevSoloChallengeLevel) => ({
            ...prevSoloChallengeLevel,
            Levels: [...prevSoloChallengeLevel.Levels, newField],
        }));
    };

    const removeFieldsLevels = (index) => {
        let data = [...SoloChallengeLevel.Levels];
        data.splice(index, 1);

        setSoloChallengeLevel((prevSoloChallengeLevel) => ({
            ...prevSoloChallengeLevel,
            Levels: data,
        }));
    };

    const handleFormChangeFreeSuperPowers = (event) => {
        const { name, value } = event.target;
        setFreeSuperpowers((prevFreeSuperpowers) => ({
            ...prevFreeSuperpowers,
            [name]: value === "true",
        }));
    };

    const handleFormChangeUserStepsConfig = (event) => {
        const { name, value } = event.target;
        setUserStepsConfig((prevFreeSuperpowers) => ({
            ...prevFreeSuperpowers,
            [name]: value === "true",
        }));
    };

    const handleFormChangeRedemptionLimit = (event) => {
        const { name, value } = event.target;
        setRedemptions((prevRedemptionLimit) => ({
            ...prevRedemptionLimit,
            [name]: value === "true",
        }));
        if (value === "false") {
            setRedemptions((prevRedemptionLimit) => ({
                ...prevRedemptionLimit,
                Amount: "",
            }));
        }
    };

    const handleFormChangeAmount = (name, event) => {
        const value = event.target.value;
        setFreeSuperpowers((prevFreeSuperpowers) => ({
            ...prevFreeSuperpowers,
            [name]: parseInt(value),
        }));
    };

    const handleFormChangeTarget = (name, event) => {
        const value = event.target.value;
        setUserStepsConfig((prevFreeSuperpowers) => ({
            ...prevFreeSuperpowers,
            [name]: parseInt(value),
        }));
    };

    const handleFormChangeRedemptionLimitAmount = (name, event) => {
        const value = event.target.value;
        setRedemptions((prevFreeSuperpowers) => ({
            ...prevFreeSuperpowers,
            [name]: parseInt(value),
        }));
    };

    const handleInputChange = (e) => {
        setReferralConfig({
            ...ReferralConfig,
            [e.target.name]: parseInt(e.target.value)
        });
    };

    const handleClanConfigChange = (event) => {
        const { name, value, type, checked } = event.target;
        setClanConfig((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleRoutinePlansConfigChange = (event) => {
        const { name, value, type, checked } = event.target;
        setRoutinePlansConfig((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Add New Subscription</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Create New Subscription</div>
                <form onSubmit={submitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="Name">Name :</label>
                        <input
                            type="text"
                            id="Name"
                            required
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter name"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Price">Price :</label>
                        <input
                            type="number"
                            id="Price"
                            required
                            value={Price}
                            onChange={(e) => setPrice(parseInt(e.target.value))}
                            placeholder="Enter Price"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="Duration">Duration :</label>
                        <input
                            type="number"
                            id="Duration"
                            value={Duration}
                            onChange={(e) => setDuration(parseInt(e.target.value))}
                            placeholder="Enter Duration"
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Redemptions</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsRedemptionLimit"> IsRedemptionLimit : </label>
                                <select
                                    name="IsRedemptionLimit"
                                    id="IsRedemptionLimit"
                                    value={Redemptions.IsRedemptionLimit}
                                    onChange={handleFormChangeRedemptionLimit}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            {Redemptions.IsRedemptionLimit && (
                                <div className={classes.control}>
                                    <label htmlFor="Amount">Amount : </label>
                                    <input
                                        type="number"
                                        name="Amount"
                                        required
                                        placeholder="Enter Amount"
                                        value={Redemptions.Amount}
                                        onChange={(e) => handleFormChangeRedemptionLimitAmount("Amount", e)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Clan Config</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsClanCreationAllowed">IsClanCreationAllowed: </label>
                                <select
                                    name="IsClanCreationAllowed"
                                    id="IsClanCreationAllowed"
                                    value={ClanConfig.IsClanCreationAllowed}
                                    onChange={handleClanConfigChange}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="ClanCreationPoints">Clan Creation Points: </label>
                                <input
                                    type="number"
                                    name="ClanCreationPoints"
                                    required
                                    placeholder="Enter Clan Creation Points"
                                    value={ClanConfig.ClanCreationPoints}
                                    onChange={handleClanConfigChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Routine Plans Config</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsRoutinePlansAllowed">IsRoutinePlansAllowed: </label>
                                <select
                                    name="IsRoutinePlansAllowed"
                                    id="IsRoutinePlansAllowed"
                                    value={RoutinePlansConfig.IsRoutinePlansAllowed}
                                    onChange={handleRoutinePlansConfigChange}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="AndroidproductId">AndroidproductId :</label>
                        <input
                            type="text"
                            id="AndroidproductId"
                            required
                            value={AndroidproductId}
                            onChange={(e) => setAndroidproductId(e.target.value)}
                            placeholder="Enter AndroidproductId"
                        />
                    </div>

                    <div className={classes.control}>
                        <label htmlFor="IOSproductId">IOSproductId :</label>
                        <input
                            type="text"
                            id="IOSproductId"
                            required
                            value={IOSproductId}
                            onChange={(e) => setIOSproductId(e.target.value)}
                            placeholder="Enter IOSproductId"
                        />
                    </div>

                    <div className={classes.subfields}>
                        <h3>Free Superpowers</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsFreeSuperpower"> IsFreeSuperpower : </label>
                                <select
                                    name="IsFreeSuperpower"
                                    id="IsFreeSuperpower"
                                    value={freeSuperpowers.IsFreeSuperpower.toString()}
                                    onChange={handleFormChangeFreeSuperPowers}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            {freeSuperpowers.IsFreeSuperpower === true && (
                                <div className={classes.control}>
                                    <label htmlFor="Amount">Amount : </label>
                                    <input
                                        type="number"
                                        name="Amount"
                                        required={freeSuperpowers.IsFreeSuperpower === true}
                                        placeholder="Enter Amount"
                                        value={freeSuperpowers.Amount}
                                        onChange={(e) => handleFormChangeAmount("Amount", e)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>UserStepsConfig</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsDailyPoints"> IsDailyPoints : </label>
                                <select
                                    name="IsDailyPoints"
                                    id="IsDailyPoints"
                                    value={UserStepsConfig.IsDailyPoints}
                                    onChange={handleFormChangeUserStepsConfig}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            {UserStepsConfig.IsDailyPoints === true && (
                                <div className={classes.control}>
                                    <label htmlFor="Target">Target : </label>
                                    <input
                                        type="number"
                                        name="Target"
                                        required={UserStepsConfig.IsDailyPoints === true}
                                        placeholder="Enter Target"
                                        value={UserStepsConfig.Target}
                                        onChange={(e) => handleFormChangeTarget("Target", e)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>UserGoalConfig</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsGoalProgressViewAllowed"> IsGoalProgressViewAllowed : </label>
                                <select
                                    name="IsGoalProgressViewAllowed"
                                    id="IsGoalProgressViewAllowed"
                                    value={UserGoalConfig.IsGoalProgressViewAllowed}
                                    onChange={(e) => {
                                        handleFormChangeUserGoalConfig("IsGoalProgressViewAllowed", e);
                                    }}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Daily Points</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsDailyPoints"> IsDailyPoints : </label>
                                <select
                                    name="IsDailyPoints"
                                    id="IsDailyPoints"
                                    value={DailyPoints.IsDailyPoints.toString()}
                                    onChange={(e) => {
                                        handleFormChangeDailyPoints("IsDailyPoints", e);
                                    }}
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>

                            {DailyPoints.IsDailyPoints && (
                                <>
                                    <div className={classes.control}>
                                        <label htmlFor="MaxReward">Max Reward : </label>
                                        <input
                                            type="number"
                                            name="MaxReward"
                                            required
                                            placeholder="Enter Max Reward"
                                            value={DailyPoints.MaxReward}
                                            onChange={(e) => handleFormChangePoints("MaxReward", e)}
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="MinReward">Min Reward : </label>
                                        <input
                                            type="number"
                                            name="MinReward"
                                            required
                                            placeholder="Enter Min Reward"
                                            value={DailyPoints.MinReward}
                                            onChange={(e) => handleFormChangePoints("MinReward", e)}
                                        />
                                    </div>
                                    <div className={classes.selectbox}>
                                        <label htmlFor="RewardType">RewardType : </label>
                                        <select
                                            name="RewardType"
                                            value={DailyPoints.RewardType}
                                            onChange={(e) => handleFormChangePoints("RewardType", e)}
                                        >
                                            <option value={1}>1.Constant</option>
                                            <option value={2}>2.Incremental</option>
                                        </select>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>Referral Config</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.control}>
                                <label htmlFor="PointForReferralUser">Point For Referral User : </label>
                                <input
                                    required
                                    name="PointForReferralUser"
                                    id="PointForReferralUser"
                                    placeholder="Enter Point For Referral User"
                                    value={ReferralConfig.PointForReferralUser}
                                    onChange={handleInputChange}
                                    type="number"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="PointForReferredUser">Point For Referred User : </label>
                                <input
                                    required
                                    id="PointForReferredUser"
                                    placeholder="Enter Point For Referred User"
                                    name="PointForReferredUser"
                                    value={ReferralConfig.PointForReferredUser}
                                    onChange={handleInputChange}
                                    type="number"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="ReferralLimit">Referral Limit : </label>
                                <input
                                    required
                                    name="ReferralLimit"
                                    id="ReferralLimit"
                                    placeholder="Enter Point For Referral Limit"
                                    value={ReferralConfig.ReferralLimit}
                                    onChange={handleInputChange}
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.subfields}>
                        <h3>ChallengeCategoryAllowed</h3>
                        <div className={classes.subfieldControl}>
                            {ChallengeCategoryAllowed.map((categoryAllowed, i) => (
                                <div className={classes.subfield} style={{ marginTop: "20px" }}>
                                    <div key={i}>
                                        <div className={classes.control}>
                                            <label htmlFor={`CategoryID_${i}`}>CategoryID : </label>
                                            <input
                                                type="number"
                                                id={`CategoryID_${i}`}
                                                name={`CategoryID_${i}`}
                                                placeholder="Enter CategoryID"
                                                value={categoryAllowed.CategoryID}
                                                onChange={(e) => handleFormChallengeCategoryAllowed(i, e, 'CategoryID')}
                                            />
                                        </div>
                                        <div style={{ display: "flex", marginBottom: !categoryAllowed.IsUnlimitedAttemptsAllowed ? "0px" : "1rem" }}>
                                            <label htmlFor={`IsUnlimitedAttemptsAllowed_${i}`}>IsUnlimitedAttemptsAllowed : </label>
                                            <select
                                                name="IsUnlimitedAttemptsAllowed"
                                                id="IsUnlimitedAttemptsAllowed"
                                                value={categoryAllowed.IsUnlimitedAttemptsAllowed}
                                                onChange={(e) => handleFormChallengeCategoryAllowed(i, e, 'IsUnlimitedAttemptsAllowed')}
                                            >
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                        {!categoryAllowed.IsUnlimitedAttemptsAllowed && (
                                            <div className={classes.control}>
                                                <label htmlFor={`AttemptsAllowed_${i}`}>AttemptsAllowed : </label>
                                                <input
                                                    type="number"
                                                    id={`AttemptsAllowed_${i}`}
                                                    onChange={(e) => handleFormChallengeCategoryAllowed(i, e, 'AttemptsAllowed')}
                                                    name={`AttemptsAllowed_${i}`}
                                                    placeholder="Enter AttemptsAllowed"
                                                    value={categoryAllowed.AttemptsAllowed}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            removeChallengeCategoryAllowed(i);
                                        }}
                                    >
                                        Remove Category
                                    </button>
                                </div>
                            ))}
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    addChallengeCategoryAllowed(event);
                                }}
                            >
                                Add More Category...
                            </button>
                        </div>
                    </div>


                    <div className={classes.subfields}>
                        <h3>Solo Challenge Level</h3>
                        <div className={classes.subfieldsControl}>
                            <div className={classes.selectbox}>
                                <label htmlFor="IsSoloChallenge"> IsSoloChallenge : </label>
                                <select
                                    name="IsSoloChallenge"
                                    id="IsSoloChallenge"
                                    value={SoloChallengeLevel.IsSoloChallenge.toString()}
                                    onChange={(e) =>
                                        handleFormChangeSoloChallengeLevel("IsSoloChallenge", e)
                                    }
                                >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                            </div>
                            {SoloChallengeLevel.IsSoloChallenge && (
                                <>
                                    <div
                                        className={classes.selectbox}
                                        style={{
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <label htmlFor="IsSoloChallengeAllLevel">
                                            {" "}
                                            IsSoloChallengeAllLevel :{" "}
                                        </label>
                                        <select
                                            name="IsSoloChallengeAllLevel"
                                            id="IsSoloChallengeAllLevel"
                                            value={SoloChallengeLevel.IsSoloChallengeAllLevel.toString()}
                                            onChange={(e) =>
                                                handleFormChangeSoloChallengeLevel(
                                                    "IsSoloChallengeAllLevel",
                                                    e
                                                )
                                            }
                                        >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </select>
                                    </div>
                                    {SoloChallengeLevel.IsSoloChallengeAllLevel === false && (
                                        <>
                                            <div className={classes.subfields}>
                                                <h3>Levels</h3>
                                                {SoloChallengeLevel.Levels.map((level, index) => (
                                                    <div key={index}>
                                                        <div className={classes.control}>
                                                            <label htmlFor={`Level`} style={{ width: "500px" }}>
                                                                Level :
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id="Level"
                                                                placeholder="Enter Level"
                                                                value={level.Level}
                                                                required
                                                                onChange={(event) => handleFormChangeLevels(index, event)}
                                                            />
                                                        </div>
                                                        <button
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                removeFieldsLevels(index);
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                                <button
                                                    onClick={addFieldsLevels}
                                                    style={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    Add More Levels...
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={IsActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className={classes.selectbox}>
                        <label htmlFor="IsAdsFree">IsAdsFree : </label>
                        <select
                            name="IsAdsFree"
                            id="IsAdsFree"
                            value={IsAdsFree}
                            onChange={(e) => setIsAdsFree(e.target.value)}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} style={{ marginBottom: "10px" }}>
                        <label htmlFor="IsReminders">IsReminders : </label>
                        <select
                            name="IsReminders"
                            id="IsReminders"
                            value={IsReminders}
                            onChange={(e) => setIsReminders(e.target.value)}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>

                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default SubscriptionCreationForm;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_ADD_ONBOARDING_SCREEN_QUESTIONS, BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_QUESTIONS } from "../../../../base_routes_paths/auth_paths";
import { OnboardinScreenQuestionsServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const OSQuestions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [ID, setID] = useState("")
    const [Points, setPoints] = useState(0);
    const [StringLang, setStringLang] = useState("");
    const [QuestionArray, setQuestionArray] = useState([]);
    const { token } = AUTH_CONTEXT();
    const navigate = useNavigate();

    const FetchResponse = async () => {
        try {
            let response = await OnboardinScreenQuestionsServices.getOnboardinScreenQuestions(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data[0];
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setPoints(result.Points);
                    setQuestionArray(result.QuestionArray);
                    setID(result._id);
                    setStringLang(result.StringLang)
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <>
            {isLoading && (
                <div className={classes.crumbs}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Auth
                        </Typography>
                        <Typography color="text.primary">View Onboarding Screen Questions</Typography>
                    </Breadcrumbs>
                    <Card className={classes.input}>
                        <div>
                            <div className={classes.Main_div}>Onboarding Screen Questions</div>
                            <div className={classes.control}>
                                <label htmlFor="Points">Points :</label>
                                <input
                                    type="text"
                                    id="Points"
                                    value={Points}
                                    disabled
                                />
                            </div>

                            <div className={classes.control}>
                                <label htmlFor="Stringlang">Stringlang :</label>
                                <input
                                    type="text"
                                    id="Stringlang"
                                    value={StringLang}
                                    disabled
                                />
                            </div>

                            <div className={classes.subfields} style={{ marginBottom: "20px" }} >
                                <h3>Questions List</h3>
                                {QuestionArray.map((Questions, index) => (
                                    <div key={index}>
                                        <div className={classes.subfields}>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>QuestionID : </span>
                                                <span className={classes.itemValue}>{Questions.QuestionID}</span>
                                            </div>
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>Question : </span>
                                                <span className={classes.itemValue}>{Questions.Question}</span>
                                            </div>
                                            <h3 style={{ marginBottom: "10px" }}>Values :</h3>
                                            {Questions.ValueArray.map((ValueArray) =>
                                            (
                                                <div className={classes.subfields} style={{ marginBottom: "20px", border: "2px solid black" }}>
                                                    <div className={classes.detailItem}>
                                                        <span className={classes.itemKey}>ValueID : </span>
                                                        <span className={classes.itemValue}>{ValueArray.ValueID}</span>
                                                    </div>
                                                    <div className={classes.detailItem}>
                                                        <span className={classes.itemKey}>Value : </span>
                                                        <span className={classes.itemValue}>{ValueArray.Value}</span>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                            <div className={classes.detailItem}>
                                                <span className={classes.itemKey}>SortID : </span>
                                                <span className={classes.itemValue}>{Questions.SortID}</span>
                                            </div>
                                            <div className={classes.selectbox}>
                                                <label htmlFor="IsActive">IsActive : </label>
                                                <select
                                                    name="IsActive"
                                                    id="IsActive"
                                                    value={Questions.IsActive}
                                                    disabled
                                                >
                                                    <option value={false}>False</option>
                                                    <option value={true}>True</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div style={{ display: "flex" }}>
                                <div>
                                    <Button
                                        onClick={() => {
                                            navigate(`${BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_QUESTIONS}/${ID}`);
                                        }}
                                    >
                                        Edit Onboarding Screen Questions
                                    </Button>
                                </div>
                                <div style={{ marginLeft: "1rem" }}>
                                    <Button
                                        onClick={() => {
                                            navigate(BASE_PATH_TO_ADD_ONBOARDING_SCREEN_QUESTIONS);
                                        }}

                                    >
                                        Add New Onboarding Screen Questions
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card >
                </div>
            )
            }
            {!isLoading && <Loader />}
        </>
    );
};

export default OSQuestions;

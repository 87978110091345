import StartingPageContent from "../pages/overview";
import DashboardPageLayout from "../layouts/dashboard_layout";
import { ProtectedRoutes } from "./protected_route";
import UserList from "../pages/data/all_users";
import UserDetails from "../pages/data/all_users/user_details";
import SoloChallengeParticipants from "../pages/data/solo_challenge_users_details";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS, BASE_PATH_TO_ALL_USERS, BASE_PATH_TO_USER_DETAILS, BASE_PATH_TO_USER_ONBOARDING_SCREEN_RECOMMENDATIONS, BASE_PATH_TO_USER_GOALS, BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS, BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS, BASE_PATH_TO_TESTIMONIALS_LIST, BASE_PATH_TO_UPDATE_TESTIMONIAL, BASE_PATH_TO_LEADERBOARD_INPUT, BASE_PATH_TO_LEADERBOARD, BASE_PATH_TO_USER_ACTIVITY, BASE_PATH_TO_REDEEM_INPUT, BASE_PATH_TO_REDEEM_DATA, BASE_PATH_TO_UPDATE_REDEEM, BASE_PATH_TO_USER_SNAPSHOTS, BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS, BASE_PATH_TO_USER_SUBSCRIPTION, BASE_PATH_TO_USER_RECOMMENDATIONS, BASE_PATH_TO_USER_SOLO_CHALLENGE, BASE_PATH_TO_USER_CHALLENGE_ATTEMPTS, BASE_PATH_TO_USER_CHALLENGE_SNAPSHOTS, BASE_PATH_TO_UPDATE_FEEDBACKS, BASE_PATH_TO_USER_All_ACTIVITIES } from "../base_routes_paths/data_paths";
import OsRecommendationsList from "../pages/data/user_onboarding_screen_recommendations";
import UserGoals from "../pages/data/user_goals";
import IOSUserSubscriptionsList from "../pages/data/user_subscriptions/ios_user_subscriptions";
import AndroidUserSubscriptionsList from "../pages/data/user_subscriptions/android_user_subscriptions";
import Testimonials from "../pages/data/testimonials/testimonials_list";
import UpdateTestimonial from "../pages/data/testimonials/update_testimonial";
import LeaderBoardInput from "../pages/data/leaderboard/input";
import Leaderboard from "../pages/data/leaderboard/leaderboard_list";
import UserActivityList from "../pages/data/user_activity";
import RedeemUserInput from "../pages/data/redeemptiion_details/redeemption_input";
import UpdateRedeemptionData from "../pages/data/redeemptiion_details/update_redeemption_details";
import RedeemUserDetailsList from "../pages/data/redeemptiion_details/redeemption_details";
import UserSnapShotsConfig from "../pages/data/UserSnapShots/UserSnapShots";
import IOSUserSubscription from "../pages/data/user_subscriptions/ios_user_subscriptions/ios_details";
import AndroidUserSubscription from "../pages/data/user_subscriptions/android_user_subscriptions/android_details";
import UserOSRecommendations from "../pages/data/user_onboarding_screen_recommendations/user-recommendations";
import UserSubscriptionDetailsList from "../pages/redeem/subscriptions/user_subscription_details";
import UserSoloChallengeDetailsList from "../pages/challenge/solo_challenge/user_solo_challenge";
import UserChallengeAttempt from "../pages/challenge/challenge/user_challenge_attempts";
import UserChallangeAttemptSnapshots from "../pages/data/user_challenge_attempts";
import UserFeedbacks from "../pages/data/feedbacks";
import UserAllActitivty from "../pages/data/user_all_activities";

const DataRoutes = {
    path: BASE_PATH_TO_HOME,
    element: (
        <ProtectedRoutes>
            <DashboardPageLayout />
        </ProtectedRoutes>
    ),
    children: [
        {
            path: BASE_PATH_TO_HOME,
            element: <StartingPageContent />,
        },
        {
            path: BASE_PATH_TO_ALL_USERS,
            element: <UserList />,
        },
        {
            path: `${BASE_PATH_TO_USER_DETAILS}/:UserID`,
            element: <UserDetails />,
        },
        {
            path: BASE_PATH_TO_ALL_SOLO_CHALLENGE_PARTICIPANTS,
            element: <SoloChallengeParticipants />,
        },
        {
            path: BASE_PATH_TO_USER_ONBOARDING_SCREEN_RECOMMENDATIONS,
            element: <OsRecommendationsList />,
        },
        {
            path: BASE_PATH_TO_USER_GOALS,
            element: <UserGoals />,
        },
        {
            path: BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS,
            element: <IOSUserSubscriptionsList />,
        },
        {
            path: BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS,
            element: <AndroidUserSubscriptionsList />,
        },
        {
            path: BASE_PATH_TO_TESTIMONIALS_LIST,
            element: <Testimonials />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_TESTIMONIAL}/:ID`,
            element: <UpdateTestimonial />,
        },
        {
            path: BASE_PATH_TO_LEADERBOARD_INPUT,
            element: <LeaderBoardInput />,
        },
        {
            path: `${BASE_PATH_TO_LEADERBOARD}/:ID`,
            element: <Leaderboard />,
        },
        {
            path: BASE_PATH_TO_USER_ACTIVITY,
            element: <UserActivityList />,
        },
        {
            path: BASE_PATH_TO_REDEEM_INPUT,
            element: <RedeemUserInput />,
        },
        {
            path: `${BASE_PATH_TO_REDEEM_DATA}/:date`,
            element: <RedeemUserDetailsList />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_REDEEM}/:ID`,
            element: <UpdateRedeemptionData />,
        },
        {
            path: `${BASE_PATH_TO_USER_SNAPSHOTS}/:UserID/:ChallengeID`,
            element: <UserSnapShotsConfig />,
        },
        {
            path: `${BASE_PATH_TO_IOS_USER_SUBSCRIPTIONS_DETAILS}/:ID`,
            element: <IOSUserSubscription />,
        },
        {
            path: `${BASE_PATH_TO_ANDROID_USER_SUBSCRIPTIONS_DETAILS}/:ID`,
            element: <AndroidUserSubscription />,
        },
        {
            path: `${BASE_PATH_TO_USER_RECOMMENDATIONS}/:ID`,
            element: <UserOSRecommendations />,
        },
        {
            path: `${BASE_PATH_TO_USER_SUBSCRIPTION}/:ID`,
            element: <UserSubscriptionDetailsList />,
        },
        {
            path: `${BASE_PATH_TO_USER_SOLO_CHALLENGE}/:ID`,
            element: <UserSoloChallengeDetailsList />,
        },
        {
            path: `${BASE_PATH_TO_USER_CHALLENGE_ATTEMPTS}/:ID`,
            element: <UserChallengeAttempt />,
        },
        {
            path: `${BASE_PATH_TO_USER_CHALLENGE_SNAPSHOTS}/:ID`,
            element: <UserChallangeAttemptSnapshots />,
        },
        {
            path: `${BASE_PATH_TO_UPDATE_FEEDBACKS}/:ID`,
            element: <UserFeedbacks />,
        },
        {
            path: `${BASE_PATH_TO_USER_All_ACTIVITIES}/:ID`,
            element: <UserAllActitivty />,
        },
    ],
};

export default DataRoutes;

import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import classes from "../../Point.module.css";
import AUTH_CONTEXT from "../../../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import { PointServices } from "../../../../services/point_services";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_NEW_POINT_CONFIG, BASE_PATH_TO_UPDATE_POINT_CONFIG } from "../../../../base_routes_paths/points_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";

const PointConfigList = () => {
    const userColumns = [
        { field: "id", headerName: "ID", width: 230 },
        {
            field: "type",
            headerName: "Type",
            width: 200,
        },
        {
            field: "DefaultPoint",
            headerName: "Default Point",
            width: 123,
        },
        {
            field: "IsPromotional",
            headerName: "IsPromotional",
            width: 120,
        },
        {
            field: "IsDatePromotional",
            headerName: "IsDatePromotional",
            width: 140,
        },
        {
            field: "IsNoOfPPLPromotional",
            headerName: "IsNoOfPPLPromotional",
            width: 180,
        },
        {
            field: "PromotionStartDate",
            headerName: "Promotion StartDate",
            width: 180,
        },
        {
            field: "PromotionEndDate",
            headerName: "Promotion EndDate",
            width: 180,
        },
        {
            field: "NoofPPL",
            headerName: "Max. People",
            width: 100,
        },
        {
            field: "NoofPPLGiven",
            headerName: "People Rewarded",
            width: 140,
        },
        {
            field: "PromotionalPoint",
            headerName: "Promotional point",
            width: 160,
        },
        {
            field: "Priority",
            headerName: "Priority",
            width: 80,
        },
    ];

    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_PointsList`;
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();
    const [OngoingPromotion, setOngoingPromotion] = useState([]);
    const [UpcomingPromotion, setUpcomingPromotion] = useState([]);
    const [NonPromotionalConfig, setNonPromotionalConfig] = useState([]);
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await PointServices.getAllPointConfig(token, navigate);
            if (response.status === 207) {
                toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi()
                .then((result) => {
                    let OngoingPromotion = result.OngoingPromotion;
                    let UpcomingPromotion = result.UpcomingPromotion;
                    let NonPromotionalConfig = result.NonPromotionalConfig;

                    let OngoingPromotionInput = OngoingPromotion.map(
                        ({ _id: id, ...OngoingPromotion }) => ({
                            id,
                            type: "OngoingPromotion",
                            ...OngoingPromotion,
                        })
                    );
                    setOngoingPromotion(OngoingPromotionInput);

                    let UpcomingPromotionInput = UpcomingPromotion.map(
                        ({ _id: id, ...UpcomingPromotion }) => ({
                            id,
                            type: "UpcomingPromotion",
                            ...UpcomingPromotion,
                        })
                    );
                    setUpcomingPromotion(UpcomingPromotionInput);

                    let NonPromotionalConfigInput = NonPromotionalConfig.map(
                        ({ _id: id, ...NonPromotionalConfig }) => ({
                            id,
                            type: "NonPromotionalConfig",
                            ...NonPromotionalConfig,
                        })
                    );
                    setNonPromotionalConfig(NonPromotionalConfigInput);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    useEffect(() => {
        let inputData = OngoingPromotion.concat(
            UpcomingPromotion,
            NonPromotionalConfig
        );
        setData(inputData);
    }, [OngoingPromotion, UpcomingPromotion, NonPromotionalConfig]);

    const handleDelete = (id) => {
        PointServices.deletePointConfig(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_POINT_CONFIG}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Point
                </Typography>
                <Typography color="text.primary">PointConfigList</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_NEW_POINT_CONFIG);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        )
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default PointConfigList;

import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import classes from "../../Dashboard.module.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import CustomToolbar from "../../../../components/toolbar";
import { BASE_PATH_TO_ADD_MENU_URL, BASE_PATH_TO_UPDATE_MENU_URL } from "../../../../base_routes_paths/dashboard_paths";
import { MenuURLServices } from "../../../../services/dashboard_services";

const MenuUrlListConfig = () => {
    const userColumns = [
        {
            field: "Key",
            headerName: "Key",
            width: 250,
        },
        {
            field: "Value",
            headerName: "Link",
            width: 750,
            renderCell: (params) => {
                const link = params.row.Value;
                return (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                );
            },
        },
        {
            field: "KeyTitle",
            headerName: "KeyTitle",
            width: 400,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const KeyTitle = params.row.KeyTitle;

                return (
                    <div>
                        <table style={{ textAlign: "center" }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingRight: 25 }}>Key</th>
                                    <th style={{ paddingRight: 25 }}>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(KeyTitle).map(([key, value]) => (
                                    <tr key={key}>
                                        <td style={{ paddingRight: 25 }}>{key}</td>
                                        <td style={{ paddingRight: 25 }}>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            },
        },
    ];

    const navigate = useNavigate();
    const { token, fileName } = AUTH_CONTEXT()
    const EXPORT_FILENAME = `${fileName}_MenuURLSList`;
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);

    const FetchApi = async () => {
        try {
            let response = await MenuURLServices.getAllMenuURL(token, navigate);
            if (response.status === 207) {
                return toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            setIsDataFetched(true);

            let result = response.data.Links;

            let input = result.map(({ _id: id, ...result }) => ({
                id,
                ...result,
            }));
            setData(input);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi();
        }
    }, []);

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <div>
                            <Link
                                href={`${BASE_PATH_TO_UPDATE_MENU_URL}/` + params.row.id}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={classes.viewButton}>Update</div>
                            </Link>
                        </div>
                        <div
                            className={classes.deleteButton}
                            onClick={() => MenuURLServices.deleteMenuURL(params.row.id, token)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {isDataFetched && (
                <div className={classes.datatable}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Dashboard
                        </Typography>
                        <Typography color="text.primary">Menu URL's</Typography>
                    </Breadcrumbs>
                    <DataGridPremium
                        rows={data}
                        columns={userColumns.concat(actionColumn)}
                        slots={{
                            toolbar: (params) => (
                                <CustomToolbar
                                    EXPORT_FILENAME={EXPORT_FILENAME}
                                    onClickCreate={() => {
                                        navigate(BASE_PATH_TO_ADD_MENU_URL);
                                    }}
                                    displayCreate={"block"}
                                    onClickUpdate={() => { }}
                                    displayUpdate={"none"}
                                />
                            ),
                        }}
                        pagination
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelectionVisibleOnly
                        checkboxSelection
                        rowHeight={150}
                    />
                </div>
            )}
            {!isDataFetched && <Loader />}
        </div>
    );
};

export default MenuUrlListConfig;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Challenge.module.css";
import { toast } from "react-toastify";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import Loader from "../../../../components/Loader/Loader";
import { BASE_PATH_TO_CHALLENGES_LIST } from "../../../../base_routes_paths/challenge_paths";
import { ChallenegeServices } from "../../../../services/challenge_services";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";

const ChallengeUpdationForm = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [WorkoutID, setWorkoutID] = useState("");
    const [Name, setName] = useState("");
    const [Threshold, SetThreshold] = useState("");
    const [JoinByTimeUtc, setJoinByTimeUtc] = useState("");
    const [EndsInTimeUtc, setEndsInTimeUtc] = useState("");
    const [StartsTimeUtc, setStartsTimeUtc] = useState("");
    const [NoOfAttempts, setNoOfAttempts] = useState("");
    const [SetPoint, setSetPoint] = useState("");
    const [TimeDuration, setTimeDuration] = useState("");
    const [ChallengeThumbURL, setChallengeThumbURL] = useState("");
    const [CorporateID, setCorporateID] = useState("");
    const [SmsVideoUrl, setSmsVideoUrl] = useState("");
    const [IsSponsored, setIsSponsored] = useState(true);
    const [IsCumulative, setIsCumulative] = useState(true);
    const [gender, setGender] = useState("All");
    const [selectedCategoryID, setSelectedCategoryID] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [subscriberOnly, setSubscriberOnly] = useState(false);
    const [inputSponseredReward, setinputSponseredReward] = useState([
        { Ranks: "", Rewards: "" },
    ]);
    const [ClanID, setClanID] = useState("");
    const [DeviceType, setDeviceType] = useState("All");
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [Description, setDescription] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const { token } = AUTH_CONTEXT();

    const [WorkoutIDAPI, setWorkoutIDAPI] = useState([]);
    const [CategoryIDAPI, setCategoryIDAPI] = useState([]);

    const fetchWorkoutIDDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetWorkoutID`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            response = await response.json()
            StatusCode(response, navigate);
            setWorkoutIDAPI(response.data);
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleWorkoutIDSelectClick = () => {
        if (WorkoutIDAPI.length === 0) {
            fetchWorkoutIDDetails();
        }
    };

    const fetchCategoryIDDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/GetChallengeIDForAdminDashboard`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            response = await response.json()
            StatusCode(response, navigate);
            setCategoryIDAPI(response.data);
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleCategoryIDSelectClick = () => {
        if (CategoryIDAPI.length === 0) {
            fetchCategoryIDDetails();
        }
    };

    const FetchResponseGetChallengeDetailsByUserID = async () => {
        try {
            let response = await ChallenegeServices.getChallengeDetails(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            console.log(e.message);
            alert(e.message);
        }
    };

    useEffect(() => {
        async function FetchData() {
            if (!isDataFetched) {
                let result = await FetchResponseGetChallengeDetailsByUserID();
                setWorkoutID(result.WorkoutID);
                setName(result.Name);
                SetThreshold(result.Threshold);
                setJoinByTimeUtc(result.JoinByTimeUtc ? new Date(result.JoinByTimeUtc).toISOString().slice(0, 16) : null);
                setEndsInTimeUtc(result.EndsInTimeUtc ? new Date(result.EndsInTimeUtc).toISOString().slice(0, 16) : null);
                setStartsTimeUtc(result.StartsTimeUtc ? new Date(result.StartsTimeUtc).toISOString().slice(0, 16) : null);
                setNoOfAttempts(result.NoOfAttempts);
                setSetPoint(result.SetPoint);
                setTimeDuration(result.TimeDuration);
                setChallengeThumbURL(result.ChallengeThumbURL);
                setCorporateID(result.CorporateID);
                setSmsVideoUrl(result.SmsVideoUrl);
                setIsSponsored(result.IsSponsored);
                setIsCumulative(result.IsCumulative);
                setGender(result.Gender);
                setSelectedCategoryID(result.CategoryID);
                setinputSponseredReward(result.SponsoredReward);
                setSubscriberOnly(result.SubscriberOnly);
                setIsActive(result.IsActive)
                setDescription(result.Description ? result.Description : {
                    "en-US": "",
                    "en-IN": "",
                    "en-CA": ""
                });
                setDeviceType(result.DeviceType)
                setClanID(result.ClanID)
            }
        }

        if (params.ID) {
            setIsLoaderLoading(true);
            FetchData();
            handleWorkoutIDSelectClick();
            handleCategoryIDSelectClick()
        }
    }, []);

    const submitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const FetchApi = async () => {
            try {
                let NewCorporateID = CorporateID.toUpperCase();

                const Data = {
                    _id: params.ID,
                    Data: {
                        WorkoutID: WorkoutID,
                        Name: Name,
                        Threshold: +Threshold,
                        JoinByTimeUtc: JoinByTimeUtc,
                        EndsInTimeUtc: EndsInTimeUtc,
                        StartsTimeUtc: StartsTimeUtc,
                        NoOfAttempts: +NoOfAttempts,
                        SetPoint: +SetPoint,
                        TimeDuration: +TimeDuration,
                        ChallengeThumbURL: ChallengeThumbURL,
                        CorporateID: NewCorporateID,
                        SmsVideoUrl: SmsVideoUrl,
                        IsSponsored: IsSponsored,
                        SponsoredReward: inputSponseredReward,
                        IsCumulative: IsCumulative,
                        IsActive: isActive,
                        Description: Description,
                        CategoryID: selectedCategoryID,
                        Gender: gender,
                        SubscriberOnly: subscriberOnly,
                        DeviceType: DeviceType,
                        ClanID: ClanID
                    },
                }

                let response = await ChallenegeServices.updateChallenges(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_CHALLENGES_LIST);
                toast.success("Data updated successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const handleFormChangeCategoryID = (e) => {
        const selectedValue = parseInt(e.target.value);
        setSelectedCategoryID(selectedValue);
    };

    const handleFormChangeRewards = (index, event) => {
        let data = [...inputSponseredReward];
        data[index][event.target.name] = event.target.value;
        setinputSponseredReward(data);
    };

    const addFieldsRewards = (event) => {
        event.preventDefault();
        let newfield = { Ranks: "", Rewards: "" };
        setinputSponseredReward([...inputSponseredReward, newfield]);
    };

    const removeFieldsRewards = (index) => {
        let data = [...inputSponseredReward];
        data.splice(index, 1);
        setinputSponseredReward(data);
    };

    const handleDescriptionChange = (e, locale) => {
        setDescription(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <div className={classes.crumbs}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">
                            Challenege
                        </Typography>
                        <Typography color="text.primary">Update Challenge</Typography>
                    </Breadcrumbs>
                    <Card className={classes.input}>
                        <div className={classes.Main_div}>Update Challenge</div>
                        <form onSubmit={submitHandler}>
                            <div className={classes.selectbox}>
                                <label htmlFor="WorkoutID">Workout ID : </label>
                                <select
                                    name="WorkoutID"
                                    id="WorkoutID"
                                    placeholder="Select One"
                                    required
                                    style={{ width: "300px" }}
                                    onChange={(e) => setWorkoutID(e.target.value)}
                                    value={WorkoutID}
                                >
                                    {WorkoutIDAPI.map((Workout) => (
                                        <option key={Workout.WorkoutID} value={Workout.WorkoutID}>
                                            {Workout.WorkoutID}.{Workout.WorkoutName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="CategoryID">Category ID : </label>
                                <select
                                    name="CategoryID"
                                    id="CategoryID"
                                    placeholder="Select One"
                                    required
                                    style={{ width: "300px" }}
                                    onChange={(e) => handleFormChangeCategoryID(e)}
                                    value={selectedCategoryID}
                                >
                                    {CategoryIDAPI.map((Category) => (
                                        <option key={Category.CategoryID} value={Category.CategoryID}>
                                            {Category.CategoryID}.{Category.CategoryName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={classes.control}>
                                <label htmlFor="Name">Name :</label>
                                <input
                                    type="text"
                                    id="Name"
                                    required
                                    value={Name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter name of challenge"
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="Threshold">Threshold :</label>
                                <input
                                    type="number"
                                    id="Threshold"
                                    required
                                    value={Threshold}
                                    onChange={(e) => SetThreshold(parseInt(e.target.value))}
                                    placeholder="Enter threshold for challenge"
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="StartsTimeUtc">StartsTimeUtc : </label>
                                <input
                                    id="StartsTimeUtc"
                                    type="datetime-local"
                                    value={StartsTimeUtc}
                                    required
                                    onChange={(e) => setStartsTimeUtc(e.target.value)}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="JoinByTimeUtc">JoinByTimeUtc : </label>
                                <input
                                    id="JoinByTimeUtc"
                                    type="datetime-local"
                                    value={JoinByTimeUtc}
                                    required
                                    onChange={(e) => setJoinByTimeUtc(e.target.value)}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="EndsInTimeUtc">EndsInTimeUtc : </label>
                                <input
                                    id="EndsInTimeUtc"
                                    type="datetime-local"
                                    value={EndsInTimeUtc}
                                    required
                                    onChange={(e) => setEndsInTimeUtc(e.target.value)}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="NoOfAttempts">No Of Attempts : </label>
                                <input
                                    required
                                    id="NoOfAttempts"
                                    type="number"
                                    placeholder="Enter maximum number of attempts"
                                    value={NoOfAttempts}
                                    onChange={(e) => setNoOfAttempts(parseInt(e.target.value))}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="setPoint">Set Point : </label>
                                <input
                                    required
                                    id="setPoint"
                                    type="number"
                                    placeholder="Enter joining points"
                                    value={SetPoint}
                                    onChange={(e) => setSetPoint(parseInt(e.target.value))}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="TimeDuration">Time Duration : </label>
                                <input
                                    required
                                    id="TimeDuration"
                                    type="number"
                                    placeholder="Enter time duration (in sec)"
                                    value={TimeDuration}
                                    onChange={(e) => setTimeDuration(parseInt(e.target.value))}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="CorporateID">CorporateID : </label>
                                <input
                                    id="CorporateID"
                                    type="text"
                                    placeholder="Corporate ID"
                                    value={CorporateID}
                                    style={{ textTransform: "uppercase" }}
                                    onChange={(e) => setCorporateID(e.target.value)}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="ClanID">ClanID : </label>
                                <input
                                    id="ClanID"
                                    type="text"
                                    placeholder="ClanID"
                                    value={ClanID}
                                    style={{ textTransform: "uppercase" }}
                                    onChange={(e) => setClanID(e.target.value)}
                                />
                            </div>


                            <div className={classes.control}>
                                <label htmlFor="ChallengeThumbURL">
                                    Challenge Thumb URL :{" "}
                                </label>
                                <input
                                    id="ChallengeThumbURL"
                                    type="text"
                                    placeholder="Enter ChallengeThumbURL"
                                    value={ChallengeThumbURL}
                                    onChange={(e) => setChallengeThumbURL(e.target.value)}
                                />
                            </div>

                            <div className={classes.control}>
                                <label htmlFor="SmsVideoUrl">Sms Video Url : </label>
                                <input
                                    id="SmsVideoUrl"
                                    type="text"
                                    placeholder="Enter SmsVideoUrl"
                                    value={SmsVideoUrl}
                                    onChange={(e) => setSmsVideoUrl(e.target.value)}
                                />
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="Gender">Gender : </label>
                                <select
                                    name="Gender"
                                    id="Gender"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="All">All</option>
                                </select>
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="IsSponsored">IsSponsored : </label>
                                <select
                                    name="IsSponsored"
                                    id="IsSponsored"
                                    value={IsSponsored.toString()}
                                    onChange={(e) => {
                                        const value = e.target.value === 'true';
                                        setIsSponsored(value);
                                        if (!value) {
                                            setinputSponseredReward([]);
                                        } else {
                                            setinputSponseredReward([{ Ranks: "", Rewards: "" }]);
                                        }
                                    }}
                                >
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </select>
                            </div>

                            {IsSponsored && (
                                <div className={classes.subfields}>
                                    <h3>Sponsored Rewards</h3>
                                    {inputSponseredReward.map((input, index) => {
                                        return (
                                            <div key={index} className={classes.subfieldsControl}>
                                                <div className={classes.control}>
                                                    <label htmlFor="Ranks">Rank</label>
                                                    <input
                                                        type="text"
                                                        name="Ranks"
                                                        placeholder="Enter rank"
                                                        value={input.Ranks}
                                                        onChange={(event) =>
                                                            handleFormChangeRewards(index, event)
                                                        }
                                                    />
                                                </div>
                                                <div className={classes.control}>
                                                    <label htmlFor="Rewards">Rewards</label>
                                                    <input
                                                        type="text"
                                                        name="Rewards"
                                                        placeholder="Enter Rewards"
                                                        value={input.Rewards}
                                                        onChange={(event) =>
                                                            handleFormChangeRewards(index, event)
                                                        }
                                                    />
                                                </div>
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        removeFieldsRewards(index);
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        );
                                    })}
                                    <button onClick={addFieldsRewards}>Add More...</button>
                                </div>
                            )}

                            <div className={classes.selectbox}>
                                <label htmlFor="IsCumulative">IsCumulative : </label>
                                <select
                                    name="IsCumulative"
                                    id="IsCumulative"
                                    value={IsCumulative}
                                    onChange={(e) => setIsCumulative(e.target.value)}
                                >
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </select>
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="SubscriberOnly">SubscriberOnly : </label>
                                <select
                                    name="SubscriberOnly"
                                    id="SubscriberOnly"
                                    value={subscriberOnly}
                                    onChange={(e) => setSubscriberOnly(e.target.value)}
                                >
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </select>
                            </div>

                            <div className={classes.selectbox}>
                                <label htmlFor="IsActive">IsActive : </label>
                                <select
                                    name="IsActive"
                                    id="IsActive"
                                    value={isActive}
                                    onChange={(e) => setIsActive(e.target.value)}
                                >
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </select>
                            </div>

                            <div className={classes.selectbox} >
                                <label htmlFor="DeviceType">DeviceType : </label>
                                <select
                                    name="DeviceType"
                                    id="DeviceType"
                                    value={DeviceType}
                                    onChange={(e) => setDeviceType(e.target.value)}
                                >
                                    <option value="All">All</option>
                                    <option value="IOS">IOS</option>
                                    <option value="Android">Android</option>
                                </select>
                            </div>

                            <div className={classes.subfields}>
                                <h3>Descriptions</h3>
                                <div className={classes.subfields}>
                                    <div className={classes.control}>
                                        <label htmlFor="en-US">Description (en-US)</label>
                                        <input
                                            type="text"
                                            id="en-US"
                                            value={Description["en-US"]}
                                            onChange={(e) => handleDescriptionChange(e, "en-US")}
                                            placeholder="Enter Description for en-US"
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="en-IN">Description (en-IN)</label>
                                        <input
                                            type="text"
                                            id="en-IN"
                                            value={Description["en-IN"]}
                                            onChange={(e) => handleDescriptionChange(e, "en-IN")}
                                            placeholder="Enter Description for en-IN"
                                        />
                                    </div>
                                    <div className={classes.control}>
                                        <label htmlFor="en-CA">Description (en-CA)</label>
                                        <input
                                            type="text"
                                            id="en-CA"
                                            value={Description["en-CA"]}
                                            onChange={(e) => handleDescriptionChange(e, "en-CA")}
                                            placeholder="Enter Description for en-CA"
                                        />
                                    </div>
                                </div>
                            </div>

                            {!isLoading && <Button type="submit">Update</Button>}
                            {isLoading && <p color="white">Sending request...</p>}
                        </form>
                    </Card>
                </div>
            )
            }
        </>
    );
};

export default ChallengeUpdationForm;

export const BASE_PATH_TO_AUTH = "/auth";

export const BASE_PATH_TO_ADD_COMMUNITY_LINK = `${BASE_PATH_TO_AUTH}/add-community-url`
export const BASE_PATH_TO_VIEW_COMMUNITY_LINK = `${BASE_PATH_TO_AUTH}/community-url`
export const BASE_PATH_TO_UPDATE_COMMUNITY_LINK = `${BASE_PATH_TO_AUTH}/update-community-url`

export const BASE_PATH_TO_VIEW_VERSION_CONFIG = `${BASE_PATH_TO_AUTH}/version-config`
export const BASE_PATH_TO_ADD_VERSION_CONFIG = `${BASE_PATH_TO_AUTH}/add-version-config`
export const BASE_PATH_TO_UPDATE_VERSION_CONFIG = `${BASE_PATH_TO_AUTH}/update-version-config`

export const BASE_PATH_TO_SPECIAL_REFERRAL_LIST = `${BASE_PATH_TO_AUTH}/special-referral`
export const BASE_PATH_TO_ADD_SPECIAL_REFERRAL = `${BASE_PATH_TO_AUTH}/add-special-referral`
export const BASE_PATH_TO_UPDATE_SPECIAL_REFERRAL = `${BASE_PATH_TO_AUTH}/update-special-referral`

export const BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_QUESTIONS = `${BASE_PATH_TO_AUTH}/onboarding-screen-questions`
export const BASE_PATH_TO_ADD_ONBOARDING_SCREEN_QUESTIONS = `${BASE_PATH_TO_AUTH}/add-onboarding-screen-questions`
export const BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_QUESTIONS = `${BASE_PATH_TO_AUTH}/update-onboarding-screen-questions`

export const BASE_PATH_TO_VIEW_ONBOARDING_SCREEN_RECOMMENDATIONS = `${BASE_PATH_TO_AUTH}/onboarding-screen-recommendations`
export const BASE_PATH_TO_ADD_ONBOARDING_SCREEN_RECOMMENDATIONS = `${BASE_PATH_TO_AUTH}/add-onboarding-screen-recommendations`
export const BASE_PATH_TO_UPDATE_ONBOARDING_SCREEN_RECOMMENDATIONS = `${BASE_PATH_TO_AUTH}/update-onboarding-screen-recommendations`

export const BASE_PATH_TO_VIEW_IP_ADDRESS = `${BASE_PATH_TO_AUTH}/ip-address`
export const BASE_PATH_TO_ADD_IP_ADDRESS = `${BASE_PATH_TO_AUTH}/add-ip-address`
export const BASE_PATH_TO_UPDATE_IP_ADDRESS = `${BASE_PATH_TO_AUTH}/update-ip-address` 
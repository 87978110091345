import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Point.module.css";
import AUTH_CONTEXT from "../../../../store";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { ThresholdServices } from "../../../../services/point_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_THRESHOLD_POINT } from "../../../../base_routes_paths/points_paths";

const AddThresholdConfig = (props) => {
    const navigate = useNavigate();
    const ThresholdRef = useRef();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const SubmitHandler = (event) => {
        event.preventDefault();
        let EnteredThreshold = ThresholdRef.current.value;
        setIsLoading(true);

        const FetchResponse = async () => {
            try {
                let response = await ThresholdServices.addThreshold(token, navigate, EnteredThreshold);

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_THRESHOLD_POINT);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Points
                </Typography>
                <Typography color="text.primary">Add Threshold</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Add threshold for Redeemption</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <label htmlFor="threshold">Threshold Value : </label>
                        <input
                            type="number"
                            id="threshold"
                            required
                            ref={ThresholdRef}
                            placeholder="Enter threshold for voucher redeemption"
                        />
                    </div>
                    {!isLoading && <Button type="submit">Add</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default AddThresholdConfig;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import Loader from "../../../../components/Loader/Loader";
import { PaymentGatewayService } from "../../../../services/redeem_services";
import { BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST } from "../../../../base_routes_paths/redeem_paths";

const UpdatePaymentGatewayConfig = () => {
    const navigate = useNavigate();
    const [Country, setCountry] = useState("");
    const [DeviceType, setDeviceType] = useState("IOS");
    const [IsPhonePaymentGatewayActive, setIsPhonePaymentGatewayActive] = useState(false);
    const [IsStorePaymentGatewayActive, setIsStorePaymentGatewayActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();

    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);

    const { token } = AUTH_CONTEXT()

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            _id: params.ID,
            Data: {
                Country: Country,
                IsPhonePaymentGatewayActive: IsPhonePaymentGatewayActive,
                IsStorePaymentGatewayActive: IsStorePaymentGatewayActive,
                DeviceType: DeviceType
            }
        }

        const FetchResponse = async () => {
            try {
                let response = await PaymentGatewayService.addPaymentGatewayConfig(token, navigate, Data);

                setIsLoading(false);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_PAYMENT_GATEWAY_CONFIG_LIST);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponse();
    };

    const FetchResponse = async () => {
        try {
            let response = await PaymentGatewayService.getPaymentGatewayConfigs(token, navigate, params.ID);
            setIsLoaderLoading(true)
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoaderLoading(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const FetchData = async () => {
            try {
                let resp = await FetchResponse();
                const response = resp[0];
                setCountry(response.Country)
                setIsPhonePaymentGatewayActive(response.IsPhonePaymentGatewayActive)
                setIsStorePaymentGatewayActive(response.IsStorePaymentGatewayActive)
                setDeviceType(response.DeviceType)
            } catch (error) {
                console.log(error.message);
            }
        };

        if (params.ID) {
            FetchData();
        }
    }, []);

    return (
        !IsLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Challenege
                    </Typography>
                    <Typography color="text.primary">Update Country Conversion Rate</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Add New Payment Gateway Config</div>
                    <form onSubmit={SubmitHandler}>
                        <div className={classes.control}>
                            <label htmlFor="Country">Country</label>
                            <input
                                type="text"
                                id="Country"
                                required
                                value={Country}
                                onChange={(e) => setCountry(e.target.value)}
                                placeholder="Enter Name for Country"
                            />
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsPhonePaymentGatewayActive">IsPhonePaymentGatewayActive : </label>
                            <select
                                name="IsPhonePaymentGatewayActive"
                                id="IsPhonePaymentGatewayActive"
                                value={IsPhonePaymentGatewayActive}
                                onChange={(e) => setIsPhonePaymentGatewayActive(e.target.value)}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsStorePaymentGatewayActive">IsStorePaymentGatewayActive : </label>
                            <select
                                name="IsStorePaymentGatewayActive"
                                id="IsStorePaymentGatewayActive"
                                value={IsStorePaymentGatewayActive}
                                onChange={(e) => setIsStorePaymentGatewayActive(e.target.value)}
                            >
                                <option value={false}>False</option>
                                <option value={true}>True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="DeviceType">DeviceType : </label>
                            <select
                                name="DeviceType"
                                id="DeviceType"
                                value={DeviceType}
                                onChange={(e) => setDeviceType(e.target.value)}
                            >
                                <option value="IOS">IOS</option>
                                <option value="Android">Android</option>
                                <option value="">All</option>
                            </select>
                        </div>

                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p color="white">Sending request...</p>}
                    </form>
                </Card>
            </div>
    );
};

export default UpdatePaymentGatewayConfig;

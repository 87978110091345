import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "../../Redeem.module.css";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { useEffect } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_VOUCHER_LIST } from "../../../../base_routes_paths/redeem_paths";
import { VoucherServices } from "../../../../services/redeem_services";

const UpdateRedeemVoucherConfig = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Title, setTitle] = useState({
        "en-US": "",
        "en-IN": "",
        "en-CA": ""
    });
    const [points, setPoints] = useState("");
    const [price, setPrice] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [category, setCategory] = useState(0);
    const [IsActive, setIsActive] = useState(true);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            Title: Title,
            points: +points,
            price: +price,
            imageUrl: imageUrl,
            category: +category,
            IsActive: IsActive,
        }

        const FetchApi = async () => {
            try {
                let response = await VoucherServices.updateVoucher(token, navigate, params.ID, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                setIsLoading(false);
                navigate(BASE_PATH_TO_VOUCHER_LIST);
                toast.success("Data updated successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchApi();
    };

    const FetchApi = async () => {
        try {
            let response = await VoucherServices.getAllVouchers(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi().then((data) => {
                setTitle(data.Title);
                setPoints(data.points);
                setPrice(data.price);
                setImageUrl(data.imageUrl);
                setCategory(+data.category);
                setIsActive(data.IsActive);
            })
        }
    }, []);

    const handleTitleChange = (e, locale) => {
        setTitle(prevState => ({
            ...prevState,
            [locale]: e.target.value
        }));
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Redeem
                </Typography>
                <Typography color="text.primary">Update Voucher</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>Update Voucher</div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.subfields}>
                        <h3>Title</h3>
                        <div className={classes.subfields}>
                            <div className={classes.control}>
                                <label htmlFor="en-US">Title (en-US)</label>
                                <input
                                    type="text"
                                    id="en-US"
                                    value={Title["en-US"]}
                                    onChange={(e) => handleTitleChange(e, "en-US")}
                                    placeholder="Enter Title for en-US"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-IN">Title (en-IN)</label>
                                <input
                                    type="text"
                                    id="en-IN"
                                    value={Title["en-IN"]}
                                    onChange={(e) => handleTitleChange(e, "en-IN")}
                                    placeholder="Enter Title for en-IN"
                                />
                            </div>
                            <div className={classes.control}>
                                <label htmlFor="en-CA">Title (en-CA)</label>
                                <input
                                    type="text"
                                    id="en-CA"
                                    value={Title["en-CA"]}
                                    onChange={(e) => handleTitleChange(e, "en-CA")}
                                    placeholder="Enter Title for en-CA"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Points">Points</label>
                        <input
                            type="number"
                            id="Points"
                            required
                            value={points}
                            onChange={(e) => setPoints(e.target.value)}
                            placeholder="Enter points required for redeemption"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="Price">Price</label>
                        <input
                            type="number"
                            id="Price"
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price of voucher"
                        />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor="imageUrl">Link</label>
                        <input
                            type="text"
                            id="imageUrl"
                            required
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}
                            placeholder="Enter link of voucher's image"
                        />
                    </div>
                    <div className={classes.selectbox}>
                        <label htmlFor="Category">Category : </label>
                        <select
                            name="Category"
                            id="Category"
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}
                        >
                            <option value={1}>Cash Voucher</option>
                            <option value={2}>Charity Voucher</option>
                        </select>
                    </div>

                    <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                        <label htmlFor="IsActive">IsActive : </label>
                        <select
                            name="IsActive"
                            id="IsActive"
                            value={IsActive}
                            onChange={(e) => setIsActive(e.target.value)}
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                    {!isLoading && <Button type="submit">Update</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default UpdateRedeemVoucherConfig;

import { useState, useEffect } from "react";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Data.module.css";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import {
    Box,
    Breadcrumbs,
    CircularProgress,
    FormControl,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { StatusCode } from "../../../utils";
import ARENO_API_ENDPOINT from "../../../api";
import AUTH_CONTEXT from "../../../store";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS } from "../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_CHALLENGE_DETAILS } from "../../../base_routes_paths/challenge_paths";

const userActvityColumns = [
    {
        field: "UserID",
        headerName: "UserID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.UserID}>{params.row.UserID}</a>
            );
        },
    },
    {
        field: "ChallengeID",
        headerName: "ChallengeID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_CHALLENGE_DETAILS}/` + params.row.ChallengeID}>{params.row.ChallengeID}</a>
            );
        },
    },
    {
        field: "UserName",
        headerName: "UserName",
        width: 180,
    },
    {
        field: "IpAddress",
        headerName: "IpAddress",
        width: 180,
    },
    {
        field: "CorporateID",
        headerName: "CorporateID",
        width: 120,
    },
    {
        field: "ChallengeName",
        headerName: "Challenge Name",
        width: 200,
    },
    {
        field: "ChallengeCategory",
        headerName: "Challenge Category",
        width: 180,
    },
    {
        field: "Date",
        headerName: "Date",
        width: 150,
    },
    {
        field: "Time",
        headerName: "Time",
        width: 150,
    },
    {
        field: "AttemptResult",
        headerName: "Attempt Result",
        width: 150,
    },
];

const meditationColumns = [
    {
        field: "UserID",
        headerName: "User ID",
        width: 150,
    },
    {
        field: "MeditationID",
        headerName: "Meditation ID",
        width: 200,
    },
    {
        field: "IsDeleted",
        headerName: "Is Deleted",
        width: 150,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "IsCompleted",
        headerName: "Is Completed",
        width: 150,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "TimeDurationCompleted",
        headerName: "Time Duration Completed",
        width: 250,
    },
    {
        field: "IsUserAttempted",
        headerName: "Is User Attempted",
        width: 200,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "LocalDate",
        headerName: "Local Date",
        width: 150,
    },
    {
        field: "UtcDate",
        headerName: "UTC Date",
        width: 150,
    }
];

const routineColumns = [
    {
        field: "_id",
        headerName: "ID",
        width: 150,
    },
    {
        field: "UserID",
        headerName: "User ID",
        width: 150,
    },
    {
        field: "UserName",
        headerName: "User Name",
        width: 200,
    },
    {
        field: "Location",
        headerName: "Location",
        width: 150,
    },
    {
        field: "Age",
        headerName: "Age",
        width: 100,
    },
    {
        field: "Gender",
        headerName: "Gender",
        width: 100,
    },
    {
        field: "Height",
        headerName: "Height",
        width: 120,
    },
    {
        field: "Weight",
        headerName: "Weight",
        width: 120,
    },
    {
        field: "CorporateID",
        headerName: "Corporate ID",
        width: 150,
    },
    {
        field: "RoutinePlanID",
        headerName: "Routine Plan ID",
        width: 200,
    },
    {
        field: "RoutinePlanName",
        headerName: "Routine Plan Name",
        width: 200,
    },
    {
        field: "LocalDate",
        headerName: "Local Date",
        width: 150,
    },
    {
        field: "UtcDate",
        headerName: "UTC Date",
        width: 150,
    },
    {
        field: "AttemptResult",
        headerName: "Attempt Result",
        width: 150,
    },
    {
        field: "IpAddress",
        headerName: "IP Address",
        width: 180,
    },
    {
        field: "IsUserAttempted",
        headerName: "Is User Attempted",
        width: 180,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "IsCompleted",
        headerName: "Is Completed",
        width: 150,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "RoutineActivityID",
        headerName: "Routine Activity ID",
        width: 200,
    },
    {
        field: "TotalCaloriesBurnt",
        headerName: "Total Calories Burnt",
        width: 180,
    },
    {
        field: "created_at",
        headerName: "Created At",
        width: 200,
    },
    {
        field: "updated_at",
        headerName: "Updated At",
        width: 200,
    },
];

const stepsColumns = [
    {
        field: "_id",
        headerName: "ID",
        width: 150,
    },
    {
        field: "UserID",
        headerName: "User ID",
        width: 150,
    },
    {
        field: "Steps",
        headerName: "Steps",
        width: 120,
    },
    {
        field: "StepsCalories",
        headerName: "Steps Calories",
        width: 150,
    },
    {
        field: "SyncDate",
        headerName: "Sync Date",
        width: 150,
    },
    {
        field: "StepsSyncHistory",
        headerName: "Steps Sync History",
        width: 300,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const StepsSyncHistory = params.row.StepsSyncHistory;

            return (
                <div>
                    <table style={{ textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th style={{ paddingRight: 25 }}>Steps</th>
                                <th style={{ paddingRight: 25 }}>Sync Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StepsSyncHistory.map((data, index) => (
                                <tr key={index}>
                                    <td style={{ paddingRight: 25 }}>{data.Steps}</td>
                                    <td style={{ paddingRight: 25 }}>{data.SyncTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div >
            );
        },
    },
    {
        field: "IsAutomatedSync",
        headerName: "Automated Sync",
        width: 150,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "IsDailyPointAwarded",
        headerName: "Daily Point Awarded",
        width: 180,
        renderCell: (params) => (
            <>{params.value ? "Yes" : "No"}</>
        ),
    },
    {
        field: "created_at",
        headerName: "Created At",
        width: 200,
    },
    {
        field: "updated_at",
        headerName: "Updated At",
        width: 200,
    },
];


const UserAllActitivty = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [UserActivity, setUserActivity] = useState([]);
    const [MeditationActivity, setMeditationActivity] = useState([]);
    const [RoutineActivity, setRoutineActivity] = useState([]);
    const [StepsActivity, setStepsActivity] = useState([]);
    const params = useParams();

    const EXPORT_ACTIVITY_FILENAME = `${fileName}_Activities`;
    const navigate = useNavigate();

    const FetchResponse = async (
    ) => {
        try {
            setIsLoading(true);
            setIsDataFetched(false);
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/challenges/AdminGetUserActivity`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        UserID: params.ID,
                        TimeDuration: "year"
                    }),
                }
            );
            StatusCode(response, navigate);
            response = await response.json();
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoading(false);
            return response;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isDataFetched) {
                try {
                    const result = await FetchResponse();
                    const res = result.data.GraphData.ActivityData;
                    let input = res.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setUserActivity((prevData) => [...prevData, ...input]);

                    const resMeditation = result.data.GraphData.MeditationData;
                    let inputMeditation = resMeditation.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setMeditationActivity((prevData) => [...prevData, ...inputMeditation]);

                    const resRoutines = result.data.GraphData.RoutineActivityData;
                    let inputRoutines = resRoutines.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setRoutineActivity((prevData) => [...prevData, ...inputRoutines]);

                    const resSteps = result.data.GraphData.StepsData;
                    let inputSteps = resSteps.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setStepsActivity((prevData) => [...prevData, ...inputSteps]);
                } catch (err) { }
            }
        };

        fetchData();
    }, [isDataFetched]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_ACTIVITY_FILENAME }}
                    excelOptions={{ fileName: EXPORT_ACTIVITY_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            {isLoading ? <Loader /> : (
                <div>
                    <div className={classes.datatable}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                            <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                                Dashboard
                            </Link>
                            <Typography color="text.primary">
                                Data
                            </Typography>
                            <Typography color="text.primary">All UserActivities</Typography>
                        </Breadcrumbs>

                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                            <Typography color="text.primary">
                                UserActivities
                            </Typography>
                            <Typography color="text.primary">Challenges</Typography>
                        </Breadcrumbs>

                        <DataGridPremium
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            rows={UserActivity}
                            columns={userActvityColumns}
                            pagination
                            pageSizeOptions={[20, 50, 100, 500, 1000]}
                            disableRowSelectionOnClick
                            loading={isLoading}
                            checkboxSelectionVisibleOnly
                            checkboxSelection
                        />
                    </div>

                    <div className={classes.datatable}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                            <Typography color="text.primary">
                                UserActivities
                            </Typography>
                            <Typography color="text.primary">Routines</Typography>
                        </Breadcrumbs>
                        <DataGridPremium
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            rows={RoutineActivity}
                            columns={routineColumns}
                            pagination
                            pageSizeOptions={[20, 50, 100, 500, 1000]}
                            disableRowSelectionOnClick
                            loading={isLoading}
                            checkboxSelectionVisibleOnly
                            checkboxSelection
                        />
                    </div>


                    <div className={classes.datatable}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                            <Typography color="text.primary">
                                UserActivities
                            </Typography>
                            <Typography color="text.primary">Meditations</Typography>
                        </Breadcrumbs>
                        <DataGridPremium
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            rows={MeditationActivity}
                            columns={meditationColumns}
                            pagination
                            pageSizeOptions={[20, 50, 100, 500, 1000]}
                            disableRowSelectionOnClick
                            loading={isLoading}
                            checkboxSelectionVisibleOnly
                            checkboxSelection
                        />
                    </div>

                    <div className={classes.datatable}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                            <Typography color="text.primary">
                                UserActivities
                            </Typography>
                            <Typography color="text.primary">Steps</Typography>
                        </Breadcrumbs>
                        <DataGridPremium
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            rows={StepsActivity}
                            getRowHeight={() => 'auto'}
                            getEstimatedRowHeight={() => 200}
                            columns={stepsColumns}
                            pagination
                            pageSizeOptions={[20, 50, 100, 500, 1000]}
                            disableRowSelectionOnClick
                            loading={isLoading}
                            checkboxSelectionVisibleOnly
                            checkboxSelection
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default UserAllActitivty
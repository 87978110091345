import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../Redeem.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Loader from "../../../../components/Loader/Loader";
import Button from "../../../../components/Button/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { FreeUserServices } from "../../../../services/redeem_services";
import { BASE_PATH_TO_ADD_FREE_USER_CONFIG, BASE_PATH_TO_UPDATE_FREE_USER_CONFIG } from "../../../../base_routes_paths/redeem_paths";

const FreeUser = () => {
    const navigate = useNavigate();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [Name, setName] = useState("");
    const [DailyPoints, setDailyPoints] = useState({});
    const [IsActive, setIsActive] = useState(true);
    const [IsAdsFree, setIsAdsFree] = useState(true);
    const [IsInterstitialAdsAllowed, setIsInterstitialAdsAllowed] = useState(true);
    const [IsReminders, setIsReminders] = useState(true);
    const [freeSuperpowers, setFreeSuperpowers] = useState({});
    const [ChallengeCategoryAllowed, setChallengeCategoryAllowed] = useState([]);
    const [Redemptions, setRedemptions] = useState({});
    const [UserGoalConfig, setUserGoalConfig] = useState({});
    const [UserStepsConfig, setUserStepsConfig] = useState({});
    const [ReferralConfig, setReferralConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [ClanConfig, setClanConfig] = useState({
        IsClanCreationAllowed: false,
        ClanCreationPoints: 0,
    });
    const [RoutinePlansConfig, setRoutinePlansConfig] = useState({
        IsRoutinePlansAllowed: false,
    });

    const { token } = AUTH_CONTEXT();

    const FetchApi = async () => {
        try {
            let response = await FreeUserServices.getFreeUserConfig(token, navigate);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(false);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(true);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchApi().then((result) => {
                setName(result.Name);
                setDailyPoints(result.DailyPoints);
                setRedemptions(result.Redemptions);
                setFreeSuperpowers(result.FreeSuperpowers);
                setIsActive(result.IsActive);
                setIsAdsFree(result.IsAdsFree);
                setIsReminders(result.IsReminders);
                setIsInterstitialAdsAllowed(result.IsInterstitialAdsAllowed);
                setChallengeCategoryAllowed(result.ChallengeCategoryAllowed);
                setUserGoalConfig(result.UserGoalConfig);
                setUserStepsConfig(result.UserStepsConfig);
                setReferralConfig(result.ReferralConfig);
                setClanConfig(result.ClanConfig);
                setRoutinePlansConfig(result.RoutinePlansConfig);
            });
        }
    }, []);

    return (
        <>
            {isLoading === true ? (
                <Loader />
            ) : (
                <div className={classes.crumbs}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Redeem</Typography>
                        <Typography color="text.primary">View Free User Config</Typography>
                    </Breadcrumbs>
                    <Card className={classes.input}>
                        <div className={classes.Main_div}>Free User Config</div>
                        <div className={classes.control}>
                            <label htmlFor="Name">Name :</label>
                            <input
                                disabled
                                type="text"
                                id="Name"
                                value={Name}
                                placeholder="Enter name"
                            />
                        </div>

                        <div className={classes.subfields}>
                            <h3>Redemptions</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsRedemptionLimit">
                                        {" "}
                                        IsRedemptionLimit :{" "}
                                    </label>
                                    <select
                                        disabled
                                        name="IsRedemptionLimit"
                                        id="IsRedemptionLimit"
                                        value={Redemptions.IsRedemptionLimit}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                {Redemptions.IsRedemptionLimit && (
                                    <div className={classes.control}>
                                        <label htmlFor="Amount">Amount : </label>
                                        <input
                                            type="number"
                                            name="Amount"
                                            disabled
                                            placeholder="Enter Amount"
                                            value={Redemptions.Amount}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>Free Superpowers</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsFreeSuperpower"> IsFreeSuperpower : </label>
                                    <select
                                        name="IsFreeSuperpower"
                                        id="IsFreeSuperpower"
                                        value={freeSuperpowers.IsFreeSuperpower}
                                        disabled
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="Amount">Amount : </label>
                                    <input
                                        type="number"
                                        name="Amount"
                                        disabled
                                        placeholder="Enter Amount"
                                        value={freeSuperpowers.Amount}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>User Goal Config</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsGoalProgressViewAllowed">
                                        IsGoalProgressViewAllowed :{" "}
                                    </label>
                                    <select
                                        name="IsGoalProgressViewAllowed"
                                        id="IsGoalProgressViewAllowed"
                                        disabled
                                        value={UserGoalConfig.IsGoalProgressViewAllowed}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>Clan Config</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsClanCreationAllowed">IsClanCreationAllowed: </label>
                                    <select
                                        disabled
                                        name="IsClanCreationAllowed"
                                        id="IsClanCreationAllowed"
                                        value={ClanConfig.IsClanCreationAllowed}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="ClanCreationPoints">Clan Creation Points: </label>
                                    <input
                                        type="number"
                                        disabled
                                        name="ClanCreationPoints"
                                        required
                                        placeholder="Enter Clan Creation Points"
                                        value={ClanConfig.ClanCreationPoints}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>Routine Plans Config</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsRoutinePlansAllowed">IsRoutinePlansAllowed: </label>
                                    <select
                                        name="IsRoutinePlansAllowed"
                                        disabled
                                        id="IsRoutinePlansAllowed"
                                        value={RoutinePlansConfig.IsRoutinePlansAllowed}
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>User Steps Config</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsDailyPoints"> IsDailyPoints : </label>
                                    <select
                                        name="IsDailyPoints"
                                        id="IsDailyPoints"
                                        value={UserStepsConfig.IsDailyPoints}
                                        disabled
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="Target">Target : </label>
                                    <input
                                        name="Target"
                                        disabled
                                        value={UserStepsConfig.Target}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>Daily Points</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.selectbox}>
                                    <label htmlFor="IsDailyPoints"> IsDailyPoints : </label>
                                    <select
                                        name="IsDailyPoints"
                                        id="IsDailyPoints"
                                        value={DailyPoints.IsDailyPoints}
                                        disabled
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                {DailyPoints.IsDailyPoints && (
                                    <>
                                        <div className={classes.control}>
                                            <label htmlFor="MaxReward">Max Reward : </label>
                                            <input
                                                type="number"
                                                name="MaxReward"
                                                disabled
                                                placeholder="Enter Max Reward"
                                                value={DailyPoints.MaxReward}
                                            />
                                        </div>
                                        <div className={classes.control}>
                                            <label htmlFor="MinReward">Min Reward : </label>
                                            <input
                                                type="number"
                                                name="MinReward"
                                                disabled
                                                placeholder="Enter Min Reward"
                                                value={DailyPoints.MinReward}
                                            />
                                        </div>
                                        <div className={classes.selectbox}>
                                            <label htmlFor="RewardType">RewardType : </label>
                                            <select
                                                name="RewardType"
                                                value={DailyPoints.RewardType}
                                                disabled
                                            >
                                                <option value={1}>1.Constant</option>
                                                <option value={2}>2.Incremental</option>
                                            </select>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={classes.subfields}>
                            <h3>Referral Config</h3>
                            <div className={classes.subfieldsControl}>
                                <div className={classes.control}>
                                    <label htmlFor="PointForReferralUser">
                                        Point For Referral User :{" "}
                                    </label>
                                    <input
                                        name="PointForReferralUser"
                                        disabled
                                        placeholder="Enter Point For Referral User"
                                        value={ReferralConfig.PointForReferralUser}
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="PointForReferredUser">
                                        Point For Referred User :{" "}
                                    </label>
                                    <input
                                        name="PointForReferredUser"
                                        disabled
                                        value={ReferralConfig.PointForReferredUser}
                                    />
                                </div>
                                <div className={classes.control}>
                                    <label htmlFor="ReferralLimit">Referral Limit : </label>
                                    <input
                                        name="ReferralLimit"
                                        disabled
                                        value={ReferralConfig.ReferralLimit}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.subfields} style={{ marginTop: "20px" }}>
                            <h3>ChallengeCategoryAllowed</h3>
                            <div className={classes.subfieldControl}>
                                {ChallengeCategoryAllowed.map((categoryAllowed, i) => (
                                    <div
                                        className={classes.subfield}
                                        style={{ marginTop: "20px" }}
                                    >
                                        <div key={i}>
                                            <div className={classes.control}>
                                                <label htmlFor={`CategoryID_${i}`}>CategoryID : </label>
                                                <input
                                                    type="number"
                                                    id={`CategoryID_${i}`}
                                                    name={`CategoryID_${i}`}
                                                    placeholder="Enter CategoryID"
                                                    value={categoryAllowed.CategoryID}
                                                    disabled
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom:
                                                        !categoryAllowed.IsUnlimitedAttemptsAllowed
                                                            ? "0px"
                                                            : "1rem",
                                                }}
                                            >
                                                <label htmlFor={`IsUnlimitedAttemptsAllowed_${i}`}>
                                                    IsUnlimitedAttemptsAllowed :{" "}
                                                </label>
                                                <select
                                                    name="IsUnlimitedAttemptsAllowed"
                                                    id="IsUnlimitedAttemptsAllowed"
                                                    value={categoryAllowed.IsUnlimitedAttemptsAllowed}
                                                    disabled
                                                >
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                                </select>
                                            </div>
                                            <div className={classes.control}>
                                                <label htmlFor={`AttemptsAllowed_${i}`}>
                                                    AttemptsAllowed :{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    id={`AttemptsAllowed_${i}`}
                                                    disabled
                                                    name={`AttemptsAllowed_${i}`}
                                                    placeholder="Enter AttemptsAllowed"
                                                    value={categoryAllowed.AttemptsAllowed}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsInterstitialAdsAllowed">IsInterstitialAdsAllowed : </label>
                            <select
                                name="IsInterstitialAdsAllowed"
                                id="IsInterstitialAdsAllowed"
                                disabled
                                value={IsInterstitialAdsAllowed}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsActive">IsActive : </label>
                            <select name="IsActive" id="IsActive" value={IsActive} disabled>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox}>
                            <label htmlFor="IsAdsFree">IsAdsFree : </label>
                            <select
                                name="IsAdsFree"
                                id="IsAdsFree"
                                disabled
                                value={IsAdsFree}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div className={classes.selectbox} style={{ marginBottom: "10px" }}>
                            <label htmlFor="IsReminders">IsReminders : </label>
                            <select
                                name="IsReminders"
                                id="IsReminders"
                                disabled
                                value={IsReminders}
                            >
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: "1rem" }}>
                                <Button

                                    onClick={() => {
                                        navigate(BASE_PATH_TO_UPDATE_FREE_USER_CONFIG);
                                    }}
                                >
                                    Edit Free User Config
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={() => {
                                        navigate(BASE_PATH_TO_ADD_FREE_USER_CONFIG);
                                    }}
                                >
                                    Add New Free User Config
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};

export default FreeUser;

import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Auth.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { SpecialReferraServices } from "../../../../services/auth_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import { BASE_PATH_TO_SPECIAL_REFERRAL_LIST } from "../../../../base_routes_paths/auth_paths";

const UpdateSpecialReferral = () => {
    const params = useParams();
    const navigate = useNavigate();
    const ReferralLimitRef = useRef();
    const [referralCode, setReferralCode] = useState("");
    const [PointForReferralUser, setPointForReferralUser] = useState("");
    const [PointForReferredUser, setPointForReferredUser] = useState("");
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);

    const FetchResponse = async () => {
        try {
            let response = await SpecialReferraServices.getAllSpecialReferral(token, navigate, params.ID)
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            toast.success("Data Fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        const FetchData = async () => {
            try {
                let result = await FetchResponse();
                ReferralLimitRef.current.value = result.ReferralLimit;
                setReferralCode(result.ReferralCode)
                setPointForReferralUser(result.PointForReferralUser)
                setPointForReferredUser(result.PointForReferredUser)
            } catch (error) {
                console.log(error.message);
            }
        };

        if (params.ID) {
            FetchData();
        }
    }, []);

    const SubmitHandler = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            ReferralLimit: +ReferralLimitRef.current.value,
            ReferralCode: referralCode,
            PointForReferralUser: +PointForReferralUser,
            PointForReferredUser: +PointForReferredUser
        }

        const FetchResponseSetReferrelConfig = async () => {
            try {
                let response = await await SpecialReferraServices.updateSpecialReferral(token, navigate, Data);
                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                navigate(BASE_PATH_TO_SPECIAL_REFERRAL_LIST);
                toast.success("Data added successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } catch (e) {
                setIsLoading(false);
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        FetchResponseSetReferrelConfig();
    };

    return (
        <div className={classes.crumbs}>
            <Breadcrumbs aria-label="breadcrumb" >
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Auth
                </Typography>
                <Typography color="text.primary">Update Special Referral</Typography>
            </Breadcrumbs>
            <Card className={classes.input}>
                <div className={classes.Main_div}>
                    Update Special Referral Configuration
                </div>
                <form onSubmit={SubmitHandler}>
                    <div className={classes.control}>
                        <div className={classes.control}>
                            <label htmlFor="ReferralCode">Referral Code</label>
                            <input
                                type="text"
                                id="ReferralCode"
                                disabled
                                value={referralCode}
                                onChange={(e) => { setReferralCode(e.target.value) }}
                                placeholder="Enter ReferralCode"
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="ReferralLimit">Referral Limit</label>
                            <input
                                type="number"
                                id="ReferralLimit"
                                required
                                ref={ReferralLimitRef}
                                placeholder="Enter Referral Limit for a User"
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="PointForReferralUser">Point For Referral User</label>
                            <input
                                type="text"
                                id="PointForReferralUser"
                                value={isNaN(PointForReferralUser) ? '' : PointForReferralUser}
                                onChange={(e) => setPointForReferralUser(parseInt(e.target.value))}
                                placeholder="Enter Points for Referral User"
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="PointForReferredUser">Point For Referred User</label>
                            <input
                                type="text"
                                id="PointForReferredUser"
                                value={isNaN(PointForReferredUser) ? '' : PointForReferredUser}
                                onChange={(e) => setPointForReferredUser(parseInt(e.target.value))}
                                placeholder="Enter Points for Referred Code"
                            />
                        </div>
                    </div>
                    {!isLoading && <Button type="submit">Update</Button>}
                    {isLoading && <p color="white">Sending request...</p>}
                </form>
            </Card>
        </div>
    );
};

export default UpdateSpecialReferral;

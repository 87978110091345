import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Point.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../store";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import { PointServices } from "../../../../services/point_services";
import { BASE_PATH_TO_POINT_CONFIG_LIST } from "../../../../base_routes_paths/points_paths";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import Loader from "../../../../components/Loader/Loader";

const UpdatePointConfig = () => {
    const navigate = useNavigate();
    const { token } = AUTH_CONTEXT();
    const params = useParams();
    const [defaultPoint, setDefaultPoint] = useState("");
    const [isPromotional, setIsPromotional] = useState(true);
    const [isDatePromotional, setIsDatePromotional] = useState(true);
    const [isNoOfPPLPromotional, setIsNoOfPPLPromotional] = useState(true);
    const [promotionEndDate, setPromotionEndDate] = useState("");
    const [promotionStartDate, setPromotionStartDate] = useState("");
    const [noOfPPL, setNoOfPPL] = useState("");
    const [promotionalPoint, setPromotionalPoint] = useState("");
    const [priority, setPriority] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [IsLoaderLoading, setIsLoaderLoading] = useState(false);

    const SubmitHandler = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const Data = {
            ConfigID: params.ID,
            Data: {
                DefaultPoint: defaultPoint,
                IsPromotional: isPromotional,
                IsDatePromotional: isDatePromotional,
                IsNoOfPPLPromotional: isNoOfPPLPromotional,
                PromotionEndDate: promotionEndDate,
                PromotionStartDate: promotionStartDate,
                NoofPPL: noOfPPL,
                PromotionalPoint: promotionalPoint,
                Priority: priority
            }
        }

        try {
            const response = await PointServices.updatePointConfig(
                token,
                navigate,
                Data
            );

            if (response.status === 207) {
                toast.error(response.error, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setIsLoading(false);
                navigate(BASE_PATH_TO_POINT_CONFIG_LIST);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            alert(error.message);
        }
    };

    const FetchResponse = async () => {
        try {
            let response = await PointServices.getAllPointConfig(token, navigate, params.ID);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsDataFetched(true);
            setIsLoaderLoading(false);
            toast.success("Data added successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        async function FetchData() {
            try {
                if (!isDataFetched) {
                    let result = await FetchResponse();
                    console.log(result.NonPromotionalConfig)
                    if (result.OngoingPromotion.length > 0) {
                        const startDate = new Date(result.OngoingPromotion[0].PromotionStartDate).toISOString().split('T')[0];
                        const endDate = new Date(result.OngoingPromotion[0].PromotionEndDate).toISOString().split('T')[0];
                        setPromotionStartDate(startDate);
                        setPromotionEndDate(endDate);
                        setDefaultPoint(result.OngoingPromotion[0].DefaultPoint)
                        setIsPromotional(result.OngoingPromotion[0].IsPromotional)
                        setIsDatePromotional(result.OngoingPromotion[0].IsDatePromotional)
                        setIsNoOfPPLPromotional(result.OngoingPromotion[0].IsNoOfPPLPromotional)
                        setNoOfPPL(result.OngoingPromotion[0].NoofPPL)
                        setPromotionalPoint(result.OngoingPromotion[0].PromotionalPoint)
                        setPriority(result.OngoingPromotion[0].Priority)
                    } else if (result.UpcomingPromotion.length > 0) {
                        const startDate = new Date(result.UpcomingPromotion[0].PromotionStartDate).toISOString().split('T')[0];
                        const endDate = new Date(result.UpcomingPromotion[0].PromotionEndDate).toISOString().split('T')[0];
                        setPromotionStartDate(startDate);
                        setPromotionEndDate(endDate);
                        setDefaultPoint(result.UpcomingPromotion[0].DefaultPoint)
                        setIsPromotional(result.UpcomingPromotion[0].IsPromotional)
                        setIsDatePromotional(result.UpcomingPromotion[0].IsDatePromotional)
                        setIsNoOfPPLPromotional(result.UpcomingPromotion[0].IsNoOfPPLPromotional)
                        setNoOfPPL(result.UpcomingPromotion[0].NoofPPL)
                        setPromotionalPoint(result.UpcomingPromotion[0].PromotionalPoint)
                        setPriority(result.UpcomingPromotion[0].Priority)
                    }
                    else if (result.NonPromotionalConfig.length > 0) {
                        const startDate = new Date(result.NonPromotionalConfig[0].PromotionStartDate).toISOString().split('T')[0];
                        const endDate = new Date(result.NonPromotionalConfig[0].PromotionEndDate).toISOString().split('T')[0];
                        setPromotionStartDate(startDate);
                        setPromotionEndDate(endDate);
                        setDefaultPoint(result.NonPromotionalConfig[0].DefaultPoint)
                        setIsPromotional(result.NonPromotionalConfig[0].IsPromotional)
                        setIsDatePromotional(result.NonPromotionalConfig[0].IsDatePromotional)
                        setIsNoOfPPLPromotional(result.NonPromotionalConfig[0].IsNoOfPPLPromotional)
                        setNoOfPPL(result.NonPromotionalConfig[0].NoofPPL)
                        setPromotionalPoint(result.NonPromotionalConfig[0].PromotionalPoint)
                        setPriority(result.NonPromotionalConfig[0].Priority)
                    }
                }
            } catch (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

        if (params.ID) {
            FetchData();
            setIsLoaderLoading(true);
        }
    }, []);


    return (
        IsLoaderLoading ? <Loader /> :
            <div className={classes.crumbs}>
                <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">
                        Points
                    </Typography>
                    <Typography color="text.primary">Update Point Configration</Typography>
                </Breadcrumbs>
                <Card className={classes.input}>
                    <div className={classes.Main_div}>Update Point Configration</div>
                    <form onSubmit={SubmitHandler}>
                        <div className={classes.control}>
                            <label htmlFor="DefaultPoint">Default point</label>
                            <input
                                type="number"
                                id="DefaultPoint"
                                required
                                value={defaultPoint}
                                onChange={(e) => setDefaultPoint(e.target.value)}
                                placeholder="Enter default points"
                            />
                        </div>
                        <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                            <label htmlFor="IsPromotional">IsPromotional</label>
                            <select
                                name="IsPromotional"
                                id="IsPromotional"
                                value={isPromotional}
                                onChange={(e) => setIsPromotional(e.target.value === "true")}
                                required
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                            <label htmlFor="IsDatePromotional">IsDatePromotional</label>
                            <select
                                name="IsDatePromotional"
                                id="IsDatePromotional"
                                value={isDatePromotional}
                                onChange={(e) => setIsDatePromotional(e.target.value === "true")}
                                required
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className={classes.selectbox} style={{ marginBottom: "2rem" }}>
                            <label htmlFor="IsNoOfPPLPromotional">IsNoOfPPLPromotional</label>
                            <select
                                name="IsNoOfPPLPromotional"
                                id="IsNoOfPPLPromotional"
                                value={isNoOfPPLPromotional}
                                onChange={(e) => setIsNoOfPPLPromotional(e.target.value === "true")}
                                required
                            >
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="PromotionEndDate">PromotionEndDate : </label>
                            <input
                                id="PromotionEndDate"
                                type="date"
                                placeholder="Enter ending date"
                                value={promotionEndDate}
                                onChange={(e) => setPromotionEndDate(e.target.value)}
                            />
                        </div>
                        <div className={classes.selectbox}>
                            <label htmlFor="PromotionStartDate">PromotionStartDate : </label>
                            <input
                                id="PromotionStartDate"
                                type="date"
                                placeholder="Enter start date"
                                value={promotionStartDate}
                                onChange={(e) => setPromotionStartDate(e.target.value)}
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="NoofPPL">Number of People</label>
                            <input
                                type="number"
                                id="NoofPPL"
                                required
                                value={noOfPPL}
                                onChange={(e) => setNoOfPPL(e.target.value)}
                                placeholder="Enter Number of people"
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="PromotionalPoint">Promotional point</label>
                            <input
                                type="number"
                                id="PromotionalPoint"
                                required
                                value={promotionalPoint}
                                onChange={(e) => setPromotionalPoint(e.target.value)}
                                placeholder="Enter the point for promotional"
                            />
                        </div>
                        <div className={classes.control}>
                            <label htmlFor="Priority">Priority</label>
                            <input
                                type="number"
                                id="Priority"
                                required
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                                placeholder="Enter Priority"
                            />
                        </div>
                        {!isLoading && <Button type="submit">Update</Button>}
                        {isLoading && <p color="white">Sending request...</p>}
                    </form>
                </Card>
            </div>
    );
};

export default UpdatePointConfig;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../Data.module.css";
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import AUTH_CONTEXT from "../../../../store";
import { LeaderboardService } from "../../../../services/challenge_services";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME, BASE_PATH_TO_USER_DETAILS, BASE_PATH_TO_USER_SNAPSHOTS } from "../../../../base_routes_paths/data_paths";

const userColumns = [
    {
        field: "Rank",
        headerName: "Rank",
        width: 80
    },
    {
        field: "id",
        headerName: "User ID",
        width: 230,
        renderCell: (params) => {
            return (
                <a target="_blank" rel="noreferrer" href={`${BASE_PATH_TO_USER_DETAILS}/` + params.row.id}>{params.row.id}</a>
            );
        },
    },
    {
        field: "ProfileImage",
        headerName: "Profile",
        width: 80,
        renderCell: (params) => {
            return (
                <div className={classes.cellWithImg}>
                    <img
                        className={classes.cellImg}
                        src={
                            params.row.ProfileImage ||
                            "https://www.areno.in/images/logo.png"
                        }
                        alt=""
                    />
                </div>
            );
        },
    },
    {
        field: "Name",
        headerName: "Name",
        width: 130,
    },
    {
        field: "CountryCode",
        headerName: "Country Code",
        width: 120,
    },
    {
        field: "Mobile_Number",
        headerName: "Mobile Number",
        width: 120,
    },
    {
        field: "Age",
        headerName: "Age",
        width: 60,
    },
    {
        field: "Role",
        headerName: "Role",
        width: 80,
    },
    {
        field: "HeightFt",
        headerName: "HeightFt",
        width: 80,
    },
    {
        field: "HeightIn",
        headerName: "HeightIn",
        width: 80,
    },
    {
        field: "ChallengeAtmpt",
        headerName: "ChallengeAtmpt",
        width: 150,
    },
    {
        field: "LastChallengeAtmptTime",
        headerName: "LastChallengeAtmptTime",
        width: 180,
    },
    {
        field: "TotalTry",
        headerName: "TotalTry",
        width: 150,
    },
    {
        field: "Wt_kg",
        headerName: "WeightKgs",
        width: 80,
    },
    {
        field: "IsSubscribedByCorporate",
        headerName: "Corporate Subscribed",
        width: 150,
    },
    {
        field: "IsSubscribed",
        headerName: "Subscribed",
        width: 100,
    },
    {
        field: "SubscriptionStartsOn",
        headerName: "Subscription Start Date",
        width: 180,
    },
    {
        field: "SubscriptionEndsOn",
        headerName: "Subscription End Date",
        width: 180,
    },
    {
        field: "YearOfBirth",
        headerName: "Year Of Birth",
        width: 120,
    },
    {
        field: "Gender",
        headerName: "Gender",
        width: 80,
    },
    {
        field: "CorporateID",
        headerName: "CorporateID",
        width: 120,
    },
    {
        field: "TeamID",
        headerName: "TeamID",
        width: 120,
    },
    {
        field: "Location",
        headerName: "Location",
        width: 160,
    },
    {
        field: "City",
        headerName: "City",
        width: 160,
    },
    {
        field: "DateOfRegistration",
        headerName: "Registration date",
        width: 160,
    },
    {
        field: "TimeOfRegistration",
        headerName: "Registration time",
        width: 160,
    },
    {
        field: "IsRatedApp",
        headerName: "Has Rated",
        width: 160,
    },
    {
        field: "RatingGiven",
        headerName: "Ratings given",
        width: 160,
        renderCell: (params) => {
            const { RatingGiven } = params.row;
            if (RatingGiven === "") {
                return <div>N/A</div>;
            } else {
                return <div>{RatingGiven}</div>;
            }
        },
    },
    {
        field: "FeedBack",
        headerName: "FeedBack",
        width: 250,
    },
    {
        field: "TimeOffSet",
        headerName: "Time Off Set",
        width: 160,
    },
    {
        field: "ActivityStatus",
        headerName: "Activity Status",
        width: 110,
        renderCell: (params) => {
            const ActivityStatus = params.row.ActivityStatus;
            return <>{ActivityStatus === -1 ? "N/A" : ActivityStatus}</>
        },
    },
    {
        field: "GetToKnowFrom",
        headerName: "Get To Know From",
        width: 140,
        renderCell: (params) => {
            const GetToKnowFrom = params.row.GetToKnowFrom;
            return <>{GetToKnowFrom === -1 ? "N/A" : GetToKnowFrom}</>
        },
    },
    {
        field: "ReferralCode",
        headerName: "ReferralCode",
        width: 120,
    },
    {
        field: "ReferedBy",
        headerName: "ReferedBy",
        width: 120,
    },
    {
        field: "BuildNumber",
        headerName: "BuildNumber",
        width: 120,
    },
    {
        field: "OSVersion",
        headerName: "OS Version",
        width: 100,
    },
    {
        field: "AppVersion",
        headerName: "App Version",
        width: 120,
    },
    {
        field: "DeviceID",
        headerName: "Device ID",
        width: 180,
    },
    {
        field: "DeviceType",
        headerName: "Device Type",
        width: 140,
    },
    {
        field: "IsActive",
        headerName: "IsActive",
        width: 140,
    },
    {
        field: "IsSubscribed",
        headerName: "IsSubscribed",
        width: 140,
    },
];

const Leaderboard = () => {
    const [isLoaderLoading, setIsLoaderLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [model, setModel] = useState([]);
    const params = useParams();
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_LeaderboardList`;
    const navigate = useNavigate();

    useEffect(() => {
        const functionCall = async () => {
            try {
                let response = await LeaderboardService.getLeaderboardData(token, navigate, params.ID);

                if (response.status === 207) {
                    return toast
                        .error(response.error, {
                            position: "bottom-left",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        })
                }
                let result = response.data.data;
                let input = result.map((result) => ({
                    id: result._id,
                    ...result,
                }));
                setIsDataFetched(true);
                setData(input);
                toast.success("Data fetched successfully!!", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsLoaderLoading(false);
            } catch (e) {
                toast.error(e.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };

        if (params.ID) {
            setIsLoaderLoading(true);
            functionCall();
        } else {
            return;
        }
    }, [params.ID, token]);

    useEffect(() => {
        const storedModel = sessionStorage.getItem("LeaderboardChecked");
        if (storedModel) {
            const parsedModel = JSON.parse(storedModel);
            setModel(parsedModel);
        }
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{ fileName: EXPORT_FILENAME }}
                    excelOptions={{ fileName: EXPORT_FILENAME }}
                />
            </GridToolbarContainer>
        );
    }

    const actionColumn = [
        {
            field: "Snapshots",
            headerName: "Snapshots",
            width: 200,
            renderCell: (param) => {
                return (
                    <div className={classes.cellAction}>
                        <a target="_blank" rel="noreferrer" href={BASE_PATH_TO_USER_SNAPSHOTS + "/" + param.row.id + "/" + params.ID}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>View</div>
                        </a>
                    </div >
                );
            },
        },
    ];


    return (
        <div>
            {isLoaderLoading === true ? (
                <Loader />
            ) : (
                <>
                    {isDataFetched && (
                        <div className={classes.datatable}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                                    Dashboard
                                </Link>
                                <Typography color="text.primary">
                                    Data
                                </Typography>
                                <Typography color="text.primary">Leaderboard</Typography>
                            </Breadcrumbs>
                            <DataGridPremium
                                checkboxSelection
                                rows={data}
                                columns={userColumns.concat(actionColumn)}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                }}
                                pagination
                                onRowSelectionModelChange={(e) => {
                                    const selectedIDs = new Set(e);
                                    sessionStorage.setItem(
                                        "LeaderboardChecked",
                                        JSON.stringify(Array.from(selectedIDs))
                                    );
                                    setModel(Array.from(selectedIDs));
                                }}
                                disableRowSelectionOnClick
                                checkboxSelectionVisibleOnly
                                rowSelectionModel={model}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Leaderboard;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../../../Data.module.css";
import { toast } from "react-toastify";
import AUTH_CONTEXT from "../../../../../store";
import ARENO_API_ENDPOINT from "../../../../../api";
import IndianTimeFormat, { StatusCode } from "../../../../../utils";
import Loader from "../../../../../components/Loader/Loader";
import Card from "../../../../../components/Card/Card";

const IOSUserSubscription = () => {
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [UserID, setUserID] = useState("");
    const [SubscriptionID, setSubscriptionID] = useState("");
    const [SubscriptionName, setSubscriptionName] = useState("");
    const [SubscriptionStatus, setSubscriptionStatus] = useState();
    const [productId, setProductId] = useState("");
    const [price, setPrice] = useState("");
    const [currency, setCurrency] = useState("");
    const [storefront, setStorefront] = useState("");
    const [storefrontId, setStorefrontId] = useState("");
    const [inAppOwnershipType, setInAppOwnershipType] = useState("");
    const [transactionReason, setTransactionReason] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [originalTransactionId, setOriginalTransactionId] = useState("");
    const [webOrderLineItemId, setWebOrderLineItemId] = useState("");
    const [subscriptionGroupIdentifier, setSubscriptionGroupIdentifier] = useState("");
    const [signedDate, setSignedDate] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("");
    const [originalPurchaseDate, setOriginalPurchaseDate] = useState("");
    const [expiresDate, setExpiresDate] = useState("");
    const [type, setType] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [IsSubscribedByCorporate, setIsSubscribedByCorporate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const navigate = useNavigate();

    const FetchResponseGetAllUserDetails = async () => {
        try {
            let response = await fetch(
                `${ARENO_API_ENDPOINT}/redeem/AdminGetIOSUserSubscriptionByID`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        _id: params.ID,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            setIsLoading(false);
            StatusCode(response, navigate);
            response = await response.json();
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            console.log(e.message);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (params.ID) {
            if (!isDataFetched) {
                FetchResponseGetAllUserDetails()
                    .then((result) => {
                        setUserID(result.UserID)
                        setSubscriptionID(result.SubscriptionID)
                        setSubscriptionName(result.SubscriptionName)
                        setQuantity(result.quantity)
                        setProductId(result.productId)
                        setSubscriptionStatus(result.SubscriptionStatus)
                        setPrice(result.price)
                        setCurrency(result.currency)
                        setStorefront(result.storefront)
                        setStorefrontId(result.storefrontId)
                        setInAppOwnershipType(result.inAppOwnershipType)
                        setTransactionReason(result.transactionReason)
                        setType(result.type)
                        setIsSubscribedByCorporate(result.IsSubscribedByCorporate)
                        setTransactionId(result.transactionId)
                        setOriginalTransactionId(result.originalTransactionId)
                        setWebOrderLineItemId(result.webOrderLineItemId)
                        setSubscriptionGroupIdentifier(result.subscriptionGroupIdentifier)
                        setSignedDate(result.signedDate)
                        setPurchaseDate(result.purchaseDate)
                        setOriginalPurchaseDate(result.originalPurchaseDate)
                        setPurchaseDate(result.originalPurchaseDate)
                        setExpiresDate(result.expiresDate)
                    })
                    .catch((err) => console.log(err));
            }
        } else {
            toast.error("ID missing", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, []);

    return (
        !isLoading ? <Loader /> :
            <Card className={classes.input}>
                <div className={classes.Main_div}>IOS User Subscription Details</div>
                <div className={classes.control}>
                    <label htmlFor="UserID">UserID :</label>
                    <input
                        disabled
                        type="text"
                        id="UserID"
                        value={UserID}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="SubscriptionID">SubscriptionID :</label>
                    <input
                        disabled
                        id="SubscriptionID"
                        value={SubscriptionID}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="SubscriptionName">SubscriptionName :</label>
                    <input
                        disabled
                        id="SubscriptionName"
                        value={SubscriptionName}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="SubscriptionStatus">SubscriptionStatus :</label>
                    <input
                        disabled
                        id="SubscriptionStatus"
                        value={
                            SubscriptionStatus === 4
                                ? `Purchased (${SubscriptionStatus})`
                                : SubscriptionStatus === 2
                                    ? `Renewed(${SubscriptionStatus})`
                                    : SubscriptionStatus === 13
                                        ? `Expired(${SubscriptionStatus})`
                                        : SubscriptionStatus === 42
                                            ? `Renewed(${SubscriptionStatus})`
                                            : ``
                        }
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="price">Price :</label>
                    <input
                        disabled
                        id="price"
                        value={price}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="currency">Currency :</label>
                    <input
                        disabled
                        id="currency"
                        value={currency}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="storefront">Store Front :</label>
                    <input
                        disabled
                        id="storefront"
                        value={storefront}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="storefrontId">Store Front ID:</label>
                    <input
                        disabled
                        id="storefrontId"
                        value={storefrontId}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="inAppOwnershipType">inAppOwnershipType:</label>
                    <input
                        disabled
                        id="inAppOwnershipType"
                        value={inAppOwnershipType}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="transactionReason">Transaction Reason:</label>
                    <input
                        disabled
                        id="transactionReason"
                        value={transactionReason}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="type">Type :</label>
                    <input
                        disabled
                        id="type"
                        value={type}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="transactionId">Transaction ID :</label>
                    <input
                        disabled
                        id="transactionId"
                        value={transactionId}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="originalTransactionId">Original Transaction ID :</label>
                    <input
                        disabled
                        id="originalTransactionId"
                        value={originalTransactionId}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="webOrderLineItemId">Web Order Line Item ID :</label>
                    <input
                        disabled
                        id="webOrderLineItemId"
                        value={webOrderLineItemId}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="subscriptionGroupIdentifier">Subscription Group Identifier :</label>
                    <input
                        disabled
                        id="subscriptionGroupIdentifier"
                        value={subscriptionGroupIdentifier}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="signedDate">Signed Date :</label>
                    <input
                        disabled
                        id="signedDate"
                        value={IndianTimeFormat(signedDate)}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="purchaseDate">Purchase Date :</label>
                    <input
                        disabled
                        id="purchaseDate"
                        value={IndianTimeFormat(purchaseDate)}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="originalPurchaseDate">Original Purchase Date :</label>
                    <input
                        disabled
                        id="originalPurchaseDate"
                        value={IndianTimeFormat(originalPurchaseDate)}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="expiresDate">Expires Date :</label>
                    <input
                        disabled
                        id="expiresDate"
                        value={IndianTimeFormat(expiresDate)}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="quantity">Quantity :</label>
                    <input
                        disabled
                        id="quantity"
                        value={quantity}
                    />
                </div>

                <div className={classes.control}>
                    <label htmlFor="productId">ProductId :</label>
                    <input
                        disabled
                        type="text"
                        id="productId"

                        value={productId}
                        placeholder="Enter productId"
                    />
                </div>


                <div className={classes.selectbox} style={{ margin: 0 }} >
                    <label htmlFor="IsSubscribedByCorporate">IsSubscribedByCorporate : </label>
                    <div style={{ marginLeft: "5px", marginTop: ".8rem" }}>
                        {IsSubscribedByCorporate === true ? "Yes" : "No"}
                    </div>
                </div>
            </Card>
    );
};

export default IOSUserSubscription;

import { useState, useEffect } from "react";
import classes from "../../Data.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import Card from "../../../../components/Card/Card";
import { OnboardinScreenRecommendationsServices } from "../../../../services/auth_services";

const UserOSRecommendations = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { token } = AUTH_CONTEXT();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [IsApplicableForRewards, setIsApplicableForRewards] = useState(false);
    const [Points, setPoints] = useState(0);
    const [RecommendationData, setRecommendationData] = useState([]);

    const FetchResponse = async () => {
        try {
            let response = await OnboardinScreenRecommendationsServices.getUsersOnboardinScreenRecommendationsByID(token, navigate, params.ID);

            setIsLoading(false);
            if (response.status === 207) {
                return toast
                    .error(response.error, {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    })
            }
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    setPoints(result.Points);
                    setIsApplicableForRewards(result.IsApplicableForRewards);
                    setRecommendationData(result.RecommendationData)
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        !isLoading ?
            <Loader />
            :
            <Card className={classes.input}>
                <div className={classes.Main_div}>User Onboarding Screen Recommendations</div>

                <div className={classes.control}>
                    <label htmlFor="Points">Points :</label>
                    <input
                        type="text"
                        id="Points"
                        value={Points}
                        disabled
                    />
                </div>

                <div className={classes.subfields}>
                    <h3>RecommendationData</h3>
                    {RecommendationData && RecommendationData?.map((input, index) => {
                        return (
                            <div key={index} className={classes.subfieldsControl} style={{ marginTop: "2rem" }}>
                                <div className={classes.subfields}>
                                    <div className={classes.selectbox}>
                                        <label htmlFor="CategoryID">Category ID : </label>
                                        <input
                                            value={input.CategoryID}
                                            disabled
                                        />
                                    </div>
                                    <div className={classes.selectbox}>
                                        <label htmlFor="CategoryName">Category Name : </label>
                                        <input
                                            value={input.CategoryName}
                                            disabled
                                        />
                                    </div>
                                    <div className={classes.subfields}>
                                        <h3>Links</h3>
                                        {input.Links &&
                                            input.Links?.map((level, index) => (
                                                <div key={index}>
                                                    <div className={classes.control}>
                                                        <label style={{ width: "500px" }}>
                                                            Key :
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Key"
                                                            value={level.Key}
                                                        />
                                                    </div>
                                                    <div className={classes.control}>
                                                        <label style={{ width: "500px" }}>
                                                            Value :
                                                        </label>
                                                        <input
                                                            disabled
                                                            placeholder="Value"
                                                            value={level.Value}
                                                        />
                                                        {/* <ContentCopyIcon
                                                        sx={{ marginRight: "10px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(level.Value);
                                                            toast.success("Copied URL!!");
                                                        }}
                                                        titleAccess="Copy"
                                                    /> */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className={classes.selectbox} style={{ marginBottom: "1rem" }}>
                    <label htmlFor="IsApplicableForRewards">IsApplicableForRewards : </label>
                    <select
                        name="IsApplicableForRewards"
                        id="IsApplicableForRewards"
                        value={IsApplicableForRewards}
                        disabled
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
            </Card>
    );
};

export default UserOSRecommendations;

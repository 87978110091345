export const BASE_PATH_TO_POINT = "/point";

// ==============================|| THRESHOLD POINT CONFIG ||============================== //

export const BASE_PATH_TO_ADD_THRESHOLD_POINT = `${BASE_PATH_TO_POINT}/add-threshold-config`
export const BASE_PATH_TO_UPDATE_THRESHOLD_POINT = `${BASE_PATH_TO_POINT}/update-threshold-config`
export const BASE_PATH_TO_THRESHOLD_POINT = `${BASE_PATH_TO_POINT}/threshold-config`

// ==============================|| ADD USER POINTS ||============================== //

export const BASE_PATH_TO_ADD_USER_POINTS = `${BASE_PATH_TO_POINT}/add-user-points`

// ==============================|| POINT CONFIG ||============================== //

export const BASE_PATH_TO_POINT_CONFIG_LIST = `${BASE_PATH_TO_POINT}/point-config-list`
export const BASE_PATH_TO_NEW_POINT_CONFIG = `${BASE_PATH_TO_POINT}/new-point-config`
export const BASE_PATH_TO_UPDATE_POINT_CONFIG = `${BASE_PATH_TO_POINT}/new-point-config`
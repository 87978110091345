import { useState, useEffect } from "react";
import {
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import classes from "../../Challenge.module.css";
import AUTH_CONTEXT from "../../../../store";
import Loader from "../../../../components/Loader/Loader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { BASE_PATH_TO_ADD_MEDITATIONS, BASE_PATH_TO_UPDATE_MEDITATIONS } from "../../../../base_routes_paths/challenge_paths";
import CustomToolbar from "../../../../components/toolbar";
import { MeditationServices } from "../../../../services/challenge_services";
import { Box, Breadcrumbs, CircularProgress, Link, Typography } from "@mui/material";
import { BASE_PATH_TO_HOME } from "../../../../base_routes_paths/data_paths";
import ARENO_API_ENDPOINT from "../../../../api";
import { StatusCode } from "../../../../utils";

const MeditationsList = () => {
    const { token, fileName } = AUTH_CONTEXT();
    const EXPORT_FILENAME = `${fileName}_CategoryIDsList`;
    const [Ratings, setRatings] = useState(false);
    const [RatingsValue, setRatingsValue] = useState({});

    const fetchRatings = async (id) => {
        setRatings((prevPointsState) => ({
            ...prevPointsState,
            [id]: true,
        }));

        try {
            let response = await fetch(`${ARENO_API_ENDPOINT}/auth/AdminGetFeedbackTotalRating`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ FeedbackID: id }),
            });
            StatusCode(response, navigate);
            response = await response.json();
            setRatings((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));

            const res = response.data.Rating;
            setData((prevData) => {
                const updatedData = prevData.map((item) =>
                    item.id === id ? { ...item, Points: res } : item
                );
                return updatedData;
            });
            setRatingsValue((prevPointsState) => ({
                ...prevPointsState,
                [id]: res,
            }));
        } catch (e) {
            setRatings((prevPointsState) => ({
                ...prevPointsState,
                [id]: false,
            }));
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const userColumns = [
        {
            field: "SortID",
            headerName: "SortID",
            width: 80,
        },
        {
            field: "MeditationName",
            headerName: "MeditationName",
            width: 220,
        },
        {
            field: "MediaLink",
            headerName: "MediaLink",
            width: 600,
            renderCell: (params) => {
                const link = params.row.MediaLink;
                return (
                    <>
                        <ContentCopyIcon
                            sx={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                toast.success("Copied Link!!");
                            }}
                            titleAccess="Copy"
                        />
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.copyIcon}
                        >
                            {link}
                        </a>
                    </>
                );
            },
        },
        {
            field: "ThumbnailURL",
            headerName: "ThumbnailURL",
            width: 600,
            renderCell: (params) => {
                const link = params.row.ThumbnailURL;
                return (
                    <>
                        <ContentCopyIcon
                            sx={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                toast.success("Copied Link!!");
                            }}
                            titleAccess="Copy"
                        />
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.copyIcon}
                        >
                            {link}
                        </a>
                    </>
                );
            },
        },
        {
            field: "Ratings",
            headerName: "Ratings",
            width: 120,
            renderCell: (params) => {
                const id = params.row.id;
                return !Ratings[id] ? (
                    RatingsValue[id] !== undefined ? (
                        RatingsValue[id]
                    ) : (
                        <button
                            className={`${classes.fetchButton}`}
                            onClick={() => {
                                fetchRatings(id);
                            }}
                        >
                            Fetch Ratings
                        </button>
                    )
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                );
            },
        },
        {
            field: "IsPaid",
            headerName: "IsPaid",
            width: 80,
        },
        {
            field: "IsActive",
            headerName: "IsActive",
            width: 80,
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    let ChallengeData = [];
    const [data, setData] = useState(ChallengeData);
    const navigate = useNavigate();

    const FetchResponse = async () => {
        try {
            let response = await MeditationServices.getAllMeditations(token, navigate, null);
            setIsLoading(true);
            setIsDataFetched(true);
            toast.success("Data fetched successfully!!", {
                position: "bottom-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return response.data;
        } catch (e) {
            setIsLoading(false);
            setIsDataFetched(false);
            toast.error(e.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (!isDataFetched) {
            FetchResponse()
                .then((result) => {
                    let input = result.map(({ _id: id, ...result }) => ({
                        id,
                        ...result,
                    }));
                    setData(input);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleDelete = (id) => {
        MeditationServices.deleteMeditation(id, token);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className={classes.cellAction}>
                        <Link
                            href={`${BASE_PATH_TO_UPDATE_MEDITATIONS}/` + params.row.id}
                            style={{ textDecoration: "none" }}
                        >
                            <div className={classes.viewButton}>Update</div>
                        </Link>
                        <div
                            className={classes.deleteButton}
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={classes.datatable}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 1 }}>
                <Link underline="hover" color="inherit" href={BASE_PATH_TO_HOME}>
                    Dashboard
                </Link>
                <Typography color="text.primary">
                    Challenges
                </Typography>
                <Typography color="text.primary">Meditations List</Typography>
            </Breadcrumbs>
            {isLoading && (
                <DataGridPremium
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    slots={{
                        toolbar: () => (
                            <CustomToolbar
                                EXPORT_FILENAME={EXPORT_FILENAME}
                                onClickCreate={() => {
                                    navigate(BASE_PATH_TO_ADD_MEDITATIONS);
                                }}
                                displayCreate={""}
                                onClickUpdate={() => { }}
                                displayUpdate={"none"}
                            />
                        ),
                    }}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 25, page: 0 },
                        },
                    }}
                    disableRowSelectionOnClick
                    checkboxSelectionVisibleOnly
                    checkboxSelection
                />
            )}
            {!isLoading && <Loader />}
        </div>
    );
};

export default MeditationsList;
